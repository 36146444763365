/** @jsxImportSource @emotion/react */

import Tooltip from '../../../../../components/common/Tooltip/NewTooltip';
import Button, { ButtonSize, ButtonVariant } from '../../../../../components/core/buttons/Button';
import CopyableAddress from '../../../../../components/core/CopyableAddress';
import FlexBox from '../../../../../components/core/FlexBox';
import { EditIcon } from '../../../../../components/core/icons';
import Paragraph from '../../../../../components/core/Paragraph';
import QuestionVerificationStatusIcon, {
  QUESTION_VERIFICATION_STATUS_TOOLTIP
} from '../../../../../components/core/QuestionVerificationStatusIcon';
import Text from '../../../../../components/core/Text';
import CopyableAnswer from '../../../../../components/UIFlow/CopyableAnswer';
import QuestionAnswerPair from '../../../../../components/UIFlow/QuestionAnswerPair';
import { Translations } from '../../../../../constants';
import DatapointKey from '../../../../../interfaces/IDatapoint';
import { buildVehicleTitle, IVehicle } from '../../../../../interfaces/IVehicle';
import colors from '../../../../../theme/colors';
import { spacings } from '../../../../../theme/variables';
//eslint-disable-next-line max-len
import { useVehiclesWithVerificationStatus } from '../../../../GuidedSellingExperience/GuidedDataCollection/VehicleProfilePage/_hooks/useVehicleProfileAnswersWithVerificationAnswers';

interface VehicleProps {
  vehicle: IVehicle;
  index: number;
  isEditModeAvailable: boolean;
  toggleEdit: () => void;
  answers: ReturnType<typeof useVehiclesWithVerificationStatus>[string] | undefined;
}

const Vehicle = ({ vehicle, index, isEditModeAvailable, toggleEdit, answers }: VehicleProps) => {
  return (
    <FlexBox columnDirection gap={spacings.px8}>
      <FlexBox justifySpaceBetween alignItemsCenter>
        <Paragraph bold>{buildVehicleTitle(vehicle)}</Paragraph>
        {index === 0 && (
          <>
            <Button
              size={ButtonSize.Small}
              variant={ButtonVariant.Simple}
              data-testid={`collect-info-tab-vehicle-edit-button-${index}`}
              disabled={!isEditModeAvailable}
              color={colors.black}
              {...(!isEditModeAvailable && {
                'data-tip': 'Edit is not available at this moment',
                'data-for': 'edit-hint'
              })}
              onClick={event => {
                event.stopPropagation();
                toggleEdit();
              }}
            >
              <FlexBox gap={spacings.px4} alignItemsCenter>
                <EditIcon width={16} height={16} />
                <Text type="small" bold>
                  Edit
                </Text>
              </FlexBox>
            </Button>
            <Tooltip id="edit-hint" />
          </>
        )}
      </FlexBox>
      {vehicle.address && (
        <QuestionAnswerPair
          questionLabel={
            <Text type="small" color={colors.grey60}>
              Garaging address
            </Text>
          }
          answerLabel={
            <>
              {answers?.[DatapointKey.VehicleAddress] && (
                <QuestionVerificationStatusIcon
                  verificationStatus={answers[DatapointKey.VehicleAddress].verificationStatus}
                />
              )}
              <CopyableAddress
                textType="small"
                address={vehicle.address}
                data-testid={`collect-info-tab-vehicle-profile-results-address-${index}`}
              />
            </>
          }
        />
      )}
      {vehicle.ownership && (
        <QuestionAnswerPair
          questionLabel={
            <Text type="small" color={colors.grey60}>
              Ownership
            </Text>
          }
          answerLabel={
            <>
              {answers?.[DatapointKey.VehicleOwnership] && (
                <QuestionVerificationStatusIcon
                  verificationStatus={answers[DatapointKey.VehicleOwnership].verificationStatus}
                />
              )}
              <CopyableAnswer
                textType="small"
                value={Translations.vehicleOwnership(vehicle.ownership)}
                testId={`collect-info-tab-vehicle-profile-results-ownership-${index}`}
              />
            </>
          }
        />
      )}
      {vehicle.usage && (
        <QuestionAnswerPair
          questionLabel={
            <Text type="small" color={colors.grey60}>
              Use
            </Text>
          }
          answerLabel={
            <>
              {answers?.[DatapointKey.VehicleUsage] && (
                <QuestionVerificationStatusIcon
                  verificationStatus={answers[DatapointKey.VehicleUsage].verificationStatus}
                />
              )}

              <CopyableAnswer
                textType="small"
                value={Translations.vehicleUsage(vehicle.usage)}
                testId={`collect-info-tab-vehicle-profile-results-usage-${index}`}
              />
            </>
          }
        />
      )}
      {vehicle.annual_mileage && (
        <QuestionAnswerPair
          questionLabel={
            <Text type="small" color={colors.grey60}>
              Annual mileage
            </Text>
          }
          answerLabel={
            <>
              {answers?.[DatapointKey.VehicleAnnualMileage] && (
                <QuestionVerificationStatusIcon
                  verificationStatus={answers[DatapointKey.VehicleAnnualMileage].verificationStatus}
                />
              )}
              <CopyableAnswer
                textType="small"
                value={vehicle.annual_mileage.toString()}
                testId={`collect-info-tab-vehicle-profile-results-annual_mileage-${index}`}
              />
            </>
          }
        />
      )}
      {vehicle.vin && (
        <QuestionAnswerPair
          questionLabel={
            <Text type="small" color={colors.grey60}>
              VIN
            </Text>
          }
          answerLabel={
            <>
              {answers?.[DatapointKey.VehicleVin] && (
                <QuestionVerificationStatusIcon
                  verificationStatus={answers[DatapointKey.VehicleVin].verificationStatus}
                />
              )}
              <CopyableAnswer
                fsMask
                textType="small"
                value={vehicle.vin}
                testId={`collect-info-tab-vehicle-profile-results-vin-${index}`}
              />
            </>
          }
        />
      )}
      <Tooltip id={QUESTION_VERIFICATION_STATUS_TOOLTIP} />
    </FlexBox>
  );
};

export default Vehicle;
