/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { capitalize } from '@mui/material';
import * as React from 'react';

import Container from '../../../components/core/Container';
import Copy from '../../../components/core/Copy';
import CopyableAddress from '../../../components/core/CopyableAddress';
import FlexBox from '../../../components/core/FlexBox';
import { ChevronLeft, MailIcon, UserAddIcon, UserIcon } from '../../../components/core/icons';
import Paragraph from '../../../components/core/Paragraph';
import Tag from '../../../components/core/Tag';
import Text from '../../../components/core/Text';
import { useToggle } from '../../../hooks';
import { IPerson } from '../../../interfaces';
import usePersonRelatedPeople from '../../../queries/people/usePersonRelatedPeople';
import colors from '../../../theme/colors';
import { spacings } from '../../../theme/variables';
import { dateFormatter, phoneFormatter } from '../../../utils/formatter';
import useVerticalTabsCallback from '../_hooks/useVerticalTabsCallback';

const PeopleSummary = ({ person }: { person: IPerson }) => {
  const { data: relatedPeople } = usePersonRelatedPeople(person.gid);
  const [isOpen, toggleOpen] = useToggle(false);
  const { setSelectedIndex, selectedIndex, onTabClose } = useVerticalTabsCallback();

  React.useEffect(() => {
    if (selectedIndex !== 0 && isOpen) {
      toggleOpen();
    }
  }, [selectedIndex, toggleOpen, isOpen]);

  return (
    <FlexBox
      border
      backgroundColor={colors.white}
      roundBorder
      p={spacings.px16}
      css={css`
        width: 440px;
      `}
      columnDirection
      gap={spacings.px12}
      onClick={() => {
        toggleOpen();
        if (isOpen) {
          onTabClose();
        } else {
          setSelectedIndex(0);
        }
      }}
    >
      <FlexBox justifySpaceBetween>
        <Paragraph type="large" bold>
          People
        </Paragraph>
        <ChevronLeft
          css={css`
            transform: rotate(${isOpen || selectedIndex === 0 ? '0deg' : '180deg'});
            transition: transform 0.2s;
          `}
          color={colors.black}
        />
      </FlexBox>

      <FlexBox
        gap={spacings.px8}
        alignItemsCenter
        fitParentWidth
        customCss={css`
          flex-wrap: wrap;
        `}
      >
        <UserIcon />

        <FlexBox
          gap={spacings.px4}
          customCss={css`
            flex-wrap: wrap;
          `}
        >
          <Copy
            value={person.first_name}
            withIcon={false}
            customCss={css`
              padding: 0;
            `}
          >
            <Text className="fs-mask" singleLine>
              {person.first_name}
            </Text>
          </Copy>
          {person.middle_name && (
            <Copy
              value={person.middle_name}
              withIcon={false}
              customCss={css`
                padding: 0;
              `}
            >
              <Text className="fs-mask" singleLine>
                {person.middle_name}
              </Text>
            </Copy>
          )}
          <Copy
            value={person.last_name}
            withIcon={false}
            customCss={css`
              padding: 0;
            `}
          >
            <Text className="fs-mask" singleLine>
              {person.last_name}
            </Text>
          </Copy>
        </FlexBox>
        <Tag
          transparent
          textType="small"
          textColor={colors.azure50}
          backgroundColor={colors.azure50}
          border
          label="Primary customer"
          customCss={css`
            padding: 0 ${spacings.px8}px;
          `}
        />
      </FlexBox>
      <FlexBox gap={spacings.px8} alignItemsCenter ml={spacings.px28}>
        {person.date_of_birth && (
          <Copy
            value={dateFormatter(person.date_of_birth)}
            withIcon={false}
            type="small"
            customCss={css`
              padding: 0;
              min-width: 70px;
            `}
          >
            <Text type="small" className="fs-mask" title={dateFormatter(person.date_of_birth)}>
              {dateFormatter(person.date_of_birth)}
            </Text>
          </Copy>
        )}

        {person.phone && (
          <>
            <Container
              border
              customCss={css`
                height: 16px;
              `}
            />
            <Copy
              value={person.phone}
              withIcon={false}
              type="small"
              customCss={css`
                padding: 0;
                min-width: 100px;
              `}
            >
              <Text type="small" className="fs-mask" singleLine title={phoneFormatter(person.phone)}>
                {phoneFormatter(person.phone)}
              </Text>
            </Copy>
          </>
        )}
        {person.email && (
          <>
            <Container
              border
              customCss={css`
                height: 16px;
              `}
            />
            <Copy
              value={person.email}
              withIcon={false}
              customCss={css`
                padding: 0;
              `}
              type="small"
            >
              <Text type="small" className="fs-mask" singleLine title={person.email}>
                {person.email}
              </Text>
            </Copy>
          </>
        )}
      </FlexBox>
      {person.mailing_address && (
        <FlexBox gap={spacings.px8} alignItemsCenter>
          <MailIcon />
          <Text
            type="small"
            singleLine
            block
            css={css`
              min-width: 90px;
            `}
          >
            Mailing address
          </Text>
          <CopyableAddress address={person.mailing_address} textType="small" />
        </FlexBox>
      )}
      {relatedPeople?.map(relatedPerson => {
        return (
          <React.Fragment key={relatedPerson.gid}>
            <Container border />
            <FlexBox gap={spacings.px8} columnDirection>
              <FlexBox
                gap={spacings.px8}
                alignItemsCenter
                customCss={css`
                  flex-wrap: wrap;
                `}
              >
                <UserAddIcon />

                <FlexBox
                  gap={spacings.px4}
                  customCss={css`
                    flex-wrap: wrap;
                  `}
                >
                  <Copy
                    value={relatedPerson.first_name}
                    withIcon={false}
                    customCss={css`
                      padding: 0;
                    `}
                  >
                    <Text className="fs-mask">{relatedPerson.first_name}</Text>
                  </Copy>
                  {relatedPerson.middle_name && (
                    <Copy
                      value={relatedPerson.middle_name}
                      withIcon={false}
                      customCss={css`
                        padding: 0;
                      `}
                    >
                      <Text className="fs-mask">{relatedPerson.middle_name}</Text>
                    </Copy>
                  )}
                  <Copy
                    value={relatedPerson.last_name}
                    withIcon={false}
                    customCss={css`
                      padding: 0;
                    `}
                  >
                    <Text className="fs-mask">{relatedPerson.last_name}</Text>
                  </Copy>
                </FlexBox>
                {relatedPerson.kind && (
                  <Tag
                    transparent
                    textType="small"
                    textColor={colors.azure50}
                    backgroundColor={colors.azure50}
                    border
                    label={capitalize(relatedPerson.kind)}
                    customCss={css`
                      padding: 0 ${spacings.px8}px;
                    `}
                  />
                )}
              </FlexBox>
              <FlexBox gap={spacings.px8} alignItemsCenter ml={spacings.px28}>
                {relatedPerson.date_of_birth && (
                  <Copy
                    value={dateFormatter(relatedPerson.date_of_birth)}
                    withIcon={false}
                    type="small"
                    customCss={css`
                      padding: 0;
                      min-width: 70px;
                    `}
                  >
                    <Text type="small" className="fs-mask" title={dateFormatter(relatedPerson.date_of_birth)}>
                      {dateFormatter(relatedPerson.date_of_birth)}
                    </Text>
                  </Copy>
                )}

                {relatedPerson.phone && (
                  <>
                    <Container
                      border
                      customCss={css`
                        height: 16px;
                      `}
                    />
                    <Copy
                      value={relatedPerson.phone}
                      withIcon={false}
                      type="small"
                      customCss={css`
                        padding: 0;
                        min-width: 100px;
                      `}
                    >
                      <Text type="small" className="fs-mask" singleLine title={phoneFormatter(relatedPerson.phone)}>
                        {phoneFormatter(relatedPerson.phone)}
                      </Text>
                    </Copy>
                  </>
                )}
                {relatedPerson.email && (
                  <>
                    <Container
                      border
                      customCss={css`
                        height: 16px;
                      `}
                    />
                    <Copy
                      value={relatedPerson.email}
                      withIcon={false}
                      customCss={css`
                        padding: 0;
                      `}
                      type="small"
                    >
                      <Text type="small" className="fs-mask" singleLine title={relatedPerson.email}>
                        {relatedPerson.email}
                      </Text>
                    </Copy>
                  </>
                )}
              </FlexBox>
            </FlexBox>
          </React.Fragment>
        );
      })}
    </FlexBox>
  );
};

export default PeopleSummary;
