/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { createColumnHelper } from '@tanstack/react-table';
import * as React from 'react';

import { Link } from '../../../components/common';
import Button, { ButtonSize, ButtonVariant } from '../../../components/core/buttons/Button';
import Container from '../../../components/core/Container';
import FlexBox from '../../../components/core/FlexBox';
import { ArrowDownIcon, DesktopIcon, FlagIcon, UsersIcon } from '../../../components/core/icons';
import Paragraph from '../../../components/core/Paragraph';
import Table from '../../../components/core/Table/Table';
import { usePopper } from '../../../hooks';
import { ILead } from '../../../interfaces';
import { InsuranceCategory } from '../../../interfaces/IPolicyType';
import useLeadDispositions from '../../../queries/leads/dispositions/useLeadDispositions';
import useBrand from '../../../queries/partners/useBrand';
import analytics from '../../../services/analytics';
import colors from '../../../theme/colors';
import { spacings } from '../../../theme/variables';
import { daysToNow } from '../../../utils/formatter';

const PartnerCell = ({ lead }: { lead: ILead }) => {
  const { brand } = useBrand(lead.source_dimensions.brand);

  if (!brand) {
    return null;
  }

  return <Paragraph>{brand.name}</Paragraph>;
};

const StatusCell = ({ lead }: { lead: ILead }) => {
  const { data } = useLeadDispositions(lead.id);
  const currentDisposition = data?.current_disposition;

  if (!currentDisposition) {
    return null;
  }

  return <Paragraph>{currentDisposition.label}</Paragraph>;
};

const RepresentativeCell = ({ lead }: { lead: ILead }) => {
  const { data } = useLeadDispositions(lead.id);
  const currentDisposition = data?.current_disposition;

  if (!currentDisposition) {
    return null;
  }

  return <Paragraph>{currentDisposition.assignee?.name || '-'}</Paragraph>;
};

const HeaderCell = ({ children }: { children: React.ReactNode }) => (
  <FlexBox alignItemsCenter gap={spacings.px8}>
    {children}
  </FlexBox>
);

const categoryMapping = {
  [InsuranceCategory.PersonalLines]: 'Personal lines',
  [InsuranceCategory.Life]: 'Life'
} as const;

const columnHelper = createColumnHelper<ILead>();
const tableColumns = [
  columnHelper.display({
    id: 'lead_id',
    cell: ({ row: { original } }) => <Link to={`/scouting_report?lead_gid=${original.gid}`}>{original.id}</Link>,
    header: () => (
      <HeaderCell>
        <UsersIcon /> <Paragraph bold>Lead ID</Paragraph>
      </HeaderCell>
    ),
    size: 120
  }),
  columnHelper.display({
    id: 'partner',
    cell: ({ row: { original } }) => <PartnerCell lead={original} />,
    header: () => (
      <HeaderCell>
        <FlagIcon /> <Paragraph bold>Partner</Paragraph>
      </HeaderCell>
    )
  }),
  columnHelper.display({
    id: 'category',
    cell: ({ row: { original } }) => <Paragraph>{categoryMapping[original.insurance_category]}</Paragraph>,
    header: () => (
      <HeaderCell>
        <UsersIcon /> <Paragraph bold>Category</Paragraph>
      </HeaderCell>
    )
  }),
  columnHelper.display({
    id: 'status',
    cell: ({ row: { original } }) => <StatusCell lead={original} />,
    header: () => (
      <HeaderCell>
        <UsersIcon /> <Paragraph bold>Status</Paragraph>
      </HeaderCell>
    )
  }),
  columnHelper.display({
    id: 'representative',
    cell: ({ row: { original } }) => <RepresentativeCell lead={original} />,
    header: () => (
      <HeaderCell>
        <UsersIcon /> <Paragraph bold>Matic Reps</Paragraph>
      </HeaderCell>
    )
  }),
  columnHelper.display({
    id: 'age',
    cell: ({ row: { original } }) => <Paragraph>{`${daysToNow(original.created_at)} day(s)`}</Paragraph>,
    header: () => (
      <HeaderCell>
        <DesktopIcon /> <Paragraph bold>Lead age</Paragraph>
      </HeaderCell>
    )
  })
];

const RecentLeads = ({ leads }: { leads: ILead[] }) => {
  const { triggerPopper, PopperComponent, popperProps, elementRef } = usePopper({
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8]
        }
      }
    ]
  });

  return (
    <>
      <Button
        size={ButtonSize.Small}
        variant={ButtonVariant.Simple}
        onClick={e => {
          triggerPopper(e);
          analytics.track('Recent leads button clicked');
        }}
        customCss={css`
          border-color: ${colors.grey10};
          background-color: ${colors.grey3};
        `}
      >
        <FlexBox gap={spacings.px8}>
          <Paragraph color={colors.grey80}>Recent lead ID:{leads[0]?.id || '-'}</Paragraph>
          <ArrowDownIcon />
        </FlexBox>
      </Button>
      <div ref={elementRef}>
        <PopperComponent {...popperProps}>
          <Container backgroundColor={colors.white} roundBorder border p={0}>
            <Table
              withRowBorders
              testId="recent-leads-table"
              data={leads}
              headerBackgroundColor={colors.grey5}
              columns={tableColumns}
            />
          </Container>
        </PopperComponent>
      </div>
    </>
  );
};

export default RecentLeads;
