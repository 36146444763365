/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';
import ReactTooltip from 'react-tooltip';

import IconButton, { ButtonIcons } from '../../components/core/buttons/IconButton';
import Container from '../../components/core/Container';
import Copy from '../../components/core/Copy';
import FlexBox from '../../components/core/FlexBox';
import Text from '../../components/core/Text';
import { IQuote } from '../../interfaces';
import { coverageByKey, PolicyCoverageKey } from '../../interfaces/IPolicyCoverage';
import { PolicyType } from '../../interfaces/IPolicyType';
import { QuoteSource } from '../../interfaces/IQuote';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import { moneyFormatter } from '../../utils/formatter';
import { EDIT_DISABLED_TOOLTIP_ID, HOME_COVERAGES_OPTIONS } from './_helpers';

const HomeQuoteInfo = ({
  setOpenQuoteEditor,
  quote,
  disabled
}: {
  setOpenQuoteEditor: React.Dispatch<React.SetStateAction<boolean>>;
  quote: IQuote;
  disabled?: boolean;
}) => {
  const carrierName = quote.attributes.carrier.name || '—';
  const premium = moneyFormatter(quote.attributes.premium, true) || '—';
  const dwelling = coverageByKey(quote.attributes.coverages, PolicyCoverageKey.Dwelling)?.limit_description || '—';
  const deductible =
    coverageByKey(quote.attributes.coverages, PolicyCoverageKey.Dwelling)?.deductible_description || '—';
  const otherStructures =
    coverageByKey(quote.attributes.coverages, PolicyCoverageKey.OtherStructures)?.limit_description || '—';
  const personalProperty =
    coverageByKey(quote.attributes.coverages, PolicyCoverageKey.PersonalProperty)?.limit_description || '—';
  const lossOfUse = coverageByKey(quote.attributes.coverages, PolicyCoverageKey.LossOfUse)?.limit_description || '—';
  const personalLiability =
    coverageByKey(quote.attributes.coverages, PolicyCoverageKey.PersonalLiability)?.limit_description || '—';
  const medicalPayments =
    coverageByKey(quote.attributes.coverages, PolicyCoverageKey.MedicalPayments)?.limit_description || '—';
  const fairRentalValue =
    coverageByKey(quote.attributes.coverages, PolicyCoverageKey.FairRentalValue)?.limit_description || '—';
  const householdFurnishings =
    coverageByKey(quote.attributes.coverages, PolicyCoverageKey.HouseholdFurnishings)?.limit_description || '—';

  const includedCoverages =
    quote.attributes.coverages
      ?.filter(
        coverage =>
          HOME_COVERAGES_OPTIONS.includes(coverage.key) &&
          coverage.deductible_description !== 'Not included' &&
          coverage.limit_description !== 'Not included'
      )
      .map(({ description }) => description)
      .join(', ') || '—';

  const hurricane =
    includedCoverages.includes('Hurricane') &&
    coverageByKey(quote.attributes.coverages, PolicyCoverageKey.Hurricane)?.deductible_description;

  const windHail =
    includedCoverages.includes('Wind hail') &&
    coverageByKey(quote.attributes.coverages, PolicyCoverageKey.WindHail)?.deductible_description;

  const waterBackup =
    includedCoverages.includes('Water backup') &&
    coverageByKey(quote.attributes.coverages, PolicyCoverageKey.WaterBackup)?.limit_description;

  const earthquake =
    includedCoverages.includes('Earthquake') &&
    coverageByKey(quote.attributes.coverages, PolicyCoverageKey.Earthquake)?.limit_description;

  const flood =
    includedCoverages.includes('Flood') &&
    coverageByKey(quote.attributes.coverages, PolicyCoverageKey.Flood)?.limit_description;

  const serviceLine =
    includedCoverages.includes('Service line') &&
    coverageByKey(quote.attributes.coverages, PolicyCoverageKey.ServiceLine)?.limit_description;

  const equipmentBreakdown =
    includedCoverages.includes('Equipment breakdown') &&
    coverageByKey(quote.attributes.coverages, PolicyCoverageKey.EquipmentBreakdown)?.limit_description;

  const foundation =
    includedCoverages.includes('Foundation') &&
    coverageByKey(quote.attributes.coverages, PolicyCoverageKey.Foundation)?.limit_description;

  const functionalReplacementCost =
    includedCoverages.includes('Functional replacement cost') &&
    coverageByKey(quote.attributes.coverages, PolicyCoverageKey.FunctionalReplacementCost)?.limit_description;

  const replacementCost =
    includedCoverages.includes('Extended replacement cost') &&
    coverageByKey(quote.attributes.coverages, PolicyCoverageKey.ExtendedReplacementCost)?.deductible_description;

  const replacementCostOnContents =
    includedCoverages.includes('Replacement cost on contents') &&
    coverageByKey(quote.attributes.coverages, PolicyCoverageKey.ReplacementCostOnContents)?.limit_description;

  const excludedCoverages =
    quote.attributes.coverages
      ?.filter(
        coverage => coverage.limit_description === 'Not included' || coverage.deductible_description === 'Not included'
      )
      .map(({ description }) => description)
      .join(', ') || '—';

  const bindingUrl = quote.attributes.binding_url || '—';

  const quoteEditDisabled = disabled || ![QuoteSource.AU, QuoteSource.CO].includes(quote.source);

  const policyType = quote.attributes.policy_type;
  const isInvestment = policyType === PolicyType.Fire;
  const isRenters = policyType === PolicyType.Renters;
  const isCondo = policyType === PolicyType.Condo;

  React.useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <Container
      border
      borderColor={colors.grey10}
      p={spacings.px12}
      customCss={css`
        border-radius: ${spacings.px8}px;
        max-width: 400px;
      `}
    >
      <FlexBox columnDirection gap={spacings.px8}>
        <FlexBox justifySpaceBetween>
          <Text bold>Your proposal</Text>
          <IconButton
            data-for={EDIT_DISABLED_TOOLTIP_ID}
            data-tip={quoteEditDisabled ? 'Quote editing is disabled' : ''}
            disabled={quoteEditDisabled}
            icon={ButtonIcons.Edit}
            color={colors.black}
            onClick={() => {
              setOpenQuoteEditor(true);
            }}
          />
        </FlexBox>
        <FlexBox gap={spacings.px12} columnDirection>
          <FlexBox justifySpaceBetween gap={spacings.px4}>
            <Text>Carrier</Text>
            <Text>{carrierName}</Text>
          </FlexBox>
          <FlexBox justifySpaceBetween gap={spacings.px4}>
            <Text>Bridge URL</Text>
            <Copy value={bindingUrl}>
              <Text
                customCss={css`
                  max-width: 160px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                `}
              >
                {bindingUrl}
              </Text>
            </Copy>
          </FlexBox>
          <FlexBox justifySpaceBetween gap={spacings.px4}>
            <Text>Premium(1 year)</Text>
            <Text>{premium}</Text>
          </FlexBox>
          <FlexBox justifySpaceBetween gap={spacings.px4}>
            <Text>Deductible</Text>
            <Text>{deductible}</Text>
          </FlexBox>
          {!isRenters && (
            <FlexBox justifySpaceBetween gap={spacings.px4}>
              <Text>Dwelling</Text>
              <Text>{dwelling}</Text>
            </FlexBox>
          )}
          {!(isCondo || isRenters) && (
            <FlexBox justifySpaceBetween gap={spacings.px4}>
              <Text>Other structures</Text>
              <Text>{otherStructures}</Text>
            </FlexBox>
          )}
          {!isInvestment && (
            <>
              <FlexBox justifySpaceBetween gap={spacings.px4}>
                <Text>Personal property</Text>
                <Text>{personalProperty}</Text>
              </FlexBox>
              <FlexBox justifySpaceBetween gap={spacings.px4}>
                <Text>Loss of use</Text>
                <Text>{lossOfUse}</Text>
              </FlexBox>
            </>
          )}
          {isInvestment && (
            <>
              <FlexBox justifySpaceBetween gap={spacings.px4}>
                <Text>Household furnishings</Text>
                <Text>{householdFurnishings}</Text>
              </FlexBox>
              <FlexBox justifySpaceBetween gap={spacings.px4}>
                <Text>Fair rental value</Text>
                <Text>{fairRentalValue}</Text>
              </FlexBox>
            </>
          )}
          <FlexBox justifySpaceBetween gap={spacings.px4}>
            <Text>{isInvestment ? 'Premises liability' : 'Personal liability'}</Text>
            <Text>{personalLiability}</Text>
          </FlexBox>
          <FlexBox justifySpaceBetween gap={spacings.px4}>
            <Text>Medical payments</Text>
            <Text>{medicalPayments}</Text>
          </FlexBox>
          <FlexBox justifySpaceBetween gap={spacings.px4}>
            <Text>Coverages included</Text>
            <Text
              customCss={css`
                text-align: right;
              `}
            >
              {includedCoverages}
            </Text>
          </FlexBox>
          {replacementCost && (
            <FlexBox justifySpaceBetween gap={spacings.px4}>
              <Text>Extended replacement</Text>
              <Text>{replacementCost}</Text>
            </FlexBox>
          )}
          {replacementCostOnContents && (
            <FlexBox justifySpaceBetween gap={spacings.px4}>
              <Text>Replacement cost on contents</Text>
              <Text>{replacementCostOnContents}</Text>
            </FlexBox>
          )}
          {windHail && (
            <FlexBox justifySpaceBetween gap={spacings.px4}>
              <Text>Wind/Hail</Text>
              <Text>{windHail}</Text>
            </FlexBox>
          )}
          {hurricane && (
            <FlexBox justifySpaceBetween gap={spacings.px4}>
              <Text>Hurricane</Text>
              <Text>{hurricane}</Text>
            </FlexBox>
          )}
          {waterBackup && (
            <FlexBox justifySpaceBetween gap={spacings.px4}>
              <Text>Water backup</Text>
              <Text>{waterBackup}</Text>
            </FlexBox>
          )}
          {flood && (
            <FlexBox justifySpaceBetween gap={spacings.px4}>
              <Text>Flood</Text>
              <Text>{flood}</Text>
            </FlexBox>
          )}
          {earthquake && (
            <FlexBox justifySpaceBetween gap={spacings.px4}>
              <Text>Earthquake</Text>
              <Text>{earthquake}</Text>
            </FlexBox>
          )}
          {serviceLine && (
            <FlexBox justifySpaceBetween gap={spacings.px4}>
              <Text>Service line</Text>
              <Text>{serviceLine}</Text>
            </FlexBox>
          )}
          {equipmentBreakdown && (
            <FlexBox justifySpaceBetween gap={spacings.px4}>
              <Text>Equipment breakdown</Text>
              <Text>{equipmentBreakdown}</Text>
            </FlexBox>
          )}
          {foundation && (
            <FlexBox justifySpaceBetween gap={spacings.px4}>
              <Text>Foundation coverage</Text>
              <Text>{foundation}</Text>
            </FlexBox>
          )}
          {functionalReplacementCost && (
            <FlexBox justifySpaceBetween gap={spacings.px4}>
              <Text>Functional replacement cost</Text>
              <Text>{functionalReplacementCost}</Text>
            </FlexBox>
          )}
          <FlexBox justifySpaceBetween gap={spacings.px4}>
            <Text>Coverages excluded</Text>
            <Text
              customCss={css`
                text-align: right;
              `}
            >
              {excludedCoverages}
            </Text>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </Container>
  );
};
export default HomeQuoteInfo;
