import FlexBox from '../../../components/core/FlexBox';
import { PuzzlePieceIcon } from '../../../components/core/icons';
import Paragraph from '../../../components/core/Paragraph';
import Text from '../../../components/core/Text';
import { IMaticPolicy } from '../../../interfaces';
import useFindAssignee from '../../../queries/assignees/useFindAssignee';
import { spacings } from '../../../theme/variables';
import { dateFormatter } from '../../../utils/formatter';

const LastPolicy = ({ policy }: { policy: IMaticPolicy }) => {
  const { data: agent } = useFindAssignee(policy.agent_id);

  if (!agent) {
    return null;
  }

  return (
    <FlexBox gap={spacings.px8} alignItemsCenter>
      <PuzzlePieceIcon />
      <Paragraph>
        Last policy was sold by{' '}
        <Text bold>
          {agent.role_label} {agent.name} | {dateFormatter(policy.created_at)}
        </Text>
      </Paragraph>
    </FlexBox>
  );
};

export default LastPolicy;
