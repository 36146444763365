/** @jsxImportSource @emotion/react */
import { ClassNames, css } from '@emotion/react';
import React from 'react';

import Loader from '../../../../../components/common/Loader';
import Button, { ButtonVariant } from '../../../../../components/core/buttons/Button';
import Copy from '../../../../../components/core/Copy';
import FlexBox from '../../../../../components/core/FlexBox';
import { VisibilityIcon } from '../../../../../components/core/icons';
import QuestionVerificationStatusIcon from '../../../../../components/core/QuestionVerificationStatusIcon';
import TelephonyActions from '../../../../../components/core/TelephonyActions';
import TelephonyTooltip from '../../../../../components/core/TelephonyTooltip';
import Text from '../../../../../components/core/Text';
import CopyableAnswer from '../../../../../components/UIFlow/CopyableAnswer';
import EmptyAnswer from '../../../../../components/UIFlow/EmptyAnswer';
import QuestionAnswerPair from '../../../../../components/UIFlow/QuestionAnswerPair';
import { Translations } from '../../../../../constants';
import featureFlags from '../../../../../constants/featureFlags';
import { useGuidedSellingExperienceContext } from '../../../../../contexts/GuidedSellingExperienceContext';
import { IPerson, IRelatedPerson } from '../../../../../interfaces';
import DatapointKey from '../../../../../interfaces/IDatapoint';
import { useActiveTCPAConsent } from '../../../../../queries/disclosures/useDisclosure';
import usePersonDNC from '../../../../../queries/people/dncStatus';
import useObtainPersonDrivingLicense, {
  isLicenseLookupAvailable
} from '../../../../../queries/people/useObtainDrivingLicense';
import authInfo from '../../../../../services/authInfo';
import colors from '../../../../../theme/colors';
import { spacings } from '../../../../../theme/variables';
import { dateFormatter, phoneFormatter } from '../../../../../utils/formatter';
import { useAnswersWithVerificationStatus } from '../../../_hooks';
import DOBWrapper from '../_components/DOBWrapper';

interface PersonInfoProps {
  person: IPerson | IRelatedPerson;
  testId: string;
  primaryCustomer: IPerson;
  header: React.ReactNode;
  expanded: boolean;
  answers: ReturnType<typeof useAnswersWithVerificationStatus>[string];
  isAnyVehicleQuoted: boolean;
}

const LookupDrivingLicenseButton = ({
  person,
  primaryCustomer,
  isAnyVehicleQuoted
}: Pick<PersonInfoProps, 'isAnyVehicleQuoted' | 'person' | 'primaryCustomer'>) => {
  const { leadGid } = useGuidedSellingExperienceContext();
  const addressForLicenseLookup =
    person.living_address ||
    person.mailing_address ||
    primaryCustomer.living_address ||
    primaryCustomer.mailing_address;

  const drivingLicenseLookupEnabled =
    isAnyVehicleQuoted &&
    isLicenseLookupAvailable({
      dateOfBirth: person.date_of_birth,
      address: addressForLicenseLookup,
      licenseNumber: person.license_number
    });

  const { mutateAsync: obtainPersonDrivingLicense, isPending } = useObtainPersonDrivingLicense({
    withRelatedPeopleInvalidation: person.gid !== primaryCustomer.gid
  });

  return drivingLicenseLookupEnabled ? (
    <Button
      type="button"
      disabled={isPending}
      onClick={e => {
        e.stopPropagation();
        obtainPersonDrivingLicense({
          personGid: person.gid,
          data: {
            address: addressForLicenseLookup!,
            engagement_gid: leadGid
          }
        });
      }}
      variant={ButtonVariant.Simple}
      data-testid="obtain-license-number-button"
      pv={spacings.px8}
      customCss={css`
        display: flex;
        align-items: center;
        border-radius: 8px;
        border-width: 1px;
      `}
    >
      <FlexBox gap={spacings.px4} alignItemsCenter>
        {isPending ? <Loader width={16} height={16} /> : <VisibilityIcon />}
        {isPending ? 'Looking' : 'Lookup'}
      </FlexBox>
    </Button>
  ) : null;
};

const PersonInfo = ({
  person,
  testId,
  primaryCustomer,
  header,
  expanded,
  answers,
  isAnyVehicleQuoted
}: PersonInfoProps) => {
  const { data: doNotContact, isSuccess } = usePersonDNC(person.gid);
  const { data: tcpaConsent, isPending: isConsentPending } = useActiveTCPAConsent(person.gid);
  const canCallWithDNC = authInfo.features.do_not_contact_call_available;
  const isRelatedPerson = 'kind' in person;

  return (
    <FlexBox
      key={person.gid}
      columnDirection
      gap={spacings.px8}
      customCss={css`
        flex: 1 0 auto;
        @media (max-width: 800px) {
          flex: 1 0 100%;
        }
      `}
    >
      {header}

      <FlexBox gap={spacings.px8} columnDirection pl={spacings.px28}>
        {(expanded || !isRelatedPerson) && (
          <>
            {person.phone && isSuccess && (
              <>
                <QuestionAnswerPair
                  questionLabel={
                    <Text type="small" color={colors.grey60}>
                      Phone
                    </Text>
                  }
                  answerLabel={
                    doNotContact && !canCallWithDNC ? (
                      <Text type="small" color={colors.statusRed} data-testid="phone-do-not-contact">
                        Do not contact
                      </Text>
                    ) : (
                      <TelephonyTooltip phone={person.phone} personGid={primaryCustomer.gid}>
                        {answers[DatapointKey.PersonPhone] && (
                          <QuestionVerificationStatusIcon
                            verificationStatus={answers[DatapointKey.PersonPhone].verificationStatus}
                          />
                        )}
                        <ClassNames>
                          {({ css }) => (
                            <Copy
                              type="small"
                              className={
                                doNotContact && canCallWithDNC
                                  ? `${css(`color: ${colors.statusRed};`)} fs-mask`
                                  : 'fs-mask'
                              }
                              value={phoneFormatter(person.phone)}
                              testId="collect-info-tab-customer-profile-results-phone"
                            >
                              {phoneFormatter(person.phone)}
                            </Copy>
                          )}
                        </ClassNames>
                      </TelephonyTooltip>
                    )
                  }
                />
                {!isConsentPending && !tcpaConsent && featureFlags.displayTCPAConsent && (
                  <QuestionAnswerPair
                    questionLabel={<></>}
                    answerLabel={
                      <Text color={colors.statusOrange} type="small">
                        No TCPA consent obtained
                      </Text>
                    }
                  />
                )}
              </>
            )}

            {expanded && person.secondary_phone && isSuccess && (
              <QuestionAnswerPair
                questionLabel={
                  <Text type="small" color={colors.grey60}>
                    Second phone
                  </Text>
                }
                answerLabel={
                  doNotContact && !canCallWithDNC ? (
                    <Text type="small" color={colors.statusRed} data-testid="second-phone-do-not-contact">
                      Do not contact
                    </Text>
                  ) : (
                    <TelephonyTooltip phone={person.secondary_phone} personGid={primaryCustomer.gid}>
                      {answers[DatapointKey.PersonPhoneSecondary] && (
                        <QuestionVerificationStatusIcon
                          verificationStatus={answers[DatapointKey.PersonPhoneSecondary].verificationStatus}
                        />
                      )}
                      <ClassNames>
                        {({ css }) => (
                          <Copy
                            type="small"
                            className={
                              doNotContact && canCallWithDNC
                                ? `${css(`color: ${colors.statusRed};`)} fs-mask`
                                : 'fs-mask'
                            }
                            value={phoneFormatter(person.secondary_phone)}
                            testId="collect-info-tab-customer-profile-results-secondary_phone"
                          >
                            {phoneFormatter(person.secondary_phone)}
                          </Copy>
                        )}
                      </ClassNames>
                    </TelephonyTooltip>
                  )
                }
              />
            )}

            {expanded && person.business_phone && isSuccess && (
              <QuestionAnswerPair
                questionLabel={
                  <Text type="small" color={colors.grey60}>
                    Business phone
                  </Text>
                }
                answerLabel={
                  doNotContact && !canCallWithDNC ? (
                    <Text type="small" color={colors.statusRed} data-testid="business-phone-do-not-contact">
                      Do not contact
                    </Text>
                  ) : (
                    <TelephonyTooltip phone={person.business_phone} personGid={primaryCustomer.gid}>
                      {answers[DatapointKey.PersonPhoneBusiness] && (
                        <QuestionVerificationStatusIcon
                          verificationStatus={answers[DatapointKey.PersonPhoneBusiness].verificationStatus}
                        />
                      )}
                      <ClassNames>
                        {({ css }) => (
                          <Copy
                            type="small"
                            className={
                              doNotContact && canCallWithDNC
                                ? `${css(`color: ${colors.statusRed};`)} fs-mask`
                                : 'fs-mask'
                            }
                            value={phoneFormatter(person.business_phone)}
                            testId="collect-info-tab-customer-profile-results-business_phone"
                          >
                            {phoneFormatter(person.business_phone)}
                          </Copy>
                        )}
                      </ClassNames>
                    </TelephonyTooltip>
                  )
                }
              />
            )}
          </>
        )}

        {(expanded || !isRelatedPerson) && (
          <>
            {isSuccess && (
              <QuestionAnswerPair
                questionLabel={
                  <Text type="small" color={colors.grey60}>
                    Email
                  </Text>
                }
                answerLabel={
                  <>
                    {doNotContact && !canCallWithDNC ? (
                      <Text type="small" color={colors.statusRed} data-testid="email-do-not-contact">
                        Do not contact
                      </Text>
                    ) : (
                      <>
                        {person.email && (
                          <TelephonyActions email={person.email} personGid={primaryCustomer.gid}>
                            {answers[DatapointKey.PersonEmail] && (
                              <QuestionVerificationStatusIcon
                                verificationStatus={answers[DatapointKey.PersonEmail].verificationStatus}
                              />
                            )}
                            <CopyableAnswer
                              withIcon={false}
                              textType="small"
                              fsMask
                              value={person.email}
                              customCss={
                                doNotContact && canCallWithDNC
                                  ? css`
                                      color: ${colors.statusRed} !important;
                                    `
                                  : undefined
                              }
                              testId={`${testId}-email`}
                            />
                          </TelephonyActions>
                        )}
                        {!person.email && <EmptyAnswer textType="small" />}
                      </>
                    )}
                  </>
                }
              />
            )}
          </>
        )}

        <QuestionAnswerPair
          questionLabel={
            <Text type="small" color={colors.grey60}>
              DOB
            </Text>
          }
          answerLabel={
            person.date_of_birth ? (
              <>
                {answers[DatapointKey.PersonDateOfBirth] && (
                  <QuestionVerificationStatusIcon
                    verificationStatus={answers[DatapointKey.PersonDateOfBirth].verificationStatus}
                  />
                )}
                <DOBWrapper>
                  <CopyableAnswer
                    textType="small"
                    fsMask
                    value={dateFormatter(person.date_of_birth)}
                    testId={`${testId}-dob`}
                  />
                </DOBWrapper>
              </>
            ) : (
              <EmptyAnswer textType="small" />
            )
          }
        />

        {expanded && (
          <QuestionAnswerPair
            questionLabel={
              <Text type="small" color={colors.grey60}>
                Gender
              </Text>
            }
            answerLabel={
              person.gender ? (
                <>
                  {answers[DatapointKey.PersonGender] && (
                    <QuestionVerificationStatusIcon
                      verificationStatus={answers[DatapointKey.PersonGender].verificationStatus}
                    />
                  )}
                  <CopyableAnswer
                    textType="small"
                    testId={`${testId}-gender`}
                    value={Translations.gender(person.gender)}
                  />
                </>
              ) : (
                <EmptyAnswer textType="small" />
              )
            }
          />
        )}

        {expanded && (
          <QuestionAnswerPair
            questionLabel={
              <Text type="small" color={colors.grey60}>
                Marital status
              </Text>
            }
            answerLabel={
              person.marital_status ? (
                <>
                  {answers[DatapointKey.PersonMaritalStatus] && (
                    <QuestionVerificationStatusIcon
                      verificationStatus={answers[DatapointKey.PersonMaritalStatus].verificationStatus}
                    />
                  )}
                  <CopyableAnswer
                    textType="small"
                    testId={`${testId}-marital-status`}
                    value={Translations.maritalStatus(person.marital_status)}
                  />
                </>
              ) : (
                <EmptyAnswer textType="small" />
              )
            }
          />
        )}

        {expanded && (
          <QuestionAnswerPair
            questionLabel={
              <Text type="small" color={colors.grey60}>
                License number
              </Text>
            }
            answerLabel={
              person.license_number ? (
                <>
                  {answers[DatapointKey.PersonLicenseNumber] && (
                    <QuestionVerificationStatusIcon
                      verificationStatus={answers[DatapointKey.PersonLicenseNumber].verificationStatus}
                    />
                  )}
                  <CopyableAnswer
                    textType="small"
                    testId={`${testId}-license-number`}
                    fsMask
                    value={person.license_number}
                  />
                </>
              ) : (
                <FlexBox
                  justifySpaceBetween
                  alignItemsCenter
                  customCss={css`
                    flex: 1;
                  `}
                >
                  <EmptyAnswer textType="small" />
                  <LookupDrivingLicenseButton
                    person={person}
                    primaryCustomer={primaryCustomer}
                    isAnyVehicleQuoted={isAnyVehicleQuoted}
                  />
                </FlexBox>
              )
            }
          />
        )}

        {expanded && (
          <QuestionAnswerPair
            questionLabel={
              <Text type="small" color={colors.grey60}>
                License state
              </Text>
            }
            answerLabel={
              person.license_state ? (
                <>
                  {answers[DatapointKey.PersonLicenseState] && (
                    <QuestionVerificationStatusIcon
                      verificationStatus={answers[DatapointKey.PersonLicenseState].verificationStatus}
                    />
                  )}
                  <CopyableAnswer
                    textType="small"
                    testId={`${testId}-license-state`}
                    value={Translations.usaState(person.license_state)}
                  />
                </>
              ) : (
                <EmptyAnswer textType="small" />
              )
            }
          />
        )}
      </FlexBox>
    </FlexBox>
  );
};

export default PersonInfo;
