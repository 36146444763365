import { annualMileageCalculatorInitialValues } from '../../../../../components/AnnualMileageCalculator';
import { useGuidedSellingExperienceContext } from '../../../../../contexts/GuidedSellingExperienceContext';
import { IRelatedPerson, IVehicle } from '../../../../../interfaces';
import DatapointKey, { DatapointsUsageKey } from '../../../../../interfaces/IDatapoint';
import { isVehicleOpportunity } from '../../../../../interfaces/IOpportunity';
import { IPerson, isPersonAgedToDrive } from '../../../../../interfaces/IPerson';
import { useAnswers } from '../../../../../queries/answers/useAnswers';
import useDataCollectionDrivers, {
  useUpdateDataCollectionDrivers
} from '../../../../../queries/leads/data_collection/useDataCollectionDrivers';
// eslint-disable-next-line max-len
import { useReplaceDataCollectionVehicles } from '../../../../../queries/leads/data_collection/useDataCollectionVehicles';
import { useCreateVehicle, useDetachVehicle } from '../../../../../queries/people/personVehicles';
import usePersonAddresses from '../../../../../queries/people/usePersonAddresses';
import usePersonRelatedPeople, { useCreateRelatedPerson } from '../../../../../queries/people/usePersonRelatedPeople';
import { convertNullishToEmpty } from '../../../../../utils/objectUtils';
import { StepContentProps } from '../..';

const initialDrivers = ({
  page,
  relatedPeople,
  drivers
}: {
  page: StepContentProps['page'];
  relatedPeople: IRelatedPerson[] | undefined;
  drivers: IRelatedPerson[] | undefined;
}) => {
  if (!!drivers?.length || page.is_completed) {
    return drivers || [];
  }

  return relatedPeople?.filter(isPersonAgedToDrive) || [];
};

// those are AutoOpportunities only
const useVehicleProfileData = ({ page }: { page: StepContentProps['page'] }) => {
  const { personGid, person, lead, leadGid, refetchPerson } = useGuidedSellingExperienceContext();
  const opportunities = page.opportunities || [];

  const vehicles = opportunities
    .filter(isVehicleOpportunity)
    .flatMap(({ assets }) => assets)
    .filter(Boolean);

  const {
    data: drivers,
    isFetchedAfterMount: driversFetched,
    refetch: refetchDrivers
  } = useDataCollectionDrivers({
    leadId: lead!.id
  });
  const updateDriversMutation = useUpdateDataCollectionDrivers();

  const updateDrivers = (peopleGids: string[]) => updateDriversMutation.mutateAsync({ leadId: lead!.id, peopleGids });

  const { data: personAddresses, isFetchedAfterMount: personAddressesFetched } = usePersonAddresses(personGid);

  const replaceVehiclesMutation = useReplaceDataCollectionVehicles();

  const replaceDataCollectionVehicles = (assetsGids: string[]) =>
    replaceVehiclesMutation.mutateAsync({ assetsGids, leadId: lead!.id });

  const { mutateAsync: createVehicle, isPending: isCreatingVehicle } = useCreateVehicle();
  const { mutateAsync: detachVehicle, isPending: isDetachingVehicle } = useDetachVehicle();

  const {
    data: relatedPeople,
    isFetchedAfterMount: relatedPeopleFetched,
    refetch: refetchRelatedPeople
  } = usePersonRelatedPeople(personGid);

  const { mutateAsync: addRelatedPerson, isPending: isAddingRelatedPerson } = useCreateRelatedPerson();

  const { data: additionalAnswers, isFetchedAfterMount: additionalAnswersFetched } = useAnswers({
    datapointsUsageKey: DatapointsUsageKey.VehicleProfileAdditionalInfo,
    personGid: personGid!,
    assetGid: null,
    relatedPersonGid: null
  });

  const isLoading = !relatedPeopleFetched || !personAddressesFetched || !driversFetched || !additionalAnswersFetched;

  const violationAnswer = additionalAnswers?.find(a => a.key === DatapointKey.PersonHadViolationIn5PastYears)?.value;

  const vehiclesRegistrationAnswer = additionalAnswers?.find(
    a => a.key === DatapointKey.PersonVehiclesTitledAndRegisteredToDrivers
  )?.value;

  const initialValues = {
    [DatapointKey.PersonHadViolationIn5PastYears]: violationAnswer || '',
    [DatapointKey.PersonVehiclesTitledAndRegisteredToDrivers]: vehiclesRegistrationAnswer || '',
    drivers: [
      convertNullishToEmpty({
        gid: person!.gid,
        first_name: person!.first_name,
        middle_name: person!.middle_name,
        last_name: person!.last_name,
        date_of_birth: person!.date_of_birth,
        marital_status: person!.marital_status,
        license_number: person!.license_number,
        license_state: person!.license_state,
        gender: person!.gender,
        name: person!.name
      }),
      ...initialDrivers({ page, relatedPeople, drivers })
        .filter(driver => driver.gid !== person?.gid)
        .map(driver =>
          convertNullishToEmpty({
            gid: driver.gid,
            first_name: driver.first_name,
            middle_name: driver.middle_name,
            last_name: driver.last_name,
            date_of_birth: driver.date_of_birth,
            kind: driver.kind,
            marital_status: driver.marital_status,
            license_number: driver.license_number,
            license_state: driver.license_state,
            gender: driver.gender,
            name: driver.name
          })
        )
    ] as (IRelatedPerson | IPerson)[],
    vehicles: (vehicles as IVehicle[]).map(
      vehicle =>
        ({
          ...convertNullishToEmpty({
            gid: vehicle.gid,
            address: vehicle.address,
            annual_mileage: vehicle.annual_mileage,
            days_per_week_driven: vehicle.days_per_week_driven,
            one_way_mileage: vehicle.one_way_mileage,
            make: vehicle.make,
            model: vehicle.model,
            year: vehicle.year?.toString() as unknown,
            ownership: vehicle.ownership,
            usage: vehicle.usage,
            vin: vehicle.vin,
            kind: vehicle.kind,
            submodel: vehicle.submodel
          }),
          ...annualMileageCalculatorInitialValues(vehicle as IVehicle)
        }) as IVehicle
    )
  };

  return {
    personGid: personGid!,
    person: person!,
    lead: lead!,
    leadGid: leadGid!,
    updateDrivers,
    refetchPerson,
    refetchDrivers,
    relatedPeople: relatedPeople!,
    refetchRelatedPeople,
    personAddresses,
    isLoading,
    replaceDataCollectionVehicles,
    detachVehicle,
    isDetachingVehicle,
    createVehicle,
    isCreatingVehicle,
    addRelatedPerson,
    isAddingRelatedPerson,
    initialValues,
    vehicles
  };
};

export default useVehicleProfileData;
