/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FieldArray } from 'formik';
import * as React from 'react';
import * as yup from 'yup';

import AddVehiclesModal from '../../../../components/AddVehiclesForm';
import { AutoSearchProvider } from '../../../../components/AddVehiclesForm/utils';
import Tooltip from '../../../../components/common/Tooltip/NewTooltip';
import alert from '../../../../components/core/Alert';
import SystemMessage from '../../../../components/core/Alert/SystemMessage';
import Blockquote from '../../../../components/core/Blockquote';
import AddButton from '../../../../components/core/buttons/AddButton';
import { ButtonVariant } from '../../../../components/core/buttons/Button';
import IconButton, { ButtonIcons } from '../../../../components/core/buttons/IconButton';
import Container from '../../../../components/core/Container';
import FlexBox from '../../../../components/core/FlexBox';
import FormLoader from '../../../../components/core/FormLoader';
import BaseForm from '../../../../components/core/forms/BaseForm';
import {
  AddressField,
  DateInputField,
  InputField,
  labelCss,
  RadioGroupField,
  SelectField
} from '../../../../components/core/forms/fields';
import Heading from '../../../../components/core/Heading';
import { UserIcon, WarningIcon } from '../../../../components/core/icons';
import Modal from '../../../../components/core/Modal';
import Paragraph from '../../../../components/core/Paragraph';
import QuestionVerificationStatusIcon, {
  QUESTION_VERIFICATION_STATUS_TOOLTIP
} from '../../../../components/core/QuestionVerificationStatusIcon';
import SwitchToggle from '../../../../components/core/SwitchToggle';
import VehicleKind from '../../../../components/core/VehicleKind';
import { Translations } from '../../../../constants';
import { API_TO_SMARTY_STREETS_MAPPING } from '../../../../constants/addressForm';
import featureFlags from '../../../../constants/featureFlags';
import { useConstant } from '../../../../hooks';
import useToggle from '../../../../hooks/useToggle';
import { IAddressSuggestion } from '../../../../interfaces';
import DatapointKey, { DatapointsUsageKey, NO_YES_OPTIONS } from '../../../../interfaces/IDatapoint';
import { opportunityDescription } from '../../../../interfaces/IOpportunity';
import { isPersonAgedToDrive } from '../../../../interfaces/IPerson';
import { buildVehicleTitle, IVehicle, notMotorcycleOrTrailer } from '../../../../interfaces/IVehicle';
import { useSaveAnswers } from '../../../../queries/answers/useAnswers';
// eslint-disable-next-line max-len
import { useUpdateOpportunityDataCollectionStatus } from '../../../../queries/leads/data_collection/useDataCollectionOpportunities';
import analytics from '../../../../services/analytics';
import colors from '../../../../theme/colors';
import { borderRadius, InputSize, sizes, spacings } from '../../../../theme/variables';
import { createSmartyStreetsMapping, renameKeys } from '../../../../utils/object';
import { convertNullishToEmpty } from '../../../../utils/objectUtils';
import { REQUIRED_MESSAGE, requiredField, requiredMinMaxDOBField, vehicleValidation } from '../../../../utils/yupRules';
import { VehicleFields } from '../../../GetQuotes/AssetsPicker/formHelpers';
import FinalizeLead from '../../_components/FinalizeLead';
import usePersonCoreBundleInterest from '../../_hooks/usePersonCoreBundleInterest';
import { StepContentProps } from '../.';
import AddDriversModal from '../_components/AddDriversModal';
import LeadWarnings from '../_components/LeadWarnings';
import OpportunityLicensingWarning from '../_components/OpportunityLicensingWarning';
import ScrollToFirstError from '../_components/ScrollToFirstError';
import VehicleDeleteModal from '../_components/VehicleDeleteModal';
import AnnualMileageCalculator from './_components/AnnualMileageCalculator';
import EnhanceWithVerificationStatus from './_components/EnhanceWithVerificationStatus';
import VerificationAnswersProvider from './_components/VerificationProvider';
import useVehicleProfileData from './_hooks';

const GENDER_INFO_TOOLTIP = 'Some our carriers still require this information to quote.';

const VehicleProfilePage = ({
  onSubmit,
  dataCollection,
  page,
  isDataEditingForbidden
}: StepContentProps): JSX.Element => {
  const [addVehicles, toggleAddVehicles] = useToggle(false);
  const [addDrivers, toggleAddDrivers] = useToggle(false);
  const [itemToDeleteGid, setItemToDeleteGid] = React.useState<string>();
  const todayDate = useConstant(() => new Date());
  const { mutateAsync: saveAnswers } = useSaveAnswers();

  const {
    personGid,
    person,
    lead,
    leadGid,
    updateDrivers,
    refetchDrivers,
    replaceDataCollectionVehicles,
    createVehicle,
    isCreatingVehicle,
    relatedPeople,
    addRelatedPerson,
    isAddingRelatedPerson,
    refetchRelatedPeople,
    isLoading,
    personAddresses,
    initialValues,
    refetchPerson,
    vehicles
  } = useVehicleProfileData({ page });

  const {
    mutateAsync: updateOpportunitiesDataCollectionStatus,
    isPending: isUpdatingOpportunitiesDataCollectionStatus
  } = useUpdateOpportunityDataCollectionStatus();
  const isDataCollectionDisabled = page.opportunities?.some(opportunity => !opportunity.is_data_collection_enabled);

  const missingLicenseOpportunities = page.opportunities?.filter(opportunity => !opportunity.is_user_licensed);

  const { isPersonInterestedInCoreBundle } = usePersonCoreBundleInterest({ personGid });
  const isNudgeMessageShown = !isDataCollectionDisabled && isPersonInterestedInCoreBundle;

  const nudgeMessage =
    "Wait! This customer indicated they were interested in bundling. Are you sure you don't want to quote auto?";

  if (isLoading) {
    return <FormLoader ph={spacings.px24} pv={spacings.px12} />;
  }

  const isEditingDisabled = page.is_disqualified || isDataEditingForbidden || isDataCollectionDisabled;

  const validationSchema = (isDataCollectionDisabled: boolean) => {
    if (isDataCollectionDisabled) {
      return yup.object({});
    }

    return yup.object().shape({
      vehicles: yup.array().of(vehicleValidation()),
      drivers: yup.array().of(
        yup.object().shape({
          gender: requiredField,
          marital_status: requiredField,
          date_of_birth: requiredMinMaxDOBField()
        })
      ),
      [DatapointKey.PersonHadViolationIn5PastYears]: yup.string().when('vehicles', {
        is: (vehicles: string[]) => !!vehicles.length,
        then: schema => schema.required(REQUIRED_MESSAGE),
        otherwise: undefined
      }),
      [DatapointKey.PersonVehiclesTitledAndRegisteredToDrivers]: yup.string().when('vehicles', {
        is: (vehicles: string[]) => !!vehicles.length,
        then: schema => schema.required(REQUIRED_MESSAGE),
        otherwise: undefined
      })
    });
  };

  return (
    <BaseForm
      pt={spacings.px12}
      pr={spacings.px24}
      pb={60}
      pl={spacings.px24}
      type="fullPage"
      controlsAlignment="right"
      controlsWidth={sizes.mediumFormInputWidth}
      footer={() => {
        return (
          <FlexBox
            customCss={css`
              flex: 1;
              justify-content: flex-end;
            `}
          >
            <FinalizeLead leadId={lead.id} personGid={person.gid} />
          </FlexBox>
        );
      }}
      submitText="Next"
      submitTestId="submit-vehicle-profile-button"
      cancelTestId="skip-vehicle-profile-button"
      cancelVariant={ButtonVariant.Secondary}
      validationSchema={validationSchema(!!isEditingDisabled)}
      disabled={isDataEditingForbidden}
      initialValues={initialValues}
      onSubmit={async values => {
        if (isEditingDisabled) {
          return await onSubmit();
        }

        const buildVehiclesAnswers = ({ gid, ...restOfVehicleValues }: IVehicle) => {
          const getDatapointKey = (key: string) => {
            switch (key) {
              case VehicleFields.DaysPerWeekDriven:
                return DatapointKey.VehicleDaysDrivenWeekly;
              case VehicleFields.OneWayMileage:
                return DatapointKey.VehicleDailyMileage;
              default:
                return `vehicle_${key}`;
            }
          };

          return Object.keys(restOfVehicleValues).map(key => {
            return {
              key: getDatapointKey(key),
              person_gid: personGid,
              engagement_gid: lead.gid,
              value: restOfVehicleValues[key as keyof typeof restOfVehicleValues],
              asset_gid: gid,
              related_person_gid: null
            };
          });
        };

        const buildDriversAnswers = ({
          gid,
          ...restOfDriverValues
        }: typeof person | (typeof values.drivers)[number]) => {
          return Object.keys(restOfDriverValues)
            .map(key => {
              if (key === 'kind' || key === 'name') {
                return null;
              }

              return {
                key: `person_${key}`,
                person_gid: gid,
                engagement_gid: lead.gid,
                value: restOfDriverValues[key as keyof typeof restOfDriverValues],
                asset_gid: null,
                related_person_gid: null
              };
            })
            .filter(Boolean);
        };

        const saveDriverRelationKind = (driver: (typeof values.drivers)[number]) => {
          if ('kind' in driver) {
            return saveAnswers({
              datapointsUsageKey: DatapointsUsageKey.PersonRelationKind,
              answers: [
                {
                  key: DatapointKey.PersonRelationKind,
                  person_gid: person.gid,
                  engagement_gid: lead.gid,
                  value: driver.kind,
                  asset_gid: null,
                  related_person_gid: driver.gid
                }
              ]
            });
          }
        };

        await Promise.all([
          values.vehicles.length &&
            saveAnswers({
              datapointsUsageKey: DatapointsUsageKey.VehicleProfileAdditionalInfo,
              answers: [
                {
                  key: DatapointKey.PersonHadViolationIn5PastYears,
                  person_gid: personGid,
                  engagement_gid: lead.gid,
                  value: values[DatapointKey.PersonHadViolationIn5PastYears],
                  asset_gid: null,
                  related_person_gid: null
                },
                {
                  key: DatapointKey.PersonVehiclesTitledAndRegisteredToDrivers,
                  person_gid: personGid,
                  engagement_gid: lead.gid,
                  value: values[DatapointKey.PersonVehiclesTitledAndRegisteredToDrivers],
                  asset_gid: null,
                  related_person_gid: null
                }
              ]
            }),
          ...values.vehicles.map(vehicle =>
            saveAnswers({
              datapointsUsageKey: DatapointsUsageKey.VehicleProfileInfo,
              answers: buildVehiclesAnswers(vehicle)
            })
          ),
          ...values.drivers.map(driver =>
            saveAnswers({
              datapointsUsageKey: DatapointsUsageKey.CustomerProfileInfo,
              answers: buildDriversAnswers(driver)
            })
          ),
          ...values.drivers.flatMap(driver =>
            driver.gid !== personGid && 'kind' in driver ? [saveDriverRelationKind(driver)] : []
          )
        ]);

        if (!values.vehicles.length) {
          alert({ message: 'Please make sure to add at least one vehicle', options: { autoClose: 3000 } }).warning();
        } else {
          await updateDrivers(values.drivers.map(d => d.gid).filter(gid => gid !== personGid));
          await refetchPerson();
          await onSubmit();
        }
      }}
      renderForm={({ values, initialValues, setFieldValue }) => {
        return (
          <VerificationAnswersProvider
            personGid={person.gid}
            driversGids={values.drivers.map(d => d.gid)}
            vehicleGids={values.vehicles.map(v => v.gid)}
          >
            {answersWithVerificationStatus => (
              <>
                <LeadWarnings dataCollection={dataCollection} />
                {!!missingLicenseOpportunities?.length &&
                  missingLicenseOpportunities.map(opportunity => (
                    <OpportunityLicensingWarning key={opportunity.id} opportunity={opportunity} mb={spacings.px8} />
                  ))}

                {page.opportunities
                  ?.filter(op => op.disqualification_reason)
                  .map(op => {
                    return (
                      <SystemMessage
                        key={op.id}
                        type="warning"
                        mt={spacings.px8}
                        mb={spacings.px8}
                        description={`${`${opportunityDescription(op) || ''} ${op.disqualification_message || ''}`}`}
                      />
                    );
                  })}
                <Heading
                  type="h4"
                  customCss={css`
                    margin-bottom: 24px;
                  `}
                >
                  Vehicle profile
                </Heading>
                <FieldArray
                  name="drivers"
                  render={driversArrayHelpers => {
                    const addedDriversGids = values.drivers.map(d => d.gid);
                    return (
                      <>
                        <FieldArray
                          name="vehicles"
                          render={vehiclesArrayHelpers => (
                            <>
                              {addVehicles && (
                                <AddVehiclesModal
                                  personGid={personGid}
                                  person={person}
                                  leadGid={leadGid}
                                  cancelHandler={toggleAddVehicles}
                                  isLoading={isCreatingVehicle}
                                  confirmHandler={async ({
                                    vehicles: vehiclesValues,
                                    prefilledVehiclesGarageAddress,
                                    prefilledVehicles,
                                    prefilledDrivers
                                  }) => {
                                    if (vehiclesValues.length > 0 || prefilledVehicles.length > 0) {
                                      const manualVehiclesResponse = await Promise.all(
                                        vehiclesValues.map(({ address, auto: { year, make, model, submodel, vin } }) =>
                                          createVehicle({
                                            personGid,
                                            data: { address, year, make, model, submodel, vin }
                                          })
                                        )
                                      );

                                      const prefilledVehiclesResponse = await Promise.all(
                                        prefilledVehicles.map(vehicle =>
                                          createVehicle({
                                            personGid,
                                            data: {
                                              ...vehicle,
                                              address: prefilledVehiclesGarageAddress,
                                              data_source: 'fenris'
                                            }
                                          })
                                        )
                                      );

                                      const vehiclesResponse = manualVehiclesResponse.concat(prefilledVehiclesResponse);

                                      manualVehiclesResponse.forEach(({ vehicle }) =>
                                        analytics.track('Vehicle added', {
                                          lead_gid: leadGid,
                                          person_gid: personGid,
                                          place: 'guided_selling_experience',
                                          source: 'manual',
                                          vehicle_gid: vehicle.gid
                                        })
                                      );

                                      prefilledVehiclesResponse.forEach(({ vehicle }) =>
                                        analytics.track('Vehicle added', {
                                          lead_gid: leadGid,
                                          person_gid: personGid,
                                          place: 'guided_selling_experience',
                                          source: 'fenris',
                                          vehicle_gid: vehicle.gid
                                        })
                                      );

                                      await replaceDataCollectionVehicles(
                                        [...values.vehicles, ...vehiclesResponse.map(({ vehicle }) => vehicle)].map(
                                          v => v.gid
                                        )
                                      );

                                      let lastAutoIndex = values.vehicles.findLastIndex(v =>
                                        notMotorcycleOrTrailer(v.kind)
                                      );

                                      vehiclesResponse.forEach(({ vehicle }) => {
                                        if (notMotorcycleOrTrailer(vehicle.kind)) {
                                          vehiclesArrayHelpers.insert(
                                            lastAutoIndex + 1,
                                            convertNullishToEmpty({
                                              gid: vehicle.gid,
                                              address: vehicle.address,
                                              annual_mileage: vehicle.annual_mileage,
                                              days_per_week_driven: vehicle.days_per_week_driven,
                                              one_way_mileage: vehicle.one_way_mileage,
                                              make: vehicle.make,
                                              model: vehicle.model,
                                              submodel: vehicle.submodel,
                                              year: vehicle.year?.toString() as unknown,
                                              ownership: vehicle.ownership,
                                              usage: vehicle.usage,
                                              vin: vehicle.vin,
                                              kind: vehicle.kind
                                            })
                                          );
                                          lastAutoIndex++;
                                        } else {
                                          vehiclesArrayHelpers.push(
                                            convertNullishToEmpty({
                                              gid: vehicle.gid,
                                              address: vehicle.address,
                                              annual_mileage: vehicle.annual_mileage,
                                              days_per_week_driven: vehicle.days_per_week_driven,
                                              one_way_mileage: vehicle.one_way_mileage,
                                              make: vehicle.make,
                                              model: vehicle.model,
                                              submodel: vehicle.submodel,
                                              year: vehicle.year?.toString() as unknown,
                                              ownership: vehicle.ownership,
                                              usage: vehicle.usage,
                                              vin: vehicle.vin,
                                              kind: vehicle.kind
                                            })
                                          );
                                        }
                                      });
                                    }

                                    if (prefilledDrivers.length) {
                                      const relatedPersonResponse = await Promise.all(
                                        prefilledDrivers.map(data =>
                                          addRelatedPerson({ sourcePersonGid: personGid, data })
                                        )
                                      );

                                      relatedPersonResponse.forEach(({ related_person }) =>
                                        driversArrayHelpers.push(
                                          convertNullishToEmpty({
                                            gid: related_person.gid,
                                            first_name: related_person.first_name,
                                            middle_name: related_person.middle_name,
                                            last_name: related_person.last_name,
                                            date_of_birth: related_person.date_of_birth,
                                            kind: related_person.kind,
                                            marital_status: related_person.marital_status,
                                            license_number: related_person.license_number,
                                            license_state: related_person.license_state,
                                            gender: related_person.gender,
                                            name: related_person.name
                                          })
                                        )
                                      );

                                      refetchRelatedPeople();
                                      refetchDrivers();

                                      analytics.track('Driver added', {
                                        lead_gid: leadGid,
                                        person_gid: personGid,
                                        place: 'guided_selling_experience',
                                        source: 'fenris'
                                      });
                                    }

                                    toggleAddVehicles();
                                  }}
                                />
                              )}
                              <FlexBox mb={spacings.px24} columnDirection gap={spacings.px8}>
                                <FlexBox alignItemsCenter justifySpaceBetween>
                                  <FlexBox gap={spacings.px8} alignItemsCenter>
                                    <Paragraph bold type="large">
                                      Insured vehicle
                                    </Paragraph>
                                    <AddButton
                                      onClick={toggleAddVehicles}
                                      content="Add vehicle"
                                      disabled={isDataEditingForbidden || isDataCollectionDisabled}
                                    />
                                  </FlexBox>
                                  <FlexBox alignItemsCenter gap={spacings.px4} data-for="bundle-skip-vehicle" data-tip>
                                    <Paragraph type="small">
                                      Data collection {isDataCollectionDisabled ? 'disabled' : 'enabled'}
                                    </Paragraph>

                                    <SwitchToggle
                                      testId="vehicle-profile-data-collection-toggle"
                                      checked={!isDataCollectionDisabled}
                                      onChange={() => {
                                        analytics.track(
                                          `Vehicle data collection ${
                                            isDataCollectionDisabled ? 're-enabled' : 'disabled'
                                          }`,
                                          {
                                            lead_gid: leadGid,
                                            person_gid: personGid
                                          }
                                        );

                                        updateOpportunitiesDataCollectionStatus({
                                          leadId: lead!.id,
                                          opportunitiesIds: page.opportunities!.map(opportunity => opportunity.id),
                                          dataCollectionEnabled: !!isDataCollectionDisabled
                                        });
                                      }}
                                      disabled={isUpdatingOpportunitiesDataCollectionStatus || isDataEditingForbidden}
                                    />
                                  </FlexBox>
                                  {isNudgeMessageShown && (
                                    <Tooltip
                                      id="bundle-skip-vehicle"
                                      theme="light"
                                      place="left"
                                      customCss={css`
                                        border-radius: ${borderRadius}px !important;
                                        border: 1px ${colors.grey10} solid !important;
                                      `}
                                    >
                                      <FlexBox>
                                        <WarningIcon
                                          color={colors.statusOrange}
                                          css={css`
                                            margin-right: 8px;
                                          `}
                                        />
                                        <Paragraph
                                          customCss={css`
                                            max-width: 400px;
                                          `}
                                        >
                                          {nudgeMessage}
                                        </Paragraph>
                                      </FlexBox>
                                    </Tooltip>
                                  )}
                                </FlexBox>
                                {values.vehicles.length ? (
                                  <FlexBox
                                    gap={spacings.px24}
                                    mb={spacings.px4}
                                    customCss={css`
                                      flex-wrap: wrap;
                                    `}
                                  >
                                    {values.vehicles.map((vehicle, index) => (
                                      <FlexBox key={vehicle.gid} gap={spacings.px8} alignItemsCenter>
                                        <Paragraph type="large">
                                          {buildVehicleTitle(vehicles.find(v => v.gid === vehicle.gid) as IVehicle)}
                                        </Paragraph>
                                        <IconButton
                                          data-testid={`vehicle-profile-delete-vehicle-${vehicle.gid}`}
                                          icon={ButtonIcons.Delete}
                                          color={colors.grey60}
                                          onClick={() => setItemToDeleteGid(vehicle.gid)}
                                          disabled={isDataEditingForbidden || isDataCollectionDisabled}
                                        />
                                        {itemToDeleteGid === vehicle.gid && (
                                          <VehicleDeleteModal
                                            cancelBtnHandler={() => setItemToDeleteGid(undefined)}
                                            confirmBtnHandler={vehicleGid => {
                                              analytics.track('Vehicle removed', {
                                                lead_gid: leadGid,
                                                person_gid: personGid,
                                                place: 'guided_selling_experience',
                                                vehicle_gid: vehicleGid
                                              });
                                              return vehiclesArrayHelpers.remove(index);
                                            }}
                                            leadId={lead!.id}
                                            vehicleGid={vehicle.gid}
                                            personGid={person.gid}
                                          />
                                        )}
                                      </FlexBox>
                                    ))}
                                  </FlexBox>
                                ) : null}
                                {!!values.vehicles?.length && (
                                  <>
                                    {values.vehicles.map((vehicle, index) => (
                                      <FlexBox columnDirection key={vehicle.gid}>
                                        <FlexBox alignItemsCenter gap={spacings.px8}>
                                          <Paragraph bold>
                                            {buildVehicleTitle(vehicles.find(v => v.gid === vehicle.gid) as IVehicle)}
                                          </Paragraph>
                                          <VehicleKind kind={vehicle.kind} />
                                        </FlexBox>
                                        <Container pl={spacings.px20}>
                                          {notMotorcycleOrTrailer(vehicle.kind) && (
                                            <>
                                              <EnhanceWithVerificationStatus
                                                entityGid={vehicle.gid}
                                                answerKey={DatapointKey.VehicleAddress}
                                                answersWithVerificationStatus={answersWithVerificationStatus}
                                                disabled={isDataEditingForbidden || !!isEditingDisabled}
                                              >
                                                <AddressField
                                                  inline
                                                  required
                                                  label="Garage address"
                                                  id={`vehicles.${index}.${VehicleFields.Address}`}
                                                  placeholder=""
                                                  defaultValue={
                                                    renameKeys(
                                                      API_TO_SMARTY_STREETS_MAPPING,
                                                      vehicle.address || {}
                                                    ) as IAddressSuggestion
                                                  }
                                                  defaultOptions={
                                                    (personAddresses?.map(address =>
                                                      renameKeys(API_TO_SMARTY_STREETS_MAPPING, address)
                                                    ) || []) as IAddressSuggestion[]
                                                  }
                                                  fallbackNames={createSmartyStreetsMapping(
                                                    `vehicles.${index}.address.`
                                                  )}
                                                  inputSize={InputSize.Large}
                                                  disabled={isEditingDisabled}
                                                />
                                              </EnhanceWithVerificationStatus>

                                              <AutoSearchProvider vehicle={vehicle}>
                                                {({
                                                  years,
                                                  setMake,
                                                  getMakes,
                                                  getModels,
                                                  getSubmodels,
                                                  setModel,
                                                  isPendingMakes,
                                                  isPendingModels,
                                                  isPendingYears,
                                                  isPendingSubmodels
                                                }) => {
                                                  return (
                                                    <>
                                                      <EnhanceWithVerificationStatus
                                                        entityGid={vehicle.gid}
                                                        answerKey={DatapointKey.VehicleYear}
                                                        answersWithVerificationStatus={answersWithVerificationStatus}
                                                        disabled={isDataEditingForbidden || !!isEditingDisabled}
                                                      >
                                                        <SelectField
                                                          required
                                                          id={`vehicles.${index}.year`}
                                                          name={`vehicles.${index}.year`}
                                                          label="Year"
                                                          isLoading={isPendingYears}
                                                          options={years}
                                                          createOptionFromSearch
                                                          inline
                                                        />
                                                      </EnhanceWithVerificationStatus>
                                                      <EnhanceWithVerificationStatus
                                                        entityGid={vehicle.gid}
                                                        answerKey={DatapointKey.VehicleMake}
                                                        answersWithVerificationStatus={answersWithVerificationStatus}
                                                        disabled={isDataEditingForbidden || !!isEditingDisabled}
                                                      >
                                                        <SelectField
                                                          required
                                                          id={`vehicles.${index}.make`}
                                                          name={`vehicles.${index}.make`}
                                                          label="Make"
                                                          isLoading={isPendingMakes}
                                                          options={getMakes(vehicle.make)}
                                                          onInputChange={(value, meta) => {
                                                            if (meta.action === 'input-change') {
                                                              setMake(value);
                                                              setFieldValue(`vehicles.${index}.make`, value);
                                                            }
                                                          }}
                                                          onChange={({ target: { value } }) => {
                                                            setMake(value);
                                                            setFieldValue(`vehicles.${index}.make`, value);
                                                            setFieldValue(`vehicles.${index}.model`, '');
                                                            setFieldValue(`vehicles.${index}.submodel`, '');
                                                          }}
                                                          createOptionFromSearch
                                                          inline
                                                        />
                                                      </EnhanceWithVerificationStatus>
                                                      <EnhanceWithVerificationStatus
                                                        entityGid={vehicle.gid}
                                                        answerKey={DatapointKey.VehicleModel}
                                                        answersWithVerificationStatus={answersWithVerificationStatus}
                                                        disabled={isDataEditingForbidden || !!isEditingDisabled}
                                                      >
                                                        <SelectField
                                                          required
                                                          id={`vehicles.${index}.model`}
                                                          name={`vehicles.${index}.model`}
                                                          label="Model"
                                                          isLoading={isPendingModels}
                                                          options={getModels(vehicle.model)}
                                                          onInputChange={(value, meta) => {
                                                            if (meta.action === 'input-change') {
                                                              setModel(value);
                                                              setFieldValue(`vehicles.${index}.model`, value);
                                                            }
                                                          }}
                                                          onChange={({ target: { value } }) => {
                                                            setModel(value);
                                                            setFieldValue(`vehicles.${index}.model`, value);
                                                            setFieldValue(`vehicles.${index}.submodel`, '');
                                                          }}
                                                          createOptionFromSearch
                                                          inline
                                                        />
                                                      </EnhanceWithVerificationStatus>
                                                      <EnhanceWithVerificationStatus
                                                        entityGid={vehicle.gid}
                                                        answerKey={DatapointKey.VehicleSubmodel}
                                                        answersWithVerificationStatus={answersWithVerificationStatus}
                                                        disabled={isDataEditingForbidden || !!isEditingDisabled}
                                                      >
                                                        <SelectField
                                                          id={`vehicles.${index}.submodel`}
                                                          name={`vehicles.${index}.submodel`}
                                                          label="Submodel"
                                                          isLoading={isPendingSubmodels}
                                                          options={getSubmodels(vehicle.submodel)}
                                                          createOptionFromSearch
                                                          inline
                                                        />
                                                      </EnhanceWithVerificationStatus>
                                                    </>
                                                  );
                                                }}
                                              </AutoSearchProvider>
                                              <EnhanceWithVerificationStatus
                                                entityGid={vehicle.gid}
                                                answerKey={DatapointKey.VehicleOwnership}
                                                answersWithVerificationStatus={answersWithVerificationStatus}
                                                disabled={isDataEditingForbidden || !!isEditingDisabled}
                                              >
                                                <SelectField
                                                  id={`vehicles.${index}.${VehicleFields.Ownership}`}
                                                  name={`vehicles.${index}.${VehicleFields.Ownership}`}
                                                  label="Ownership"
                                                  inline
                                                  options={Translations.vehicleOwnershipOptions}
                                                  required
                                                  disabled={isEditingDisabled}
                                                />
                                              </EnhanceWithVerificationStatus>

                                              <AnnualMileageCalculator
                                                vehicleValues={vehicle}
                                                valuesPath={`vehicles.${index}.`}
                                                disabled={isEditingDisabled}
                                                vehicleGid={vehicle.gid}
                                                answersWithVerificationStatus={answersWithVerificationStatus}
                                                verificationAlignment="left"
                                              />
                                            </>
                                          )}
                                          <EnhanceWithVerificationStatus
                                            entityGid={vehicle.gid}
                                            answerKey={DatapointKey.VehicleVin}
                                            answersWithVerificationStatus={answersWithVerificationStatus}
                                            disabled={isDataEditingForbidden || !!isEditingDisabled}
                                          >
                                            <InputField
                                              id={`vehicles.${index}.${VehicleFields.VIN}`}
                                              name={`vehicles.${index}.${VehicleFields.VIN}`}
                                              label="VIN"
                                              inline
                                              fsMask
                                              disabled={isEditingDisabled}
                                            />
                                          </EnhanceWithVerificationStatus>
                                        </Container>
                                      </FlexBox>
                                    ))}
                                  </>
                                )}
                                {!values.vehicles.length && (
                                  <Paragraph color={colors.grey60}>No auto info collected at this moment.</Paragraph>
                                )}
                              </FlexBox>
                            </>
                          )}
                        />
                        <>
                          {addDrivers && (
                            <AddDriversModal
                              addedDriversGids={addedDriversGids}
                              relatedPeople={relatedPeople}
                              cancelHandler={toggleAddDrivers}
                              isLoading={isAddingRelatedPerson}
                              confirmHandler={async ({ manualDrivers, relatedPersonDrivers }) => {
                                if (
                                  manualDrivers.length > 0 ||
                                  Object.values(relatedPersonDrivers).some(value => !!value)
                                ) {
                                  const relatedPersonResponse = await Promise.all(
                                    manualDrivers.map(data => addRelatedPerson({ sourcePersonGid: personGid, data }))
                                  );

                                  analytics.track('Driver added', {
                                    lead_gid: leadGid,
                                    person_gid: personGid,
                                    place: 'guided_selling_experience',
                                    source: 'manual'
                                  });

                                  const confirmedRelatedPeople = [person, ...relatedPeople].filter(
                                    person => !addedDriversGids.includes(person.gid) && relatedPersonDrivers[person.gid]
                                  );

                                  confirmedRelatedPeople.forEach(person =>
                                    driversArrayHelpers.push(
                                      convertNullishToEmpty({
                                        gid: person.gid,
                                        first_name: person.first_name,
                                        middle_name: person.middle_name,
                                        last_name: person.last_name,
                                        date_of_birth: person.date_of_birth,
                                        name: person.name,
                                        marital_status: person.marital_status,
                                        license_number: person.license_number,
                                        license_state: person.license_state,
                                        gender: person.gender
                                      })
                                    )
                                  );

                                  relatedPersonResponse.forEach(({ related_person }) =>
                                    driversArrayHelpers.push(
                                      convertNullishToEmpty({
                                        gid: related_person.gid,
                                        first_name: related_person.first_name,
                                        middle_name: related_person.middle_name,
                                        last_name: related_person.last_name,
                                        date_of_birth: related_person.date_of_birth,
                                        name: related_person.name,
                                        marital_status: related_person.marital_status,
                                        license_number: related_person.license_number,
                                        license_state: related_person.license_state,
                                        gender: related_person.gender,
                                        kind: related_person.kind
                                      })
                                    )
                                  );

                                  refetchRelatedPeople();
                                  refetchDrivers();
                                }

                                toggleAddDrivers();
                              }}
                            />
                          )}
                          <FlexBox columnDirection gap={spacings.px12}>
                            <FlexBox gap={spacings.px12} alignItemsCenter>
                              <Paragraph bold type="large">
                                Insured driver
                              </Paragraph>
                              <AddButton onClick={toggleAddDrivers} content="Add driver" disabled={isEditingDisabled} />
                            </FlexBox>
                            {featureFlags.vehicleProfileAdditionalDriversNudge && (
                              <Blockquote
                                ml={spacings.px32}
                                text={
                                  <Paragraph type="large">
                                    Is there anyone else in the household aged 15 years or older that may drive the
                                    vehicle, even if not regularly?
                                  </Paragraph>
                                }
                              />
                            )}
                            {values.drivers?.length ? (
                              <FlexBox
                                gap={spacings.px24}
                                mb={spacings.px4}
                                customCss={css`
                                  flex-wrap: wrap;
                                `}
                                data-testid="vehicle-profile-page-drivers-list"
                              >
                                {values.drivers.map((driver, index) => (
                                  <FlexBox key={driver.gid} gap={spacings.px8} alignItemsCenter>
                                    <Paragraph type="large" className="fs-mask">
                                      {driver.name}
                                    </Paragraph>
                                    {driver.gid === personGid ? (
                                      <UserIcon color={colors.grey60} />
                                    ) : (
                                      <IconButton
                                        data-testid={`delete-driver-${index}`}
                                        icon={ButtonIcons.Delete}
                                        color={colors.grey60}
                                        onClick={() => setItemToDeleteGid(driver.gid)}
                                        disabled={isEditingDisabled}
                                      />
                                    )}

                                    {itemToDeleteGid === driver.gid && (
                                      <Modal
                                        dangerModal
                                        title="Do you want to delete the driver?"
                                        confirmText="Yes, delete"
                                        confirmHandler={() => {
                                          analytics.track('Driver removed', {
                                            lead_gid: leadGid,
                                            person_gid: personGid,
                                            place: 'guided_selling_experience'
                                          });
                                          driversArrayHelpers.remove(index);
                                          setItemToDeleteGid(undefined);
                                        }}
                                        cancelHandler={() => {
                                          setItemToDeleteGid(undefined);
                                        }}
                                      >
                                        <Paragraph>
                                          You&apos;re going to delete driver{' '}
                                          <span className="fs-mask">{driver.name}</span>. You will not see this person
                                          in the list of coinsureds.
                                        </Paragraph>
                                      </Modal>
                                    )}
                                  </FlexBox>
                                ))}
                              </FlexBox>
                            ) : null}
                            {values.drivers?.length ? (
                              <>
                                {values.drivers.map((driver, index) => {
                                  const driverDOB = initialValues.drivers.find(
                                    d => d.gid === driver.gid
                                  )?.date_of_birth;
                                  const showDOBField = driverDOB
                                    ? !isPersonAgedToDrive({ date_of_birth: driverDOB })
                                    : true;

                                  return (
                                    <FlexBox columnDirection key={driver.gid}>
                                      <Paragraph className="fs-mask" bold>
                                        {driver.name}
                                      </Paragraph>
                                      <Container pl={spacings.px20}>
                                        {showDOBField && (
                                          <EnhanceWithVerificationStatus
                                            entityGid={driver.gid}
                                            answerKey={DatapointKey.PersonDateOfBirth}
                                            answersWithVerificationStatus={answersWithVerificationStatus}
                                            disabled={isDataEditingForbidden || !!isEditingDisabled}
                                          >
                                            <DateInputField
                                              fsMask
                                              inline
                                              label="DOB"
                                              id={`drivers.${index}.date_of_birth`}
                                              name={`drivers.${index}.date_of_birth`}
                                              maxDate={todayDate}
                                              required={!isDataCollectionDisabled}
                                              disabled={isEditingDisabled}
                                            />
                                          </EnhanceWithVerificationStatus>
                                        )}
                                        <EnhanceWithVerificationStatus
                                          entityGid={driver.gid}
                                          answerKey={DatapointKey.PersonGender}
                                          answersWithVerificationStatus={answersWithVerificationStatus}
                                          disabled={isDataEditingForbidden || !!isEditingDisabled}
                                        >
                                          <SelectField
                                            id={`drivers.${index}.gender`}
                                            name={`drivers.${index}.gender`}
                                            label="Gender"
                                            info={GENDER_INFO_TOOLTIP}
                                            inline
                                            required={!isDataCollectionDisabled}
                                            options={Translations.genderOptions}
                                            disabled={isEditingDisabled}
                                          />
                                        </EnhanceWithVerificationStatus>

                                        {!initialValues.drivers.find(d => d.gid === driver.gid)?.marital_status && (
                                          <EnhanceWithVerificationStatus
                                            entityGid={driver.gid}
                                            answerKey={DatapointKey.PersonMaritalStatus}
                                            answersWithVerificationStatus={answersWithVerificationStatus}
                                            disabled={isDataEditingForbidden || !!isEditingDisabled}
                                          >
                                            <SelectField
                                              id={`drivers.${index}.marital_status`}
                                              name={`drivers.${index}.marital_status`}
                                              label="Marital status"
                                              inline
                                              required={!isDataCollectionDisabled}
                                              options={Translations.maritalStatusOptions}
                                              disabled={isEditingDisabled}
                                            />
                                          </EnhanceWithVerificationStatus>
                                        )}
                                        <EnhanceWithVerificationStatus
                                          entityGid={driver.gid}
                                          answerKey={DatapointKey.PersonLicenseNumber}
                                          answersWithVerificationStatus={answersWithVerificationStatus}
                                          disabled={isDataEditingForbidden || !!isEditingDisabled}
                                        >
                                          <InputField
                                            fsMask
                                            id={`drivers.${index}.license_number`}
                                            name={`drivers.${index}.license_number`}
                                            label="License number"
                                            inline
                                            disabled={isEditingDisabled}
                                          />
                                        </EnhanceWithVerificationStatus>
                                        <EnhanceWithVerificationStatus
                                          entityGid={driver.gid}
                                          answerKey={DatapointKey.PersonLicenseState}
                                          answersWithVerificationStatus={answersWithVerificationStatus}
                                          disabled={isDataEditingForbidden || !!isEditingDisabled}
                                        >
                                          <SelectField
                                            id={`drivers.${index}.license_state`}
                                            name={`drivers.${index}.license_state`}
                                            label="License state"
                                            inline
                                            options={Translations.usaStates}
                                            disabled={isEditingDisabled}
                                          />
                                        </EnhanceWithVerificationStatus>
                                      </Container>
                                    </FlexBox>
                                  );
                                })}
                              </>
                            ) : (
                              <Paragraph color={colors.grey60}>No driver info collected at this moment.</Paragraph>
                            )}
                          </FlexBox>
                        </>
                      </>
                    );
                  }}
                />
                <ScrollToFirstError />
                <Tooltip id={QUESTION_VERIFICATION_STATUS_TOOLTIP} />
                {values.vehicles.length ? (
                  <FlexBox columnDirection mt={spacings.px32} gap={spacings.px24}>
                    <Paragraph type="large" bold>
                      Additional questions
                    </Paragraph>

                    <FlexBox pl={spacings.px20} gap={spacings.px4}>
                      {answersWithVerificationStatus[person.gid]?.[DatapointKey.PersonHadViolationIn5PastYears] && (
                        <Container>
                          <QuestionVerificationStatusIcon
                            verificationStatus={
                              answersWithVerificationStatus[person.gid][DatapointKey.PersonHadViolationIn5PastYears]
                                .verificationStatus
                            }
                            disabled={isDataEditingForbidden}
                            css={css`
                              margin-left: -${spacings.px20}px;
                            `}
                          />
                        </Container>
                      )}
                      <RadioGroupField
                        label={({ required }) => (
                          <Paragraph customCss={labelCss(required)}>
                            Have any driver had any tickets, accidents, or license suspensions in the past 5 years?
                          </Paragraph>
                        )}
                        inline
                        required
                        testId={DatapointKey.PersonHadViolationIn5PastYears}
                        id={DatapointKey.PersonHadViolationIn5PastYears}
                        name={DatapointKey.PersonHadViolationIn5PastYears}
                        options={NO_YES_OPTIONS}
                      />
                    </FlexBox>

                    <FlexBox pl={spacings.px20} gap={spacings.px4}>
                      {answersWithVerificationStatus[person.gid]?.[
                        DatapointKey.PersonVehiclesTitledAndRegisteredToDrivers
                      ] && (
                        <Container>
                          <QuestionVerificationStatusIcon
                            verificationStatus={
                              answersWithVerificationStatus[person.gid][
                                DatapointKey.PersonVehiclesTitledAndRegisteredToDrivers
                              ].verificationStatus
                            }
                            disabled={isDataEditingForbidden}
                            css={css`
                              margin-left: -${spacings.px20}px;
                            `}
                          />
                        </Container>
                      )}
                      <RadioGroupField
                        label={({ required }) => (
                          <Paragraph customCss={labelCss(required)}>
                            Are your vehicles titled and registered to the drivers?
                          </Paragraph>
                        )}
                        inline
                        required
                        testId={DatapointKey.PersonVehiclesTitledAndRegisteredToDrivers}
                        id={DatapointKey.PersonVehiclesTitledAndRegisteredToDrivers}
                        name={DatapointKey.PersonVehiclesTitledAndRegisteredToDrivers}
                        options={NO_YES_OPTIONS}
                      />
                    </FlexBox>
                  </FlexBox>
                ) : null}
              </>
            )}
          </VerificationAnswersProvider>
        );
      }}
    />
  );
};
export default VehicleProfilePage;
