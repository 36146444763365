/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import Button, { ButtonVariant } from '../../../../../components/core/buttons/Button';
import Container from '../../../../../components/core/Container';
import FlexBox from '../../../../../components/core/FlexBox';
import { CheckboxField } from '../../../../../components/core/forms/fields';
import { WarningIcon } from '../../../../../components/core/icons';
import Paragraph, { ParagraphType } from '../../../../../components/core/Paragraph';
import Text from '../../../../../components/core/Text';
import { useToggle } from '../../../../../hooks';
import colors from '../../../../../theme/colors';
import { spacings } from '../../../../../theme/variables';
import ReportsModal from './ReportsModal';

export const UNIVERSAL_CONSENT_FIELD_KEY = 'universal_carrier_consent';

const UniversalCarrierConsent = () => {
  const [reportModalOpened, toggleReportsModal] = useToggle(false);
  return (
    <FlexBox
      ph={spacings.px24}
      pv={spacings.px12}
      roundBorder
      backgroundColor={colors.warningBackground}
      columnDirection
      gap={spacings.px8}
    >
      <FlexBox gap={spacings.px8}>
        <Container>
          <WarningIcon color={colors.statusOrange} />
        </Container>
        <Paragraph type={ParagraphType.Small} color={colors.grey80}>
          To prevent policy cancellation, you may need to gather evidence of roof improvement or proof of prior policy
          and submit it to Universal. Additionally, If a loss is reported by carrier or customer, you must manually
          enter in the loss using &quot;add prior loss&quot; button
        </Paragraph>
      </FlexBox>
      <CheckboxField
        preserveErrorSpace={false}
        id={UNIVERSAL_CONSENT_FIELD_KEY}
        name={UNIVERSAL_CONSENT_FIELD_KEY}
        label={
          <Text>
            I have added all prior losses on the Universal application.{' '}
            <Button
              onClick={() => toggleReportsModal()}
              variant={ButtonVariant.Text}
              customCss={css`
                &::after {
                  content: '*';
                  color: ${colors.statusRed};
                }
              `}
            >
              Show me how to run reports
            </Button>
          </Text>
        }
      />
      {reportModalOpened && <ReportsModal cancelHandler={() => toggleReportsModal()} />}
    </FlexBox>
  );
};

export default UniversalCarrierConsent;
