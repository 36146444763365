/** @jsxImportSource @emotion/react */
import { useQueryClient } from '@tanstack/react-query';
import { FieldArray } from 'formik';
import * as React from 'react';
import * as yup from 'yup';

import { AutoSearchProvider } from '../../../../../components/AddVehiclesForm/utils';
import Tooltip from '../../../../../components/common/Tooltip/NewTooltip';
import Button, { ButtonSize, ButtonVariant } from '../../../../../components/core/buttons/Button';
import FlexBox from '../../../../../components/core/FlexBox';
import BaseForm from '../../../../../components/core/forms/BaseForm';
import { AddressField, InputField, SelectField } from '../../../../../components/core/forms/fields';
import Paragraph from '../../../../../components/core/Paragraph';
import { QUESTION_VERIFICATION_STATUS_TOOLTIP } from '../../../../../components/core/QuestionVerificationStatusIcon';
import { formInputLabelStyle } from '../../../../../components/UIFlow/input.styles';
import { Translations } from '../../../../../constants';
import { API_TO_SMARTY_STREETS_MAPPING } from '../../../../../constants/addressForm';
import { IAddressSuggestion } from '../../../../../interfaces';
import DatapointKey, { DatapointsUsageKey } from '../../../../../interfaces/IDatapoint';
import { InsurableInterest } from '../../../../../interfaces/IPolicyType';
import { buildVehicleTitle, IVehicle, notMotorcycleOrTrailer } from '../../../../../interfaces/IVehicle';
import { useSaveAnswers } from '../../../../../queries/answers/useAnswers';
import { useReconcileOpportunities } from '../../../../../queries/leads/data_collection/useDataCollectionOpportunities';
// eslint-disable-next-line max-len
import { PERSON_OPPORTUNITIES_QUERY_KEY } from '../../../../../queries/people/person_opportunities/usePersonOpportunities';
import usePersonAddresses from '../../../../../queries/people/usePersonAddresses';
import { InputSize, LabelSize, spacings } from '../../../../../theme/variables';
import { createSmartyStreetsMapping, renameKeys } from '../../../../../utils/object';
import { convertNullishToEmpty } from '../../../../../utils/objectUtils';
import { REQUIRED_MESSAGE, vehicleValidation } from '../../../../../utils/yupRules';
import { VehicleFields } from '../../../../GetQuotes/AssetsPicker/formHelpers';
import AnnualMileageCalculator, {
  annualMileageCalculatorInitialValues
} from '../../../GuidedDataCollection/VehicleProfilePage/_components/AnnualMileageCalculator';
//eslint-disable-next-line max-len
import {
  useAdditionalPersonVehicleAnswers,
  useVehiclesWithVerificationStatus
} from '../../../GuidedDataCollection/VehicleProfilePage/_hooks/useVehicleProfileAnswersWithVerificationAnswers';

const NO_YES_OPTIONS = [
  { value: 'No', key: 'no' },
  { value: 'Yes', key: 'yes' }
];

const VehiclesForm = ({
  vehicles,
  personGid,
  toggleEdit,
  leadId,
  leadGid
}: {
  vehicles: IVehicle[];
  personGid: string;
  toggleEdit: () => void;
  leadId: number | undefined;
  leadGid: string | undefined;
}) => {
  const { data: personAddresses, isPending: isPendingAddresses } = usePersonAddresses(personGid);
  const { mutateAsync: saveAnswers } = useSaveAnswers();
  const { mutateAsync: reconcileOpportunities } = useReconcileOpportunities();
  const queryClient = useQueryClient();
  const answersWithVerificationStatus = useVehiclesWithVerificationStatus({
    personGid,
    vehicleGids: vehicles.map(({ gid }) => gid)
  });

  const additionalPersonAnswers = useAdditionalPersonVehicleAnswers({
    personGid
  });

  const violationAnswer = additionalPersonAnswers[personGid]?.[DatapointKey.PersonHadViolationIn5PastYears];
  const vehiclesStatusAnswer =
    additionalPersonAnswers[personGid]?.[DatapointKey.PersonVehiclesTitledAndRegisteredToDrivers];

  if (!(violationAnswer && vehiclesStatusAnswer)) {
    return null;
  }

  return (
    <BaseForm
      pt={spacings.px0}
      pr={spacings.px0}
      pb={spacings.px0}
      pl={spacings.px0}
      customControls
      initialValues={{
        [DatapointKey.PersonHadViolationIn5PastYears]: violationAnswer.value || '',
        [DatapointKey.PersonVehiclesTitledAndRegisteredToDrivers]: vehiclesStatusAnswer.value || '',
        vehicles: vehicles.map(vehicle => ({
          gid: vehicle.gid,
          year: (vehicle.year?.toString() || '') as unknown,
          make: vehicle.make || '',
          model: vehicle.model || '',
          submodel: vehicle.submodel || '',
          [VehicleFields.Address]: convertNullishToEmpty(vehicle.address),
          [VehicleFields.Ownership]: vehicle.ownership || '',
          [VehicleFields.VIN]: vehicle.vin || '',
          [VehicleFields.Kind]: vehicle.kind || ('' as IVehicle['kind']),
          ...annualMileageCalculatorInitialValues(vehicle)
        }))
      }}
      validationSchema={yup.object().shape({
        [DatapointKey.PersonHadViolationIn5PastYears]: yup.string().when('vehicles', {
          is: (vehicles: string[]) => !!vehicles.length,
          then: schema => schema.required(REQUIRED_MESSAGE),
          otherwise: undefined
        }),
        [DatapointKey.PersonVehiclesTitledAndRegisteredToDrivers]: yup.string().when('vehicles', {
          is: (vehicles: string[]) => !!vehicles.length,
          then: schema => schema.required(REQUIRED_MESSAGE),
          otherwise: undefined
        }),
        vehicles: yup.array().of(vehicleValidation())
      })}
      onSubmit={async values => {
        const buildVehiclesAnswers = ({ gid, ...restOfVehicleValues }: IVehicle) => {
          const getDatapointKey = (key: string) => {
            switch (key) {
              case VehicleFields.DaysPerWeekDriven:
                return DatapointKey.VehicleDaysDrivenWeekly;
              case VehicleFields.OneWayMileage:
                return DatapointKey.VehicleDailyMileage;
              default:
                return `vehicle_${key}`;
            }
          };

          return Object.keys(restOfVehicleValues).map(key => {
            return {
              key: getDatapointKey(key),
              person_gid: personGid,
              engagement_gid: leadGid || null,
              value: restOfVehicleValues[key as keyof typeof restOfVehicleValues],
              asset_gid: gid,
              related_person_gid: null
            };
          });
        };

        await Promise.all([
          values.vehicles.length &&
            saveAnswers({
              datapointsUsageKey: DatapointsUsageKey.VehicleProfileAdditionalInfo,
              answers: [
                {
                  key: DatapointKey.PersonHadViolationIn5PastYears,
                  person_gid: personGid,
                  engagement_gid: leadGid || null,
                  value: values[DatapointKey.PersonHadViolationIn5PastYears],
                  asset_gid: null,
                  related_person_gid: null
                },
                {
                  key: DatapointKey.PersonVehiclesTitledAndRegisteredToDrivers,
                  person_gid: personGid,
                  engagement_gid: leadGid || null,
                  value: values[DatapointKey.PersonVehiclesTitledAndRegisteredToDrivers],
                  asset_gid: null,
                  related_person_gid: null
                }
              ]
            }),
          ...values.vehicles.map(vehicle =>
            saveAnswers({
              datapointsUsageKey: DatapointsUsageKey.VehicleProfileInfo,
              answers: buildVehiclesAnswers(vehicle as IVehicle)
            })
          )
        ]);
        leadId && (await reconcileOpportunities({ leadId, insurableInterest: InsurableInterest.Vehicle }));
        queryClient.invalidateQueries({ queryKey: [PERSON_OPPORTUNITIES_QUERY_KEY] });
        toggleEdit();
      }}
      renderForm={({ values, isSubmitting, setFieldValue }) => {
        return (
          <>
            <FieldArray
              name="vehicles"
              render={() => {
                return values.vehicles.map((vehicle, index) => {
                  return (
                    <React.Fragment key={vehicle.gid}>
                      <FlexBox columnDirection>
                        <FlexBox justifySpaceBetween alignItemsCenter>
                          <Paragraph type="small" bold>
                            {buildVehicleTitle(vehicles.find(v => v.gid === vehicle.gid) as IVehicle)}
                          </Paragraph>
                          {index === 0 && (
                            <FlexBox gap={spacings.px8}>
                              <Button
                                data-testid="cancel-panel-vehicles-form"
                                variant={ButtonVariant.Secondary}
                                size={ButtonSize.Small}
                                type="button"
                                loading={isSubmitting}
                                onClick={() => toggleEdit()}
                              >
                                Cancel
                              </Button>
                              <Button
                                data-testid="submit-panel-vehicles-form"
                                variant={ButtonVariant.Default}
                                size={ButtonSize.Small}
                                type="submit"
                                loading={isSubmitting}
                              >
                                Save
                              </Button>
                            </FlexBox>
                          )}
                        </FlexBox>
                        {vehicle.kind && notMotorcycleOrTrailer(vehicle.kind) && (
                          <>
                            <AddressField
                              inline
                              required
                              disabled={isPendingAddresses}
                              manualEntryAllowed={false}
                              label="Garage address"
                              id={`vehicles.${index}.${VehicleFields.Address}`}
                              placeholder=""
                              defaultValue={
                                renameKeys(API_TO_SMARTY_STREETS_MAPPING, vehicle.address || {}) as IAddressSuggestion
                              }
                              defaultOptions={
                                (personAddresses?.map(address => renameKeys(API_TO_SMARTY_STREETS_MAPPING, address)) ||
                                  []) as IAddressSuggestion[]
                              }
                              fallbackNames={createSmartyStreetsMapping(`vehicles.${index}.address.`)}
                              inputSize={InputSize.Medium}
                              labelSize={LabelSize.Small}
                              customLabelCss={formInputLabelStyle}
                              verificationStatus={
                                answersWithVerificationStatus[vehicle.gid]?.[DatapointKey.VehicleAddress]
                                  ?.verificationStatus
                              }
                            />

                            <AutoSearchProvider vehicle={vehicle as IVehicle}>
                              {({
                                years,
                                setMake,
                                getMakes,
                                getModels,
                                getSubmodels,
                                setModel,
                                isPendingMakes,
                                isPendingYears,
                                isPendingModels,
                                isPendingSubmodels
                              }) => (
                                <>
                                  <SelectField
                                    required
                                    id={`vehicles.${index}.year`}
                                    name={`vehicles.${index}.year`}
                                    label="Year"
                                    isLoading={isPendingYears}
                                    options={years}
                                    createOptionFromSearch
                                    inline
                                    labelSize={LabelSize.Small}
                                    inputSize={InputSize.Medium}
                                    customLabelCss={formInputLabelStyle}
                                    verificationStatus={
                                      answersWithVerificationStatus[vehicle.gid]?.[DatapointKey.VehicleYear]
                                        ?.verificationStatus
                                    }
                                  />
                                  <SelectField
                                    required
                                    id={`vehicles.${index}.make`}
                                    name={`vehicles.${index}.make`}
                                    label="Make"
                                    isLoading={isPendingMakes}
                                    options={getMakes(vehicle.make)}
                                    onInputChange={(value, meta) => {
                                      if (meta.action === 'input-change') {
                                        setMake(value);
                                        setFieldValue(`vehicles.${index}.make`, value);
                                      }
                                    }}
                                    onChange={({ target: { value } }) => {
                                      setMake(value);
                                      setFieldValue(`vehicles.${index}.make`, value);
                                      setFieldValue(`vehicles.${index}.model`, '');
                                      setFieldValue(`vehicles.${index}.submodel`, '');
                                    }}
                                    createOptionFromSearch
                                    inline
                                    labelSize={LabelSize.Small}
                                    inputSize={InputSize.Medium}
                                    customLabelCss={formInputLabelStyle}
                                    verificationStatus={
                                      answersWithVerificationStatus[vehicle.gid]?.[DatapointKey.VehicleMake]
                                        ?.verificationStatus
                                    }
                                  />
                                  <SelectField
                                    required
                                    id={`vehicles.${index}.model`}
                                    name={`vehicles.${index}.model`}
                                    label="Model"
                                    options={getModels(vehicle.model)}
                                    onInputChange={(value, meta) => {
                                      if (meta.action === 'input-change') {
                                        setModel(value);
                                        setFieldValue(`vehicles.${index}.model`, value);
                                      }
                                    }}
                                    onChange={({ target: { value } }) => {
                                      setModel(value);
                                      setFieldValue(`vehicles.${index}.model`, value);
                                      setFieldValue(`vehicles.${index}.submodel`, '');
                                    }}
                                    isLoading={isPendingModels}
                                    inline
                                    labelSize={LabelSize.Small}
                                    inputSize={InputSize.Medium}
                                    customLabelCss={formInputLabelStyle}
                                    verificationStatus={
                                      answersWithVerificationStatus[vehicle.gid]?.[DatapointKey.VehicleModel]
                                        ?.verificationStatus
                                    }
                                  />
                                  <SelectField
                                    id={`vehicles.${index}.submodel`}
                                    name={`vehicles.${index}.submodel`}
                                    label="Submodel"
                                    inline
                                    options={getSubmodels(vehicle.submodel)}
                                    isLoading={isPendingSubmodels}
                                    labelSize={LabelSize.Small}
                                    inputSize={InputSize.Medium}
                                    customLabelCss={formInputLabelStyle}
                                    verificationStatus={
                                      answersWithVerificationStatus[vehicle.gid]?.[DatapointKey.VehicleSubmodel]
                                        ?.verificationStatus
                                    }
                                  />
                                </>
                              )}
                            </AutoSearchProvider>
                            <SelectField
                              id={`vehicles.${index}.${VehicleFields.Ownership}`}
                              name={`vehicles.${index}.${VehicleFields.Ownership}`}
                              label="Ownership"
                              inline
                              options={Translations.vehicleOwnershipOptions}
                              required
                              labelSize={LabelSize.Small}
                              inputSize={InputSize.Medium}
                              customLabelCss={formInputLabelStyle}
                              verificationStatus={
                                answersWithVerificationStatus[vehicle.gid]?.[DatapointKey.VehicleOwnership]
                                  ?.verificationStatus
                              }
                            />
                            <AnnualMileageCalculator
                              vehicleValues={vehicle}
                              valuesPath={`vehicles.${[index]}.`}
                              labelSize={LabelSize.Small}
                              inputSize={InputSize.Medium}
                              customLabelCss={formInputLabelStyle}
                              vehicleGid={vehicle.gid}
                              answersWithVerificationStatus={answersWithVerificationStatus}
                              verificationAlignment="right"
                            />
                          </>
                        )}
                        <InputField
                          fsMask
                          id={`vehicles.${index}.${VehicleFields.VIN}`}
                          name={`vehicles.${index}.${VehicleFields.VIN}`}
                          label="VIN"
                          inline
                          labelSize={LabelSize.Small}
                          inputSize={InputSize.Medium}
                          customLabelCss={formInputLabelStyle}
                          verificationStatus={
                            answersWithVerificationStatus[vehicle.gid]?.[DatapointKey.VehicleVin]?.verificationStatus
                          }
                        />
                      </FlexBox>
                    </React.Fragment>
                  );
                });
              }}
            />
            {values.vehicles.length ? (
              <FlexBox columnDirection mt={spacings.px16} gap={spacings.px12}>
                <Paragraph type="small" bold>
                  Additional questions
                </Paragraph>

                <SelectField
                  id={DatapointKey.PersonHadViolationIn5PastYears}
                  name={DatapointKey.PersonHadViolationIn5PastYears}
                  label="Tickets, accidents, violations in 5 years"
                  inline
                  options={NO_YES_OPTIONS}
                  required
                  labelSize={LabelSize.Small}
                  inputSize={InputSize.Medium}
                  customLabelCss={formInputLabelStyle}
                  verificationStatus={violationAnswer.verificationStatus}
                />

                <SelectField
                  id={DatapointKey.PersonVehiclesTitledAndRegisteredToDrivers}
                  name={DatapointKey.PersonVehiclesTitledAndRegisteredToDrivers}
                  label="Vehicles titled and registered to drivers"
                  inline
                  options={NO_YES_OPTIONS}
                  required
                  labelSize={LabelSize.Small}
                  inputSize={InputSize.Medium}
                  customLabelCss={formInputLabelStyle}
                  verificationStatus={vehiclesStatusAnswer.verificationStatus}
                />
              </FlexBox>
            ) : null}
            <Tooltip id={QUESTION_VERIFICATION_STATUS_TOOLTIP} />
          </>
        );
      }}
    />
  );
};

export default VehiclesForm;
