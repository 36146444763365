/** @jsxImportSource @emotion/react */
import * as React from 'react';

import Paragraph from '../../../../components/core/Paragraph';
import { useGuidedSellingExperienceContext } from '../../../../contexts/GuidedSellingExperienceContext';
import useLeadDispositions from '../../../../queries/leads/dispositions/useLeadDispositions';
import useLeadDispositionsVersions from '../../../../queries/leads/dispositions/useLeadDispositionsVersions';
import usePartners from '../../../../queries/partners/usePartners';
import VerticalTabContent from '../../_components/VerticalTabs/VerticalTabContent';
import VerticalTabHeader from '../../_components/VerticalTabs/VerticalTabHeader';
import TabSkeleton from '../_components/TabSkeleton';
import LeadEngagement from './LeadEngagement';
import LeadFeedbacks from './LeadFeedbacks';
import LeadStatuses from './LeadStatuses';
import LeadType from './LeadType';

const ManagerTab = () => {
  const { lead, isLoading } = useGuidedSellingExperienceContext();
  const { data: partners } = usePartners();
  const { data: dispositionsVersions } = useLeadDispositionsVersions({ leadGid: lead!.gid });

  const { data: leadDispositions } = useLeadDispositions(lead!.id);

  const isInfoLoading = isLoading || !lead || !partners || !leadDispositions || !dispositionsVersions;

  return (
    <>
      <VerticalTabHeader>
        <Paragraph type="large">Lead operations</Paragraph>
      </VerticalTabHeader>
      <VerticalTabContent>
        {isInfoLoading && <TabSkeleton />}
        {!isInfoLoading && (
          <>
            <LeadEngagement
              lead={lead}
              leadDispositions={leadDispositions}
              partners={partners}
              dispositionsVersions={dispositionsVersions}
            />
            <LeadStatuses lead={lead} leadDispositions={leadDispositions} dispositionsVersions={dispositionsVersions} />
            <LeadType lead={lead} />
            {<LeadFeedbacks lead={lead} />}
          </>
        )}
      </VerticalTabContent>
    </>
  );
};

export default ManagerTab;
