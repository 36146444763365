/** @jsxImportSource @emotion/react */
import { ClassNames } from '@emotion/react';

import Container from '../../../../components/core/Container';
import Copy from '../../../../components/core/Copy';
import CopyableAddress from '../../../../components/core/CopyableAddress';
import DescriptionList from '../../../../components/core/DescriptionList';
import FlexBox from '../../../../components/core/FlexBox';
import Tag from '../../../../components/core/Tag';
import TelephonyActions from '../../../../components/core/TelephonyActions';
import TelephonyTooltip from '../../../../components/core/TelephonyTooltip';
import Text from '../../../../components/core/Text';
import { Translations } from '../../../../constants';
import { useGuidedSellingExperienceContext } from '../../../../contexts/GuidedSellingExperienceContext';
import usePersonDNC from '../../../../queries/people/dncStatus';
import usePersonRelatedPeople from '../../../../queries/people/usePersonRelatedPeople';
import authInfo from '../../../../services/authInfo';
import colors from '../../../../theme/colors';
import { spacings } from '../../../../theme/variables';
import { phoneFormatter } from '../../../../utils/formatter';
import { isCustomerDataCompletenessHigh } from '../../_helpers';

const PersonInfo = () => {
  const { personGid, person, customerDataCompleteness } = useGuidedSellingExperienceContext();
  const { data: doNotContact, isSuccess } = usePersonDNC(personGid);

  const { data: personRelatedPeople } = usePersonRelatedPeople(personGid);
  const canCallWithDNC = authInfo.features.do_not_contact_call_available;

  if (!personGid || !person) {
    return null;
  }

  return (
    <>
      <FlexBox gap={spacings.px8} alignItemsCenter>
        <Text type="large" bold className="fs-mask">
          {person.name}
        </Text>
        {isCustomerDataCompletenessHigh(customerDataCompleteness) && (
          <Tag label="Online lead" backgroundColor={colors.azure50} textColor={colors.azure50} transparent />
        )}
      </FlexBox>
      <FlexBox columnDirection ml={spacings['-px4']}>
        {person.phone && isSuccess && (
          <DescriptionList
            term={<Text>Phone</Text>}
            details={
              doNotContact && !canCallWithDNC ? (
                <Text color={colors.statusRed} data-testid="phone-do-not-contact">
                  Do not contact
                </Text>
              ) : (
                <TelephonyTooltip phone={person.phone} personGid={personGid}>
                  <ClassNames>
                    {({ css }) => (
                      <Copy
                        withIcon={false}
                        className={
                          doNotContact && canCallWithDNC ? `${css(`color: ${colors.statusRed};`)} fs-mask` : 'fs-mask'
                        }
                        value={phoneFormatter(person.phone)}
                      >
                        {phoneFormatter(person.phone)}
                      </Copy>
                    )}
                  </ClassNames>
                </TelephonyTooltip>
              )
            }
          />
        )}
        {person.email && (
          <DescriptionList
            term={<Text>Email</Text>}
            details={
              doNotContact && !canCallWithDNC ? (
                <Text color={colors.statusRed} data-testid="email-do-not-contact">
                  Do not contact
                </Text>
              ) : (
                <FlexBox>
                  <TelephonyActions email={person.email} personGid={personGid}>
                    <Copy withIcon={false} value={person.email!}>
                      <Text color={doNotContact && canCallWithDNC ? colors.statusRed : undefined} className="fs-mask">
                        {person.email}
                      </Text>
                    </Copy>
                  </TelephonyActions>
                </FlexBox>
              )
            }
          />
        )}
        {person?.mailing_address && (
          <DescriptionList
            term={<Text>Address</Text>}
            details={
              <Container ml={spacings['-px4']}>
                <CopyableAddress address={person.mailing_address} />
              </Container>
            }
          />
        )}
        {personRelatedPeople?.map(relatedPerson => (
          <div key={relatedPerson.gid}>
            <DescriptionList
              term={<Text>{Translations.relationKind(relatedPerson.kind)}</Text>}
              details={<Text className="fs-mask">{relatedPerson.name}</Text>}
            />
          </div>
        ))}
      </FlexBox>
    </>
  );
};

export default PersonInfo;
