import { Translations } from '../constants';
import { assetIsNotRented, AssetType, HomeUsageType, IPersonAsset } from './IPersonAsset';

export interface IHome extends IPersonAsset {
  asset_name: AssetType.Home;
  usage: HomeUsageType | null;
  property_type: string | null;
  stories: string | null;
  year_built: number | null;
  roof_year: number | null;
  square_feet: number | null;
  bathrooms_full: number | null;
  bathrooms_half: number | null;
  bathrooms_three_quarter: number | null;
  total_rooms: number | null;
  units: number | null;
  heating: string | null;
  foundation: string | null;
  air_conditioning: string | null;
  sewer: string | null;
  water: string | null;
  quality: string | null;
  condition: string | null;
  architectural_style: string | null;
  construction: string | null;
  exterior_walls: string | null;
  roof_material: string | null;
  roof_design: string | null;
  bathroom_grade: string | null;
  kitchen_grade: string | null;
  swimming_pool: string | null;
  garages: IGarage[] | null;
  estimated_price: number | null;
  replacement_cost: number | null;
  flood_zone: string | null;
  flood_risk: FloodRisk | null;
  bedrooms: number | null;
  purchase_price: number | null;
  security_system: boolean | null;
  fire_alarm: boolean | null;
  sprinkler_system: string | null;
  protective_devices: boolean | null;
}

export enum FloodRisk {
  Dangerous = 'dangerous',
  Moderate = 'moderate',
  Low = 'low',
  Unknown = 'unknown'
}

export interface IGarage {
  kind: string | null;
  car_count: number | null;
  square_feet: number | null;
}

export const isExtendedHomeInfoAvailable = ({ ownership }: { ownership: IHome['ownership'] | undefined }) => {
  if (!ownership) {
    return true;
  }

  return assetIsNotRented({ ownership });
};

export const homeUsageTitle = ({ usage }: { usage: IHome['usage'] }) => Translations.homeUsage(usage) || 'Home';
