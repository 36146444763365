import HorizontalTabs from '../../../components/core/Tabs/HorizontalTabs';
import { IPerson } from '../../../interfaces';
import Activities from './Activities';
import Quotes from './Quotes';

const HorizontalPanels = ({ person }: { person: IPerson }) => {
  const tabContent = [
    {
      tabName: 'Quotes',
      content: <Quotes person={person} />
    },
    {
      tabName: 'Activities',
      content: <Activities person={person} />
    }
  ];

  // TODO: border and content appearance with horizontal scroll
  return <HorizontalTabs stickyHeader tabs={tabContent} />;
};

export default HorizontalPanels;
