import { skipToken, useQuery } from '@tanstack/react-query';

import api from '../../services/api';

const getUserAvatarUrl = (userId: number): Promise<{ avatar_url: string | null }> =>
  api.get(`/api/frontend/users/${userId}/avatar_url`);

const useUserAvatarUrl = (userId?: number) =>
  useQuery({
    queryKey: ['user_avatar_url', userId],
    queryFn: userId ? () => getUserAvatarUrl(userId) : skipToken,
    select: data => data.avatar_url,
    staleTime: Infinity
  });

export default useUserAvatarUrl;
