/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import Copy from '../../../../components/core/Copy';
import FlexBox from '../../../../components/core/FlexBox';
import Text from '../../../../components/core/Text';
import { IPerson } from '../../../../interfaces';
import { spacings } from '../../../../theme/variables';

interface PersonNamePros {
  person: IPerson;
}

const PersonName = ({ person: { first_name, middle_name, last_name } }: PersonNamePros) => {
  return (
    <FlexBox
      gap={spacings.px4}
      customCss={css`
        flex-wrap: wrap;
      `}
    >
      <Copy
        type="small"
        value={first_name}
        withIcon={false}
        customCss={css`
          padding: 0;
        `}
      >
        <Text type="small" bold className="fs-mask" singleLine>
          {first_name}
        </Text>
      </Copy>
      {middle_name && (
        <Copy
          type="small"
          value={middle_name}
          withIcon={false}
          customCss={css`
            padding: 0;
          `}
        >
          <Text type="small" bold className="fs-mask" singleLine>
            {middle_name}
          </Text>
        </Copy>
      )}
      <Copy
        type="small"
        value={last_name}
        withIcon={false}
        customCss={css`
          padding: 0;
        `}
      >
        <Text type="small" bold className="fs-mask" singleLine>
          {last_name}
        </Text>
      </Copy>
    </FlexBox>
  );
};

export default PersonName;
