import { Search } from 'history';
import queryString from 'query-string';

import { PersonMatch } from '../../queries/people/usePeopleByTerm';

export enum GuidedSellingRoutes {
  DataCollection = 'data_collection',
  ISRFinish = 'isr_finish',
  Quotes = 'quotes',
  Finalize = 'finalize',
  CollectedInfo = 'collected_info',
  AgentFinish = 'agent_finish',
  HandOff = 'hand_off',
  Proposal = 'proposal',
  ThankYou = 'thank_you'
}

export enum GuidedSellingPathnames {
  ScoutingReport = '/scouting_report',
  DataCollection = '/scouting_report/data_collection',
  ISRFinish = '/scouting_report/isr_finish',
  Quotes = '/scouting_report/quotes',
  Finalize = '/scouting_report/finalize',
  CollectedInfo = '/scouting_report/collected_info',
  AgentFinish = '/scouting_report/agent_finish',
  HandOff = '/scouting_report/hand_off',
  Proposal = '/scouting_report/proposal',
  ThankYou = '/scouting_report/thank_you'
}

export enum GuidedSellingExperienceOpenedFrom {
  PeopleSearch = 'people_search',
  HeaderSearch = 'header_search',
  OldUI = 'old_ui',
  LeadQuotes = 'lead_quotes'
}

export interface LocationState {
  openedFrom?: GuidedSellingExperienceOpenedFrom;
}

export const readQueryParams = (searchParams: URLSearchParams) => {
  return {
    call_log_id: searchParams.get('call_log_id'),
    person_gid: searchParams.get('person_gid'),
    lead_gid: searchParams.get('lead_gid'),
    candidate_gid: searchParams.get('candidate_gid')
  };
};

const queryForPersonSearchResult = ({ person, last_lead, last_candidate }: PersonMatch) => {
  if (last_lead?.gid) {
    return { lead_gid: last_lead.gid };
  } else if (last_candidate?.gid) {
    return { candidate_gid: last_candidate.gid };
  }

  return { person_gid: person.gid };
};

const guidedSellingExperienceNavigation = {
  forCall: ({
    call_log_id,
    path = GuidedSellingPathnames.ScoutingReport
  }: {
    call_log_id: string | number;
    path?: GuidedSellingPathnames;
  }) => {
    const query = { call_log_id };

    return queryString.stringifyUrl({ url: path, query });
  },

  forCallWithCurrentSearchParams: ({
    search,
    call_log_id,
    path = GuidedSellingPathnames.ScoutingReport
  }: {
    search: Search;
    call_log_id: string | number;
    path?: GuidedSellingPathnames;
  }) => {
    queryString.parse(search);

    const newQuery = { ...queryString.parse(search), call_log_id };
    return queryString.stringifyUrl({ url: path, query: newQuery });
  },

  forLead: ({
    lead_gid,
    path = GuidedSellingPathnames.ScoutingReport
  }: {
    lead_gid: string;
    path?: GuidedSellingPathnames;
  }) => {
    const query = { lead_gid };

    return queryString.stringifyUrl({ url: path, query });
  },

  forLeadAndCall: ({
    lead_gid,
    call_log_id,
    path = GuidedSellingPathnames.ScoutingReport
  }: {
    lead_gid: string;
    call_log_id: string | number;
    path?: GuidedSellingPathnames;
  }) => {
    const query = { lead_gid, call_log_id };

    return queryString.stringifyUrl({ url: path, query });
  },

  forCandidate: ({
    candidate_gid,
    path = GuidedSellingPathnames.ScoutingReport
  }: {
    candidate_gid: string;
    path?: GuidedSellingPathnames;
  }) => {
    const query = { candidate_gid };

    return queryString.stringifyUrl({ url: path, query });
  },

  forPerson: ({
    person_gid,
    path = GuidedSellingPathnames.ScoutingReport
  }: {
    person_gid: string;
    path?: GuidedSellingPathnames;
  }) => {
    const query = { person_gid };

    return queryString.stringifyUrl({ url: path, query });
  },

  forSearchResult: ({
    callLodId,
    personMatch,
    path = GuidedSellingPathnames.ScoutingReport
  }: {
    callLodId?: number | null;
    personMatch: PersonMatch;
    path?: GuidedSellingPathnames;
  }) => {
    const query = { call_log_id: callLodId, ...queryForPersonSearchResult(personMatch) };

    return queryString.stringifyUrl({ url: path, query });
  }
};

export default guidedSellingExperienceNavigation;
