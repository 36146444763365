/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';
import { Route, Routes, useParams } from 'react-router-dom';

import FlexBox from '../../components/core/FlexBox';
import { useDocumentTitle } from '../../hooks';
import useNavigateBack from '../../hooks/useNavigateBack';
import usePerson from '../../queries/people/usePerson';
import { spacings } from '../../theme/variables';
import ScheduleCall from '../ScheduleCall';
import Header from './Header';
import HorizontalPanels from './HorizontalPanels';
import Summary from './Summary';
import Tabs from './Tabs';

const CustomerView = () => {
  const { gid: personGid } = useParams();
  const { data: person } = usePerson(personGid);
  const navigateBack = useNavigateBack();

  useDocumentTitle(person?.name);

  if (!personGid || !person) {
    return null;
  }

  return (
    <div
      css={css`
        display: grid;
        grid-template-rows: max-content 1fr;
        grid-template-columns: 1fr auto;
        grid-template-areas:
          'heading tabs'
          'customer tabs';
        height: 100%;
      `}
    >
      <div
        css={css`
          grid-area: heading;
        `}
      >
        <Header person={person} />
      </div>

      <div
        css={css`
          grid-area: customer;
          overflow: auto;
          padding: ${spacings.px24}px;
          gap: ${spacings.px24}px;
          display: grid;
          grid-template-columns: auto 1fr;
        `}
      >
        <div>
          <Summary person={person} />
        </div>
        <div
          css={css`
            overflow: auto;
          `}
        >
          <HorizontalPanels person={person} />
        </div>
      </div>

      <FlexBox
        css={css`
          overflow: hidden;
          grid-area: tabs;
        `}
      >
        <Tabs person={person} />
      </FlexBox>
      <Routes>
        <Route
          path="schedule_call"
          element={
            <ScheduleCall
              personGid={person.gid}
              leadGid={undefined}
              candidateGid={undefined}
              cancelHandler={navigateBack}
              afterSubmitHandler={navigateBack}
            />
          }
        />
      </Routes>
    </div>
  );
};

export default CustomerView;
