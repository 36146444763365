/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { components } from 'react-select';

import Container from '../../../components/core/Container';
import { NumericField } from '../../../components/core/forms/fields';
import { ArrowUpIcon, DollarIcon, PercentageIcon } from '../../../components/core/icons';
import Select from '../../../components/core/inputs/Select';
import { LimitType, PolicyCoverageKey } from '../../../interfaces/IPolicyCoverage';
import { PolicyType } from '../../../interfaces/IPolicyType';
import colors from '../../../theme/colors';
import { buildInitialValues, COVERAGE_LABELS } from '../_helpers';

export const SmallDropdownIndicator = (props: any) => (
  <components.DropdownIndicator {...props}>
    <ArrowUpIcon
      className="select-dropdown-indicator"
      color={colors.black}
      css={css`
        width: 12px;
        height: 12px;
        transform: rotate(${props.selectProps.menuIsOpen ? 0 : 180}deg);
        transition:
          transform 0.3s ease,
          visibility 0.2s ease,
          opacity 0.2s ease;
      `}
    />
  </components.DropdownIndicator>
);

const DeductibleInput = ({ values }: { values: { deductible: string | number } }) => (
  <NumericField
    name="deductible"
    id="deductible"
    label="Deductible"
    inline
    valueIsNumber
    prefixIcon={values.deductible && Number(values.deductible) < 100 ? <PercentageIcon /> : <DollarIcon />}
    decimalScale={2}
    required
  />
);

const DwellingInput = () => (
  <NumericField
    name={PolicyCoverageKey.Dwelling}
    id={PolicyCoverageKey.Dwelling}
    label={COVERAGE_LABELS[PolicyCoverageKey.Dwelling]}
    inline
    valueIsNumber
    prefixIcon={<DollarIcon />}
    decimalScale={2}
    required
  />
);

const OtherStructuresInput = () => (
  <NumericField
    name={PolicyCoverageKey.OtherStructures}
    id={PolicyCoverageKey.OtherStructures}
    label={COVERAGE_LABELS[PolicyCoverageKey.OtherStructures]}
    inline
    valueIsNumber
    prefixIcon={<DollarIcon />}
    decimalScale={2}
    required
  />
);

const PersonalPropertyInput = () => (
  <NumericField
    name={PolicyCoverageKey.PersonalProperty}
    id={PolicyCoverageKey.PersonalProperty}
    label={COVERAGE_LABELS[PolicyCoverageKey.PersonalProperty]}
    inline
    valueIsNumber
    prefixIcon={<DollarIcon />}
    decimalScale={2}
    required
  />
);

const LossOfUseInput = ({
  values,
  setFieldValue
}: {
  values: { lossOfUseLimitType: string };
  setFieldValue: (key: string, value: string) => void;
}) => (
  <NumericField
    name={PolicyCoverageKey.LossOfUse}
    id={PolicyCoverageKey.LossOfUse}
    label={COVERAGE_LABELS[PolicyCoverageKey.LossOfUse]}
    inline
    valueIsNumber
    decimalScale={2}
    required
    customPrefix={
      <Container
        customCss={css`
          width: 62px;
        `}
      >
        <Select
          onChange={e => {
            setFieldValue('lossOfUseLimitType', e);
          }}
          value={values.lossOfUseLimitType}
          isPrefix
          options={[
            { key: LimitType.MoneyLimit, value: '$' },
            { key: LimitType.MonthlyLimit, value: 'mo' }
          ]}
          menuMinWidth="60px"
          customComponents={{
            DropdownIndicator: SmallDropdownIndicator as unknown as React.ReactNode
          }}
          customStyles={{ valueContainer: (base: any) => ({ ...base, padding: '2px' }) }}
        />
      </Container>
    }
  />
);

const PersonalLiabilityInput = ({ policyType }: { policyType: PolicyType }) => (
  <NumericField
    name={PolicyCoverageKey.PersonalLiability}
    id={PolicyCoverageKey.PersonalLiability}
    label={policyType === PolicyType.Fire ? 'Premises liability' : COVERAGE_LABELS[PolicyCoverageKey.PersonalLiability]}
    inline
    valueIsNumber
    prefixIcon={<DollarIcon />}
    decimalScale={2}
    required
  />
);

const MedicalPaymentsInput = () => (
  <NumericField
    name={PolicyCoverageKey.MedicalPayments}
    id={PolicyCoverageKey.MedicalPayments}
    label={COVERAGE_LABELS[PolicyCoverageKey.MedicalPayments]}
    inline
    valueIsNumber
    prefixIcon={<DollarIcon />}
    decimalScale={2}
    required
  />
);

const HouseholdFurnishingsInput = () => (
  <NumericField
    name={PolicyCoverageKey.HouseholdFurnishings}
    id={PolicyCoverageKey.HouseholdFurnishings}
    label={COVERAGE_LABELS[PolicyCoverageKey.HouseholdFurnishings]}
    inline
    valueIsNumber
    prefixIcon={<DollarIcon />}
    decimalScale={2}
    required
  />
);

const FairRentalValueInput = () => (
  <NumericField
    name={PolicyCoverageKey.FairRentalValue}
    id={PolicyCoverageKey.FairRentalValue}
    label={COVERAGE_LABELS[PolicyCoverageKey.FairRentalValue]}
    inline
    valueIsNumber
    prefixIcon={<DollarIcon />}
    decimalScale={2}
    required
  />
);

interface IHomeCoverages {
  values: ReturnType<typeof buildInitialValues>;
  setFieldValue: (key: string, value: string) => void;
}

const DefaultHomeCoverages = ({ values, setFieldValue }: IHomeCoverages) => (
  <>
    <DeductibleInput values={values} />
    <DwellingInput />
    <OtherStructuresInput />
    <PersonalPropertyInput />
    {'lossOfUseLimitType' in values && <LossOfUseInput values={values} setFieldValue={setFieldValue} />}
    <PersonalLiabilityInput policyType={PolicyType.Home} />
    <MedicalPaymentsInput />
  </>
);

const CondoHomeCoverages = ({ values, setFieldValue }: IHomeCoverages) => (
  <>
    <DeductibleInput values={values} />
    <DwellingInput />
    <PersonalPropertyInput />
    {'lossOfUseLimitType' in values && <LossOfUseInput values={values} setFieldValue={setFieldValue} />}
    <PersonalLiabilityInput policyType={PolicyType.Condo} />
    <MedicalPaymentsInput />
  </>
);

const InvestmentHomeCoverages = ({ values }: IHomeCoverages) => (
  <>
    <DeductibleInput values={values} />
    <DwellingInput />
    <OtherStructuresInput />
    <HouseholdFurnishingsInput />
    <FairRentalValueInput />
    <PersonalLiabilityInput policyType={PolicyType.Fire} />
    <MedicalPaymentsInput />
  </>
);

const RentersHomeCoverages = ({ values, setFieldValue }: IHomeCoverages) => (
  <>
    <DeductibleInput values={values} />
    <PersonalPropertyInput />
    {'lossOfUseLimitType' in values && <LossOfUseInput values={values} setFieldValue={setFieldValue} />}
    <PersonalLiabilityInput policyType={PolicyType.Renters} />
    <MedicalPaymentsInput />
  </>
);

export const HomeCoveragesComponent = ({ policyType, ...props }: IHomeCoverages & { policyType: PolicyType }) => {
  switch (policyType) {
    case PolicyType.Condo:
      return <CondoHomeCoverages {...props} />;
    case PolicyType.Fire:
      return <InvestmentHomeCoverages {...props} />;
    case PolicyType.Renters:
      return <RentersHomeCoverages {...props} />;
    case PolicyType.Home:
    default:
      return <DefaultHomeCoverages {...props} />;
  }
};
