/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { components } from 'react-select';

import { EmptyResults } from '../../../../components/common';
import Button, { ButtonSize } from '../../../../components/core/buttons/Button';
import Container from '../../../../components/core/Container';
import FlexBox from '../../../../components/core/FlexBox';
import { WarningIcon } from '../../../../components/core/icons';
import Select from '../../../../components/core/inputs/Select';
import Label from '../../../../components/core/Label';
import Tag from '../../../../components/core/Tag';
import TablePlaceholder from '../../../../components/TablePlaceholder';
import { isMoreThanOneWeekOld } from '../../../../interfaces/IQuote';
import { IQuotesRequest } from '../../../../interfaces/IQuotesRequests';
import { SourceProducts } from '../../../../interfaces/ISourceDimensions';
import useOpportunitiesWithPolicies from '../../../../queries/leads/opportunities/useOpportunitiesWithPolicies';
// eslint-disable-next-line max-len
import useOpportunitiesWithPremiumPredictions from '../../../../queries/leads/opportunities/useOpportunitiesWithPremiumPredictions';
import usePersonAssets from '../../../../queries/people/person_assets/usePersonAssets';
import usePersonOpportunities from '../../../../queries/people/person_opportunities/usePersonOpportunities';
import useQuotesRequests from '../../../../queries/quotes_requests/useQuotesRequests';
import colors from '../../../../theme/colors';
import { largeParagraphCss } from '../../../../theme/typography';
import { borderRadius, spacings } from '../../../../theme/variables';
import { dateFormatter, DEPRECATED_DATE_TIME_FORMAT } from '../../../../utils/formatter';
import useCustomerChoices from './_hooks/useCustomerChoices';
import useQuotingAvailability from './_hooks/useQuotingAvailability';
import useSelectedQuotesRequest from './_hooks/useSelectedQuotesRequest';
import QuotesRequest from './QuotesRequest';

const QuoteRequestSingleValue = ({ children, data: option, ...rest }: any) => {
  return (
    <components.SingleValue {...rest}>
      <FlexBox gap={spacings.px8}>
        Request {dateFormatter(option.createdAt, DEPRECATED_DATE_TIME_FORMAT)}
        {isMoreThanOneWeekOld(option.createdAt) && <WarningIcon color={colors.statusOrange} />}
      </FlexBox>
    </components.SingleValue>
  );
};

const QuoteRequestOption = ({ children, data: option, ...rest }: any) => {
  const isMasked = (rest.selectProps as any)['fsMask'];

  return (
    <components.Option {...rest} className={isMasked ? 'fs-mask' : ''}>
      <FlexBox
        justifySpaceBetween
        alignItemsCenter
        customCss={css`
          flex: 1;
        `}
      >
        Request {dateFormatter(option.createdAt, DEPRECATED_DATE_TIME_FORMAT)}
        {isMoreThanOneWeekOld(option.createdAt) && (
          <Tag
            prefix={<WarningIcon color={colors.statusOrange} />}
            textType="small"
            textColor={colors.black}
            backgroundColor={colors.warningBackground}
            borderRadius={borderRadius}
            label="Expired"
            customCss={css`
              border: 1px solid ${colors.statusOrange};
            `}
          />
        )}
      </FlexBox>
    </components.Option>
  );
};

const QuotesRequestsHeading = ({
  onGetQuotes,
  quotesRequests
}: {
  onGetQuotes: undefined | (() => void);
  quotesRequests: IQuotesRequest[];
}) => {
  const { quotesRequestGid, setQuotesRequestGid } = useSelectedQuotesRequest();

  React.useEffect(() => {
    if (quotesRequests[0]) {
      setQuotesRequestGid(quotesRequests[0].gid);
    }
  }, [setQuotesRequestGid, quotesRequests]);

  const quotesRequestsOptions =
    quotesRequests.map(quotesRequest => ({
      key: quotesRequest.gid,
      value: `Request ${dateFormatter(quotesRequest.created_at, DEPRECATED_DATE_TIME_FORMAT)}`,
      createdAt: quotesRequest.created_at,
      description: quotesRequest.source_product !== SourceProducts.AgentManual ? 'Customer requested' : undefined
    })) || [];

  const selectedQuotesRequest = quotesRequests.find(({ gid }) => gid === quotesRequestGid);

  const { forbidQuoting, Tip, labelProps } = useQuotingAvailability();

  return (
    <FlexBox alignItemsCenter justifySpaceBetween gap={spacings.px8}>
      <FlexBox alignItemsCenter gap={spacings.px24}>
        <Label
          htmlFor="quotes-requests"
          customCss={css`
            ${largeParagraphCss};
            font-weight: bold;
          `}
        >
          Quotes
        </Label>
        <Select
          inputId="quotes-requests"
          customCss={css`
            width: 290px;
            font-weight: bold;
          `}
          menuMinWidth="350px"
          options={quotesRequestsOptions}
          customComponents={{
            Option: QuoteRequestOption as unknown as React.ReactNode,
            SingleValue: QuoteRequestSingleValue as unknown as React.ReactNode
          }}
          onChange={quotesRequestGid => setQuotesRequestGid(quotesRequestGid)}
          value={quotesRequestGid}
        />
        {selectedQuotesRequest?.source_product !== SourceProducts.AgentManual && (
          <Tag label="Customer requested" transparent textColor={colors.azure50} backgroundColor={colors.azure50} />
        )}
      </FlexBox>
      {onGetQuotes && (
        <Container {...labelProps}>
          <Button size={ButtonSize.Small} onClick={() => onGetQuotes()} disabled={forbidQuoting}>
            Get quotes
          </Button>
          <Tip />
        </Container>
      )}
    </FlexBox>
  );
};

const QuotesRequests = ({
  leadId,
  leadGid,
  personGid,
  onGetQuotes
}: {
  leadId: number | undefined;
  leadGid: string | undefined;
  personGid: string;
  onGetQuotes: undefined | (() => void);
}) => {
  const { data: assets, isPending: isPendingAssets } = usePersonAssets({ personGid });

  const { data: opportunities } = usePersonOpportunities({
    personGid,
    leadGid
  });
  const { opportunitiesWithPolicies, refetch: refetchCurrentPolicies } = useOpportunitiesWithPolicies({
    personGid,
    opportunities
  });
  const { opportunitiesWithPremiumPredictions } = useOpportunitiesWithPremiumPredictions({ opportunitiesWithPolicies });

  const { quotesRequestGid } = useSelectedQuotesRequest();

  const { data: quotesRequests, refetch: refetchQuotesRequests } = useQuotesRequests({
    personGid,
    leadGid,
    options: { refetchOnWindowFocus: true }
  });

  const { customerChoices } = useCustomerChoices({ personGid, leadGid, quotesRequests });

  const { forbidQuoting, Tip, labelProps } = useQuotingAvailability();

  if (!quotesRequests || isPendingAssets) {
    return <TablePlaceholder testId="quote-requests-loader" />;
  }

  const selectedQuotesRequest = quotesRequests.find(({ gid }) => gid === quotesRequestGid);

  return (
    <FlexBox fitParentWidth columnDirection gap={spacings.px24}>
      {quotesRequests.length ? (
        <QuotesRequestsHeading onGetQuotes={onGetQuotes} quotesRequests={quotesRequests} />
      ) : (
        onGetQuotes && (
          <FlexBox justifyRight>
            <Container {...labelProps}>
              <Button size={ButtonSize.Small} onClick={() => onGetQuotes()} disabled={forbidQuoting}>
                Get quotes
              </Button>
              <Tip />
            </Container>
          </FlexBox>
        )
      )}
      {!!quotesRequests.length && !selectedQuotesRequest?.is_archived && (
        <QuotesRequest
          key={quotesRequestGid}
          leadId={leadId}
          leadGid={leadGid}
          personGid={personGid}
          selectedQuotesRequest={selectedQuotesRequest}
          customerChoices={customerChoices}
          assets={assets || []}
          opportunitiesWithPremiumPredictions={opportunitiesWithPremiumPredictions}
          refetchCurrentPolicies={refetchCurrentPolicies}
          opportunities={opportunities}
          refetchQuotesRequests={refetchQuotesRequests}
          applyAppointedOnlyFilter={quotesRequestGid === quotesRequests[0]?.gid}
        />
      )}
      {!!quotesRequests.length && selectedQuotesRequest?.is_archived && <EmptyResults message="Quotes are archived" />}
      {!quotesRequests.length && <EmptyResults message="No quotes found" />}
    </FlexBox>
  );
};

export default QuotesRequests;
