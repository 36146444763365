/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import { PDFContextProvider } from '../../../../components/common/PDFViewer';
import alert from '../../../../components/core/Alert';
import FlexBox from '../../../../components/core/FlexBox';
import Paragraph from '../../../../components/core/Paragraph';
import ConfirmModal from '../../../../components/FileModals/ConfirmModal';
import { useGuidedSellingExperienceContext } from '../../../../contexts/GuidedSellingExperienceContext';
import { DocumentOwnerType, FileToConfirm, FileToUpload, IDocument } from '../../../../interfaces/IDocument';
import useDocumentTypes from '../../../../queries/document_types/useDocumentTypes';
import useLeadOpportunities from '../../../../queries/leads/opportunities/useLeadOpportunities';
import usePersonDocuments, { useCreateDocument } from '../../../../queries/people/person_documents/usePersonDocuments';
import usePersonMaticPolicies from '../../../../queries/people/person_policies/usePersonMaticPolicies';
import colors from '../../../../theme/colors';
import { spacings } from '../../../../theme/variables';
import { dateFormatter } from '../../../../utils/formatter';
import { IChannel } from '../../../PostSalesExperience/FinalLook/Document';
import VerticalTabContent from '../../_components/VerticalTabs/VerticalTabContent';
import VerticalTabHeader from '../../_components/VerticalTabs/VerticalTabHeader';
import { useDataEditingForbidden } from '../../_hooks';
import DocumentDetails from './DocumentDetails';
import Dropzone from './Dropzone';
import { getOwnerType } from './helpers';
import OtherDocuments from './OtherDocuments';
import PolicyDocuments from './PolicyDocuments';

const DocsTab = () => {
  const { lead, personGid } = useGuidedSellingExperienceContext();

  const dataEditingForbidden = useDataEditingForbidden();

  const { data: documents } = usePersonDocuments({ personGid });
  const { data: leadOpportunities } = useLeadOpportunities({ leadId: lead?.id });
  const { transformedData: documentTypes = [] } = useDocumentTypes();
  const { data: policies } = usePersonMaticPolicies({ personGid });
  const { mutateAsync: createDocument } = useCreateDocument({ attachDocs: true });

  const { data: docTypes } = useDocumentTypes();

  const [filesToConfirm, setFilesToConfirm] = React.useState<FileToConfirm[]>([]);
  const [, setFilesToUpload] = React.useState<FileToUpload[]>([]);
  const [expandedDocument, setExpandedDocument] = React.useState<IDocument | null>(null);
  const [expandedDocumentChannels, setExpandedDocumentChannels] = React.useState<IChannel[] | null>([]);

  const onAttach = (files: FileToUpload[], leadId: number) =>
    Promise.all(
      files.map(({ fileOwner, ...rest }) => {
        const owner_id = fileOwner === 'lead' ? leadId : Number(fileOwner);
        const owner_type = getOwnerType({ fileOwner, opportunities: leadOpportunities, policies });

        return createDocument({
          personGid: personGid!,
          request: { ...rest, owner_id, owner_type }
        });
      })
    );
  const closeModal = () => setFilesToConfirm([]);
  const suggestDocType = (name: string): number =>
    (documentTypes.find(type => !!(name.match(type.regexp) || [])[0])?.id || '') as number;

  const policiesOwners =
    policies?.map(policy => {
      return {
        key: policy.id.toString(),
        value: `Policy #${policy.policy_number}`,
        description: `Effective date ${dateFormatter(policy.effective_date)}-${dateFormatter(policy.expiration_date)}`
      };
    }) || [];
  const owners = lead
    ? policiesOwners.concat({ key: lead.id.toString(), value: 'Other', description: '' })
    : policiesOwners;

  const otherDocuments = documents?.filter(doc => doc.owner_type !== DocumentOwnerType.Policy);

  return (
    <PDFContextProvider>
      {expandedDocument ? (
        <DocumentDetails
          document={expandedDocument}
          setExpandedDocument={setExpandedDocument}
          documentTypes={documentTypes}
          expandedDocumentChannels={expandedDocumentChannels}
        />
      ) : (
        <>
          <VerticalTabHeader>
            <Paragraph type="large">Documents</Paragraph>
          </VerticalTabHeader>
          <VerticalTabContent>
            {lead && personGid ? (
              <FlexBox columnDirection gap={spacings.px20}>
                <Dropzone
                  disabled={dataEditingForbidden}
                  small
                  onUpload={filesToConfirm => setFilesToConfirm(filesToConfirm)}
                  onReject={invalidFiles =>
                    invalidFiles.length > 0 &&
                    alert({
                      title: 'Error uploading files',
                      items: invalidFiles.map(({ file, error }) => `${file.name} - ${error}`)
                    }).error()
                  }
                />
                {docTypes?.document_types && (
                  <FlexBox gap={spacings.px20} columnDirection>
                    {policies?.map(policy => (
                      <PolicyDocuments
                        personGid={personGid}
                        policy={policy}
                        key={policy.id}
                        docTypes={docTypes.document_types}
                        setExpandedDocument={setExpandedDocument}
                        setExpandedDocumentChannels={setExpandedDocumentChannels}
                      />
                    ))}
                    {otherDocuments && otherDocuments.length > 0 ? (
                      <OtherDocuments
                        documents={otherDocuments}
                        docTypes={docTypes.document_types}
                        setExpandedDocument={setExpandedDocument}
                        setExpandedDocumentChannels={setExpandedDocumentChannels}
                      />
                    ) : null}
                  </FlexBox>
                )}

                {filesToConfirm.length > 0 && (
                  <ConfirmModal
                    files={filesToConfirm.map(f => ({
                      ...f,
                      document_type_id: suggestDocType(f.name),
                      fileOwner: ''
                    }))}
                    cancelHandler={() => closeModal()}
                    confirmHandler={async filesToUpload => {
                      setFilesToUpload(filesToUpload);

                      closeModal();
                      await onAttach(filesToUpload, lead.id);

                      setFilesToUpload([]);
                    }}
                    owners={owners}
                  />
                )}
              </FlexBox>
            ) : (
              <Paragraph
                mt={spacings.px24}
                color={colors.grey60}
                customCss={css`
                  text-align: center;
                `}
              >
                No files here
              </Paragraph>
            )}
          </VerticalTabContent>
        </>
      )}
    </PDFContextProvider>
  );
};

export default DocsTab;
