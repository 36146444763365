import AddHomeFormModal from '../../../components/AddHomeForm';
import AddButton from '../../../components/core/buttons/AddButton';
import { API_TO_SMARTY_STREETS_MAPPING } from '../../../constants/addressForm';
import { useGuidedSellingExperienceContext } from '../../../contexts/GuidedSellingExperienceContext';
import { useToggle } from '../../../hooks';
import { IAddressSuggestion, IPayloadAddress } from '../../../interfaces';
import { useAttachHomeToDataCollection } from '../../../queries/leads/data_collection/useDataCollectionHomes';
import usePersonAddresses from '../../../queries/people/usePersonAddresses';
import { createHome } from '../../../queries/people/usePersonHomes';
import analytics from '../../../services/analytics';
import { renameKeys } from '../../../utils/object';

const CreateHome = () => {
  const { lead, personGid } = useGuidedSellingExperienceContext();
  const lead_gid = lead?.gid;
  const [addHome, toggleAddHome] = useToggle(false);
  const { data: personAddresses, refetch: refetchPersonAddresses } = usePersonAddresses(personGid);
  const { mutateAsync: attachHomeToDataCollection } = useAttachHomeToDataCollection();

  return (
    <>
      <AddButton onClick={toggleAddHome} content="Add home" />
      {addHome && (
        <AddHomeFormModal
          cancelHandler={toggleAddHome}
          defaultAddressOptions={
            (personAddresses?.map(address => renameKeys(API_TO_SMARTY_STREETS_MAPPING, address)) ||
              []) as IAddressSuggestion[]
          }
          confirmHandler={async values => {
            const data = await createHome({
              personGid: personGid!,
              data: {
                address: values.property_address as unknown as IPayloadAddress,
                usage: values.usage
              }
            });
            analytics.track('Home added', {
              lead_gid,
              person_gid: personGid,
              place: 'post_sales_experience',
              home_gid: data.home.gid
            });
            refetchPersonAddresses();
            await attachHomeToDataCollection({
              assetGid: data.home.gid,
              leadId: lead!.id
            });
            toggleAddHome();
          }}
        />
      )}
    </>
  );
};

export default CreateHome;
