/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import moment from 'moment';

import FlexBox from '../../../../components/core/FlexBox';
import Paragraph, { ParagraphType } from '../../../../components/core/Paragraph';
import Tag from '../../../../components/core/Tag';
import Text from '../../../../components/core/Text';
import { useGuidedSellingExperienceContext } from '../../../../contexts/GuidedSellingExperienceContext';
import { IFullStorySession } from '../../../../interfaces';
import useFullStorySessions from '../../../../queries/full_story_sessions/useFullStorySessions';
import colors from '../../../../theme/colors';
import { spacings } from '../../../../theme/variables';
import VerticalTabContent from '../../_components/VerticalTabs/VerticalTabContent';
import VerticalTabHeader from '../../_components/VerticalTabs/VerticalTabHeader';
import TabSkeleton from '../_components/TabSkeleton';
import { displayedDateAndTime } from '../CommunicationHistoryTab/helpers';

const customerExperienceLabel = (experience: IFullStorySession['experience']) => {
  switch (experience) {
    case 'direct_sales':
      return 'Direct Sales';
    case 'olb':
      return 'Online Bind';
    case 'digital_profile':
      return 'Digital Profile';
    case 'proposal':
      return 'Proposal';
    default:
      return 'Unknown';
  }
};

const InsightsTab = () => {
  const { leadGid, personGid, person } = useGuidedSellingExperienceContext();
  const { data: fullStorySessions = [], isPending } = useFullStorySessions({ engagementGid: leadGid, personGid });

  return (
    <>
      <VerticalTabHeader>
        <Paragraph type="large">Insights</Paragraph>
      </VerticalTabHeader>
      <VerticalTabContent>
        {isPending && <TabSkeleton />}

        {!isPending && !!fullStorySessions.length && (
          <>
            <Paragraph bold type={ParagraphType.Large}>
              FullStory recordings
            </Paragraph>
            {fullStorySessions.map(({ id, user, experience, tracked_with_role, created_at, session_url }) => (
              <FlexBox gap={spacings.px4} key={id} justifySpaceBetween alignItemsCenter mt={spacings.px12}>
                <FlexBox alignItemsCenter gap={spacings.px4}>
                  <Text className={user ? '' : 'fs-mask'}>{user?.name || person?.name}</Text>
                  {experience === 'amp' && tracked_with_role ? (
                    <Tag
                      label={tracked_with_role}
                      transparent
                      backgroundColor={colors.grey80}
                      textColor={colors.grey80}
                    />
                  ) : (
                    <>
                      <Tag
                        label="Customer"
                        transparent
                        backgroundColor={colors.statusGreen}
                        textColor={colors.grey80}
                      />
                      <Tag
                        label={customerExperienceLabel(experience)}
                        transparent
                        backgroundColor={colors.statusGreen}
                        textColor={colors.grey80}
                      />
                    </>
                  )}
                </FlexBox>
                <Paragraph color={colors.grey60} type="small">
                  <a href={session_url} target="_blank" rel="noopener noreferrer">
                    {displayedDateAndTime(moment(created_at), true)}
                  </a>
                </Paragraph>
              </FlexBox>
            ))}
          </>
        )}

        {!isPending && !fullStorySessions.length && (
          <Paragraph
            mt={spacings.px24}
            color={colors.grey60}
            customCss={css`
              text-align: center;
            `}
          >
            No FullStory recordings available here yet...
          </Paragraph>
        )}
      </VerticalTabContent>
    </>
  );
};

export default InsightsTab;
