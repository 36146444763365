/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import { ColumnGenerator, Table } from '../../../components/common';
import HomeLinks from '../../../components/common/HomeLinks';
import Copy from '../../../components/core/Copy';
import { DeleteIcon, EditIcon } from '../../../components/core/icons';
import Text from '../../../components/core/Text';
import { Translations } from '../../../constants';
import { IHome } from '../../../interfaces/IHome';
import authInfo from '../../../services/authInfo';

interface HomesListProps {
  personGid: string;
  homes: IHome[];
  defaultSorting: { sort_column: string; sort_order: string };
  onEditHome: (home: IHome) => void;
  onDeleteHome: (home: IHome) => void;
  testId?: string;
}

const renderGidCell = ({ original: { gid } }: { original: IHome }) => (
  <Copy value={gid}>
    <Text
      data-testid="asset_gid"
      customCss={css`
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      `}
    >
      {gid}
    </Text>
  </Copy>
);

const renderPropertyType = ({ original: { property_type } }: { original: IHome }) =>
  property_type && <span data-testid="property-type">{Translations.propertyType(property_type)}</span>;

const renderHomeUsage = ({ original: { usage } }: { original: IHome }) =>
  usage && <span data-testid="usage">{Translations.homeUsage(usage)}</span>;

const renderAddress = ({
  original: {
    address: { line1, line2, city, state, zip }
  }
}: {
  original: IHome;
}) => (
  <div>
    Address 1:
    <Copy value={line1} testId="line_1">
      <Text>{line1}</Text>
    </Copy>
    <br />
    {line2 && (
      <>
        Address 2:
        <Copy value={line2} testId="line_2">
          <Text>{line2}</Text>
        </Copy>
        <br />
      </>
    )}
    City:
    <Copy value={city} testId="city">
      <Text>{city}</Text>
    </Copy>
    <br />
    State:
    <Copy value={state} testId="state">
      <Text>{state}</Text>
    </Copy>
    <br />
    Zip:
    <Copy value={zip} testId="zip">
      <Text>{zip}</Text>
    </Copy>
  </div>
);

const renderYearBuilt = ({ original: { year_built } }: { original: IHome }) =>
  !!year_built && (
    <div>
      <Copy value={year_built.toString()} testId="year_build">
        <Text>{year_built}</Text>
      </Copy>
    </div>
  );

const renderSquareFeet = ({ original: { square_feet } }: { original: IHome }) =>
  !!square_feet && (
    <div>
      <Copy value={square_feet.toString()} testId="square_feet">
        <Text>{square_feet}</Text>
      </Copy>
    </div>
  );

const HomesList = ({ homes, defaultSorting, onEditHome, onDeleteHome, testId, personGid }: HomesListProps) => {
  const renderHomeLinksCell = ({ original: { gid } }: { original: IHome }) => (
    <HomeLinks homeGid={gid} personGid={personGid} />
  );

  /* testid is used in AMP Form Filler; Table columns order is important, double-check form filler before changes */
  const renderEdit = ({ original }: { original: IHome }) => (
    <div onClick={() => onEditHome(original)} className="u-cursor-pointer" data-testid="home_edit_button">
      <EditIcon />
    </div>
  );

  const renderDelete = ({ original }: { original: IHome }) => (
    <div onClick={() => onDeleteHome(original)} className="u-cursor-pointer" data-testid="home_delete_button">
      <DeleteIcon />
    </div>
  );

  const homeColumns = [
    ColumnGenerator.simple({
      width: ColumnGenerator.WIDTH_L,
      sortable: false,
      Cell: renderHomeLinksCell
    }),
    ColumnGenerator.simple({
      width: ColumnGenerator.WIDTH_L,
      Header: 'ID',
      accessor: 'gid',
      sortable: false,
      Cell: renderGidCell
    }),
    ColumnGenerator.simple({
      width: ColumnGenerator.WIDTH_XL,
      accessor: 'property_type',
      Header: 'Type',
      Cell: renderPropertyType
    }),
    ColumnGenerator.stretchable({
      Header: 'address',
      className: 'fs-mask',
      accessor: 'address',
      sortable: false,
      Cell: renderAddress
    }),
    ColumnGenerator.simple({
      width: ColumnGenerator.WIDTH_XL,
      Header: 'Residency',
      accessor: 'usage',
      Cell: renderHomeUsage
    }),
    ColumnGenerator.simple({
      width: ColumnGenerator.WIDTH_L,
      Header: 'Year built',
      accessor: 'year_built',
      Cell: renderYearBuilt
    }),
    ColumnGenerator.simple({
      width: ColumnGenerator.WIDTH_XL,
      Header: 'Square feet',
      accessor: 'square_feet',
      Cell: renderSquareFeet
    }),
    ColumnGenerator.simple({
      width: ColumnGenerator.WIDTH_XS,
      sortable: false,
      Cell: renderEdit
    }),
    authInfo.features.asset_delete &&
      ColumnGenerator.simple({
        width: ColumnGenerator.WIDTH_XS,
        sortable: false,
        Cell: renderDelete
      })
  ].filter(Boolean);

  return (
    <Table
      defaultSorting={defaultSorting}
      columns={homeColumns}
      data={homes}
      manual={false}
      noDataMessage="No homes found"
      testId={testId}
    />
  );
};

export default HomesList;
