import * as yup from 'yup';

import { IPolicy, IPriorPolicy } from '../../interfaces';
import { coverageByKey, DeductibleType, LimitType, PolicyCoverageKey } from '../../interfaces/IPolicyCoverage';
import { isInsurableInterestRealProperty, PolicyType } from '../../interfaces/IPolicyType';
import {
  useCreatePriorPolicy,
  useUpdatePriorPolicy
} from '../../queries/people/person_policies/usePersonPriorPolicies';
import { useCreateVehicle } from '../../queries/people/personVehicles';
import { useCreatePersonHome } from '../../queries/people/usePersonHomes';
import { useCreateRelatedPerson } from '../../queries/people/usePersonRelatedPeople';
import analytics from '../../services/analytics';
import { optionalDate } from '../../utils/yupRules';
import { initialValues as homeInitialValues } from '../AddHomeForm';
import { initialValues as vehiclesInitialValues } from '../AddVehiclesForm/utils';

export const buildInitialValues = ({
  priorPolicy,
  policyType
}: {
  priorPolicy?: IPolicy;
  policyType?: PolicyType;
}) => ({
  carrier_gid: priorPolicy?.carrier?.gid || '',
  premium: priorPolicy?.premium || '',
  expiration_date: priorPolicy?.expiration_date || '',
  policy_number: priorPolicy?.policy_number || '',
  premium_period: priorPolicy || (policyType && isInsurableInterestRealProperty(policyType)) ? 12 : 1,

  // Home coverages
  deductible: coverageByKey(priorPolicy?.coverages, PolicyCoverageKey.Dwelling)?.deductible_value || '',
  [PolicyCoverageKey.Dwelling]: coverageByKey(priorPolicy?.coverages, PolicyCoverageKey.Dwelling)?.limit_value || '',
  [PolicyCoverageKey.OtherStructures]:
    coverageByKey(priorPolicy?.coverages, PolicyCoverageKey.OtherStructures)?.limit_value || '',
  [PolicyCoverageKey.PersonalProperty]:
    coverageByKey(priorPolicy?.coverages, PolicyCoverageKey.PersonalProperty)?.limit_value || '',
  [PolicyCoverageKey.LossOfUse]: coverageByKey(priorPolicy?.coverages, PolicyCoverageKey.LossOfUse)?.limit_value || '',
  [PolicyCoverageKey.PersonalLiability]:
    coverageByKey(priorPolicy?.coverages, PolicyCoverageKey.PersonalLiability)?.limit_value || '',
  [PolicyCoverageKey.MedicalPayments]:
    coverageByKey(priorPolicy?.coverages, PolicyCoverageKey.MedicalPayments)?.limit_value || '',

  // PAF coverages
  [PolicyCoverageKey.Jewelry]: coverageByKey(priorPolicy?.coverages, PolicyCoverageKey.Jewelry)?.limit_value || '',

  // Vehicle coverages
  [PolicyCoverageKey.PropertyDamage]:
    coverageByKey(priorPolicy?.coverages, PolicyCoverageKey.PropertyDamage)?.limit_value || '',
  [PolicyCoverageKey.BiPerPerson]:
    coverageByKey(priorPolicy?.coverages, PolicyCoverageKey.BiPerPerson)?.limit_value || '',
  [PolicyCoverageKey.BiPerAccident]:
    coverageByKey(priorPolicy?.coverages, PolicyCoverageKey.BiPerAccident)?.limit_value || ''
});

type PolicyCoverageValues = Omit<
  ReturnType<typeof buildInitialValues>,
  'carrier_gid' | 'premium' | 'expiration_date' | 'policy_number'
>;

const buildCoverages = ({
  dwelling,
  other_structures,
  personal_property,
  loss_of_use,
  personal_liability,
  medical_payments,
  deductible,
  property_damage,
  bodily_injury_per_person,
  bodily_injury_per_accident,
  jewelry
}: Partial<PolicyCoverageValues>) => {
  const dwellingNode = {
    key: PolicyCoverageKey.Dwelling,
    limit_type: LimitType.MoneyLimit,
    limit_value: dwelling,
    deductible_type: DeductibleType.Flat,
    deductible_value: deductible
  };
  const otherStructuresNode = {
    key: PolicyCoverageKey.OtherStructures,
    limit_type: LimitType.MoneyLimit,
    limit_value: other_structures,
    deductible_type: undefined,
    deductible_value: undefined
  };
  const personalPropertyNode = {
    key: PolicyCoverageKey.PersonalProperty,
    limit_type: LimitType.MoneyLimit,
    limit_value: personal_property,
    deductible_type: undefined,
    deductible_value: undefined
  };
  const lossOfUseNode = {
    key: PolicyCoverageKey.LossOfUse,
    limit_type: LimitType.MoneyLimit,
    limit_value: loss_of_use,
    deductible_type: undefined,
    deductible_value: undefined
  };
  const personalLiability = {
    key: PolicyCoverageKey.PersonalLiability,
    limit_type: LimitType.MoneyLimit,
    limit_value: personal_liability,
    deductible_type: undefined,
    deductible_value: undefined
  };
  const medicalPaymentsNode = {
    key: PolicyCoverageKey.MedicalPayments,
    limit_type: LimitType.MoneyLimit,
    limit_value: medical_payments,
    deductible_type: undefined,
    deductible_value: undefined
  };

  const propertyDamageNode = {
    key: PolicyCoverageKey.PropertyDamage,
    limit_type: LimitType.MoneyLimit,
    limit_value: property_damage,
    deductible_type: undefined,
    deductible_value: undefined
  };

  const biPerPersonNode = {
    key: PolicyCoverageKey.BiPerPerson,
    limit_type: LimitType.MoneyLimit,
    limit_value: bodily_injury_per_person,
    deductible_type: undefined,
    deductible_value: undefined
  };

  const biPerAccidentNode = {
    key: PolicyCoverageKey.BiPerAccident,
    limit_type: LimitType.MoneyLimit,
    limit_value: bodily_injury_per_accident,
    deductible_type: undefined,
    deductible_value: undefined
  };

  const jewelryNode = {
    key: PolicyCoverageKey.Jewelry,
    limit_type: LimitType.MoneyLimit,
    limit_value: jewelry,
    deductible_type: undefined,
    deductible_value: undefined
  };

  return [
    dwellingNode,
    otherStructuresNode,
    personalPropertyNode,
    lossOfUseNode,
    personalLiability,
    medicalPaymentsNode,
    propertyDamageNode,
    biPerPersonNode,
    biPerAccidentNode,
    jewelryNode
  ].filter(({ limit_value, deductible_value }) => limit_value || deductible_value);
};

export const validationSchema = yup.object().shape({
  premium: yup.number().min(1, 'must be greater than zero'),
  deductible: yup.number().min(1, 'must be greater than zero'),
  expiration_date: optionalDate(),
  [PolicyCoverageKey.Dwelling]: yup.number().min(1, 'must be greater than zero'),
  [PolicyCoverageKey.OtherStructures]: yup.number().min(1, 'must be greater than zero'),
  [PolicyCoverageKey.PersonalProperty]: yup.number().min(1, 'must be greater than zero'),
  [PolicyCoverageKey.LossOfUse]: yup.number().min(1, 'must be greater than zero'),
  [PolicyCoverageKey.PersonalLiability]: yup.number().min(1, 'must be greater than zero'),
  [PolicyCoverageKey.MedicalPayments]: yup.number().min(1, 'must be greater than zero'),
  [PolicyCoverageKey.Jewelry]: yup.number().min(1, 'must be greater than zero'),
  [PolicyCoverageKey.PropertyDamage]: yup.number().min(1, 'must be greater than zero'),
  [PolicyCoverageKey.BiPerPerson]: yup.number().min(1, 'must be greater than zero'),
  [PolicyCoverageKey.BiPerAccident]: yup.number().min(1, 'must be greater than zero')
});

type PriorPolicyValues = ReturnType<typeof buildInitialValues>;
type PossibleValues =
  | PriorPolicyValues
  | (PriorPolicyValues & typeof homeInitialValues)
  | (PriorPolicyValues & ReturnType<typeof vehiclesInitialValues>);

export type PriorPolicyHelperSubmitHandler =
  | (() => void)
  | ((
      arg?: Parameters<ReturnType<typeof useCreatePriorPolicy>['mutate']>['0']['params'] & { gid?: IPriorPolicy['gid'] }
    ) => void);

const calculatePremiumWithPeriod = (premium: number, premium_period: number) => {
  let calculatedPremium = premium;
  if (premium_period === 6) {
    calculatedPremium = calculatedPremium * 2;
  }
  if (premium_period === 1) {
    calculatedPremium = calculatedPremium * 12;
  }
  return calculatedPremium.toString();
};

export const usePriorPolicyEditorSubmit = ({
  personGid,
  priorPolicy,
  assetsGids,
  policyType,
  confirmHandler,
  leadGid
}: {
  personGid: string;
  priorPolicy?: IPolicy;
  policyType: PolicyType | undefined;
  assetsGids: string[] | undefined;
  confirmHandler: PriorPolicyHelperSubmitHandler;
  leadGid?: string;
}) => {
  const { mutateAsync: createHome } = useCreatePersonHome();
  const { mutateAsync: updatePriorPolicy } = useUpdatePriorPolicy();
  const { mutateAsync: createPriorPolicy } = useCreatePriorPolicy();
  const { mutateAsync: createVehicle } = useCreateVehicle();
  const { mutateAsync: addRelatedPerson } = useCreateRelatedPerson();

  return async ({
    carrier_gid,
    premium,
    expiration_date,
    policy_number,
    dwelling,
    other_structures,
    personal_property,
    loss_of_use,
    personal_liability,
    medical_payments,
    property_damage,
    bodily_injury_per_person,
    bodily_injury_per_accident,
    deductible,
    jewelry,
    premium_period,
    ...restValues
  }: PossibleValues) => {
    let asset_gids = assetsGids || null;
    if ('property_address' in restValues) {
      const { home } = await createHome({
        personGid,
        data: {
          address: restValues.property_address,
          usage: restValues.usage
        }
      });
      asset_gids = [home.gid];
      analytics.track('Home added', {
        lead_gid: leadGid,
        person_gid: personGid,
        place: 'summary_ui',
        home_gid: home.gid
      });
    }

    if ('vehicles' in restValues) {
      const manualVehiclesResponse = await Promise.all(
        restValues.vehicles.map(({ address, auto: { year, make, model, submodel, vin } }) =>
          createVehicle({
            personGid,
            data: { address, year, make, model, submodel, vin }
          })
        )
      );

      const prefilledVehiclesResponse = await Promise.all(
        restValues.prefilled_vehicles
          .filter(v => v.confirmed)
          .map(({ vehicle }) =>
            createVehicle({
              personGid,
              data: {
                ...vehicle,
                address: restValues.prefilled_vehicles_garage_address,
                data_source: 'fenris'
              }
            })
          )
      );
      const vehiclesResponse = manualVehiclesResponse.concat(prefilledVehiclesResponse);

      manualVehiclesResponse.forEach(({ vehicle }) =>
        analytics.track('Vehicle added', {
          lead_gid: leadGid,
          person_gid: personGid,
          place: 'summary_ui',
          vehicle_gid: vehicle.gid,
          source: 'manual'
        })
      );

      prefilledVehiclesResponse.forEach(({ vehicle }) =>
        analytics.track('Vehicle added', {
          lead_gid: leadGid,
          person_gid: personGid,
          place: 'summary_ui',
          vehicle_gid: vehicle.gid,
          source: 'fenris'
        })
      );

      await Promise.all(
        restValues.prefilled_drivers
          .filter(d => d.confirmed)
          .map(({ driver }) => addRelatedPerson({ sourcePersonGid: personGid, data: driver }))
      );

      asset_gids = vehiclesResponse.map(({ vehicle }) => vehicle.gid);
    }

    if (priorPolicy?.gid) {
      return updatePriorPolicy({
        personGid,
        priorPolicyGid: priorPolicy.gid,
        params: {
          carrier_gid,
          premium: calculatePremiumWithPeriod(Number(premium), premium_period),
          expiration_date,
          policy_number,
          coverages: buildCoverages({
            dwelling,
            other_structures,
            personal_property,
            loss_of_use,
            personal_liability,
            medical_payments,
            deductible,
            property_damage,
            bodily_injury_per_person,
            bodily_injury_per_accident,
            jewelry
          })
        }
      }).then(() => confirmHandler());
    } else {
      const args = {
        personGid,
        params: {
          policy_type: policyType!,
          asset_gids,
          carrier_gid,
          premium: calculatePremiumWithPeriod(Number(premium), premium_period),
          expiration_date,
          policy_number,
          coverages: buildCoverages({
            dwelling,
            other_structures,
            personal_property,
            loss_of_use,
            personal_liability,
            medical_payments,
            deductible,
            property_damage,
            bodily_injury_per_person,
            bodily_injury_per_accident,
            jewelry
          })
        }
      };

      return [
        args.params.carrier_gid,
        args.params.premium,
        args.params.expiration_date,
        args.params.policy_number,
        args.params.coverages.length
      ].find(Boolean)
        ? createPriorPolicy(args).then(({ prior_policy: { gid } }) => confirmHandler({ ...args.params, gid }))
        : confirmHandler(args.params);
    }
  };
};
