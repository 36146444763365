import featureFlags from '../../../constants/featureFlags';
import { IDataCollection } from '../../../interfaces';
import DeprecatedFinalizedSalesPage from './DeprecatedFinalizedSalesPage';
import FinalizedSalesPage from './FinalizedSalesPage';
import NewFinalizedSalesPage from './NewFinalizedSalesPage';

const FinalizeSalesPage = ({ dataCollection }: { dataCollection: IDataCollection }) => {
  if (featureFlags.enablePolicyDocumentsUI) {
    return <NewFinalizedSalesPage />;
  }

  return (
    <>
      {featureFlags.displayDisqualifyButton && dataCollection ? (
        <FinalizedSalesPage dataCollection={dataCollection} />
      ) : (
        <DeprecatedFinalizedSalesPage />
      )}
    </>
  );
};

export default FinalizeSalesPage;
