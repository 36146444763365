import * as yup from 'yup';

import { IQuote } from '../../../interfaces';
import { coverageByKey, LimitType, PolicyCoverageKey } from '../../../interfaces/IPolicyCoverage';
import { PolicyType } from '../../../interfaces/IPolicyType';
import { REQUIRED_MESSAGE, requiredField } from '../../../utils/yupRules';

export const PROPOSAL_IFRAME_ID = 'proposal_iframe';

export const EDIT_DISABLED_TOOLTIP_ID = 'quote-edit-disabled-tooltip';

export const reloadIframe = () => {
  const iframe = document.getElementById(PROPOSAL_IFRAME_ID) as HTMLIFrameElement;
  if (iframe) {
    iframe.src += '';
  }
};

export const HOME_COVERAGES_OPTIONS = [
  PolicyCoverageKey.ExtendedReplacementCost,
  PolicyCoverageKey.ReplacementCostOnContents,
  PolicyCoverageKey.WindHail,
  PolicyCoverageKey.Hurricane,
  PolicyCoverageKey.WaterBackup,
  PolicyCoverageKey.Flood,
  PolicyCoverageKey.Earthquake,
  PolicyCoverageKey.ServiceLine,
  PolicyCoverageKey.EquipmentBreakdown,
  PolicyCoverageKey.Foundation,
  PolicyCoverageKey.FunctionalReplacementCost
] as const;

export const COVERAGE_LABELS = {
  [PolicyCoverageKey.Dwelling]: 'Dwelling',
  [PolicyCoverageKey.OtherStructures]: 'Other Structures',
  [PolicyCoverageKey.PersonalProperty]: 'Personal Property',
  [PolicyCoverageKey.LossOfUse]: 'Loss of Use',
  [PolicyCoverageKey.PersonalLiability]: 'Personal Liability',
  [PolicyCoverageKey.MedicalPayments]: 'Medical Payments',
  [PolicyCoverageKey.ExtendedReplacementCost]: 'Extended Replacement Cost',
  [PolicyCoverageKey.ReplacementCostOnContents]: 'Replacement Cost on Contents',
  [PolicyCoverageKey.WindHail]: 'Wind/Hail',
  [PolicyCoverageKey.Hurricane]: 'Hurricane',
  [PolicyCoverageKey.WaterBackup]: 'Water Backup',
  [PolicyCoverageKey.Flood]: 'Flood',
  [PolicyCoverageKey.Earthquake]: 'Earthquake',
  [PolicyCoverageKey.ServiceLine]: 'Service Line',
  [PolicyCoverageKey.EquipmentBreakdown]: 'Equipment Breakdown',
  [PolicyCoverageKey.Foundation]: 'Foundation',
  [PolicyCoverageKey.FunctionalReplacementCost]: 'Functional Replacement Cost',
  [PolicyCoverageKey.HouseholdFurnishings]: 'Household Furnishings',
  [PolicyCoverageKey.FairRentalValue]: 'Fair Rental Value'
} as const;

export const buildIncludedCoverages = ({ excludedValues }: { excludedValues: PolicyCoverageKey[] }) =>
  HOME_COVERAGES_OPTIONS.map(coverage => ({
    key: coverage,
    label: COVERAGE_LABELS[coverage],
    isDisabled: excludedValues.includes(coverage)
  }));

export const buildExcludedCoverages = ({ includedValues }: { includedValues: PolicyCoverageKey[] }) =>
  HOME_COVERAGES_OPTIONS.map(coverage => ({
    key: coverage,
    label: COVERAGE_LABELS[coverage],
    isDisabled: includedValues.includes(coverage)
  }));

export const buildInitialValues = (quote: IQuote | undefined, policyType: PolicyType) => {
  const lossOfUseLimit = coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.LossOfUse)?.limit_value || '';
  const commonValues = {
    carrier_key: quote?.attributes.carrier.key || '',
    premium: quote?.attributes.premium || '',
    deductible: coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.Dwelling)?.deductible_value || '',
    [PolicyCoverageKey.MedicalPayments]:
      coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.MedicalPayments)?.limit_value || '',
    included_coverages: quote?.attributes.coverages
      ?.map(coverage =>
        HOME_COVERAGES_OPTIONS.includes(coverage.key) &&
        coverage.deductible_description !== 'Not included' &&
        coverage.limit_description !== 'Not included'
          ? coverage.key
          : undefined
      )
      .filter(Boolean) || [PolicyCoverageKey.WindHail],
    excluded_coverages:
      quote?.attributes.coverages
        ?.map(coverage =>
          coverage.deductible_description === 'Not included' || coverage.limit_description === 'Not included'
            ? coverage.key
            : undefined
        )
        .filter(Boolean) || [],
    [PolicyCoverageKey.ExtendedReplacementCost]:
      coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.ExtendedReplacementCost)?.deductible_value || '',
    [PolicyCoverageKey.ReplacementCostOnContents]:
      coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.ReplacementCostOnContents)?.limit_value || '',
    [PolicyCoverageKey.WindHail]:
      coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.WindHail)?.deductible_value || '',
    [PolicyCoverageKey.Hurricane]:
      coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.Hurricane)?.deductible_value || '',
    [PolicyCoverageKey.WaterBackup]:
      coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.WaterBackup)?.limit_value || '',
    [PolicyCoverageKey.Flood]: coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.Flood)?.limit_value || '',
    [PolicyCoverageKey.Earthquake]:
      coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.Earthquake)?.limit_value || '',
    [PolicyCoverageKey.ServiceLine]:
      coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.ServiceLine)?.limit_value || '',
    [PolicyCoverageKey.EquipmentBreakdown]:
      coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.EquipmentBreakdown)?.limit_value || '',
    [PolicyCoverageKey.Foundation]:
      coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.Foundation)?.limit_value || '',
    [PolicyCoverageKey.FunctionalReplacementCost]:
      coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.FunctionalReplacementCost)?.limit_value || '',
    agent_bridge_url: quote?.attributes.binding_url || ''
  };

  if (policyType === PolicyType.Condo) {
    return {
      ...commonValues,
      [PolicyCoverageKey.Dwelling]:
        coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.Dwelling)?.limit_value || '',
      [PolicyCoverageKey.PersonalProperty]:
        coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.PersonalProperty)?.limit_value || '',
      lossOfUseLimitType:
        lossOfUseLimit && Number(lossOfUseLimit) <= 100 ? LimitType.MonthlyLimit : LimitType.MoneyLimit,
      [PolicyCoverageKey.LossOfUse]: lossOfUseLimit,
      [PolicyCoverageKey.PersonalLiability]:
        coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.PersonalLiability)?.limit_value || ''
    };
  }

  if (policyType === PolicyType.Fire) {
    return {
      ...commonValues,
      [PolicyCoverageKey.Dwelling]:
        coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.Dwelling)?.limit_value || '',
      [PolicyCoverageKey.OtherStructures]:
        coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.OtherStructures)?.limit_value || '',
      [PolicyCoverageKey.PersonalLiability]: coverageByKey(
        quote?.attributes.coverages,
        PolicyCoverageKey.PersonalLiability
      )?.limit_value,
      [PolicyCoverageKey.HouseholdFurnishings]:
        coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.HouseholdFurnishings)?.limit_value || '',
      [PolicyCoverageKey.FairRentalValue]:
        coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.FairRentalValue)?.limit_value || ''
    };
  }

  if (policyType === PolicyType.Renters) {
    return {
      ...commonValues,
      [PolicyCoverageKey.PersonalProperty]:
        coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.PersonalProperty)?.limit_value || '',
      lossOfUseLimitType:
        lossOfUseLimit && Number(lossOfUseLimit) <= 100 ? LimitType.MonthlyLimit : LimitType.MoneyLimit,
      [PolicyCoverageKey.LossOfUse]: lossOfUseLimit,
      [PolicyCoverageKey.PersonalLiability]:
        coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.PersonalLiability)?.limit_value || ''
    };
  }

  return {
    ...commonValues,
    [PolicyCoverageKey.Dwelling]:
      coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.Dwelling)?.limit_value || '',
    [PolicyCoverageKey.OtherStructures]:
      coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.OtherStructures)?.limit_value || '',
    [PolicyCoverageKey.PersonalProperty]:
      coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.PersonalProperty)?.limit_value || '',
    lossOfUseLimitType: lossOfUseLimit && Number(lossOfUseLimit) <= 100 ? LimitType.MonthlyLimit : LimitType.MoneyLimit,
    [PolicyCoverageKey.LossOfUse]: lossOfUseLimit,
    [PolicyCoverageKey.PersonalLiability]:
      coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.PersonalLiability)?.limit_value || ''
  };
};

export const buildYupSchema = (policyType: PolicyType) => {
  const commonValidations = yup.object().shape({
    carrier_key: requiredField,
    premium: yup.number().min(1, 'must be greater than zero').required(REQUIRED_MESSAGE),
    agent_bridge_url: yup
      .string()
      .required(REQUIRED_MESSAGE)
      .test('is-url', 'must be a valid URL', value => {
        try {
          new URL(value);
          return true;
        } catch (error) {
          return false;
        }
      }),
    deductible: yup.number().min(1, 'must be greater than zero').required(REQUIRED_MESSAGE),
    [PolicyCoverageKey.MedicalPayments]: yup.number().min(1, 'must be greater than zero').required(REQUIRED_MESSAGE)
  });

  if (policyType === PolicyType.Condo) {
    const condoValidations = yup.object().shape({
      [PolicyCoverageKey.Dwelling]: yup.number().min(1, 'must be greater than zero').required(REQUIRED_MESSAGE),
      [PolicyCoverageKey.PersonalProperty]: yup.number().min(1, 'must be greater than zero').required(REQUIRED_MESSAGE),
      [PolicyCoverageKey.LossOfUse]: yup.number().min(1, 'must be greater than zero').required(REQUIRED_MESSAGE),
      [PolicyCoverageKey.PersonalLiability]: yup
        .number()
        .min(1, 'must be greater than zero')
        .required(REQUIRED_MESSAGE),
      [PolicyCoverageKey.Hurricane]: yup.number().min(1, 'must be greater than zero'),
      [PolicyCoverageKey.WindHail]: yup.number().min(1, 'must be greater than zero'),
      [PolicyCoverageKey.ExtendedReplacementCost]: yup
        .number()
        .min(1, 'must be greater than zero')
        .max(100, 'must be less than 100')
    });

    return commonValidations.concat(condoValidations);
  }

  if (policyType === PolicyType.Fire) {
    const investmentValidations = yup.object().shape({
      [PolicyCoverageKey.Dwelling]: yup.number().min(1, 'must be greater than zero').required(REQUIRED_MESSAGE),
      [PolicyCoverageKey.OtherStructures]: yup.number().min(1, 'must be greater than zero').required(REQUIRED_MESSAGE),
      [PolicyCoverageKey.HouseholdFurnishings]: yup
        .number()
        .min(1, 'must be greater than zero')
        .required(REQUIRED_MESSAGE),
      [PolicyCoverageKey.FairRentalValue]: yup.number().min(1, 'must be greater than zero').required(REQUIRED_MESSAGE),
      [PolicyCoverageKey.PersonalLiability]: yup.number().min(1, 'must be greater than zero').required(REQUIRED_MESSAGE)
    });

    return commonValidations.concat(investmentValidations);
  }

  if (policyType === PolicyType.Renters) {
    const rentersValidations = yup.object().shape({
      [PolicyCoverageKey.PersonalProperty]: yup.number().min(1, 'must be greater than zero').required(REQUIRED_MESSAGE),
      [PolicyCoverageKey.LossOfUse]: yup.number().min(1, 'must be greater than zero').required(REQUIRED_MESSAGE),
      [PolicyCoverageKey.PersonalLiability]: yup.number().min(1, 'must be greater than zero').required(REQUIRED_MESSAGE)
    });

    return commonValidations.concat(rentersValidations);
  }

  const defaultHomeValidations = yup.object().shape({
    [PolicyCoverageKey.Dwelling]: yup.number().min(1, 'must be greater than zero').required(REQUIRED_MESSAGE),
    [PolicyCoverageKey.OtherStructures]: yup.number().min(1, 'must be greater than zero').required(REQUIRED_MESSAGE),
    [PolicyCoverageKey.PersonalProperty]: yup.number().min(1, 'must be greater than zero').required(REQUIRED_MESSAGE),
    [PolicyCoverageKey.LossOfUse]: yup.number().min(1, 'must be greater than zero').required(REQUIRED_MESSAGE),
    [PolicyCoverageKey.PersonalLiability]: yup.number().min(1, 'must be greater than zero').required(REQUIRED_MESSAGE)
  });

  return commonValidations.concat(defaultHomeValidations);
};
