/** @jsxImportSource @emotion/react */
import { ClassNames } from '@emotion/react';
import * as React from 'react';
import { Tab, TabList, TabPanel, Tabs as ReactTabs } from 'react-tabs';

import colors from '../../../theme/colors';
import Paragraph from '../Paragraph';

export type TabType = {
  // don't forget to pass key to JSX.Element
  tabName: JSX.Element | string;
  content: JSX.Element | string;
  active?: boolean;
};

interface HorizontalTabsProps {
  tabs: TabType[];
  stickyHeader?: boolean;
}

const HorizontalTabs = ({ tabs, stickyHeader = false }: HorizontalTabsProps): JSX.Element => {
  const activeTabIndex = tabs.findIndex(tab => tab.active);
  const [selectedIndex, setSelectedIndex] = React.useState(activeTabIndex > 0 ? activeTabIndex : 0);

  return (
    <ClassNames>
      {({ css }) => (
        <ReactTabs
          selectedIndex={selectedIndex}
          onSelect={index => {
            setSelectedIndex(index);
          }}
          className={css`
            color: ${colors.black};
            width: 100%;
            ${stickyHeader ? 'position: relative;' : 'overflow-y: auto;'}
          `}
        >
          <TabList
            className={css`
              border-bottom: 1px solid ${colors.black};
              margin: 0;
              padding: 0;
              width: 100%;
              background-color: ${colors.white};
              z-index: 2;
              ${stickyHeader && 'position: sticky; top: 0;'}
            `}
          >
            {tabs.map((tab, index) => {
              const tabKey = typeof tab.tabName === 'string' ? tab.tabName : tab.tabName.key;
              return (
                <Tab
                  className={css`
                    display: inline-block;
                    border: none;
                    bottom: -1px;
                    position: relative;
                    list-style: none;
                    padding: 10px 10px 0 10px;
                    cursor: pointer;
                    transition: border-bottom 0.3s linear;
                    border-bottom: 2px rgba(21, 110, 234, 0) solid; // azure50 with 0 alpha
                  `}
                  selectedClassName={css`
                    border-bottom: 2px rgba(21, 110, 234, 1) solid !important; // azure50 with 0 alpha
                  `}
                  key={tabKey}
                  data-testid={`${tabKey}-tab-testid`}
                >
                  {typeof tab.tabName === 'string' ? (
                    <Paragraph
                      bold={selectedIndex === index}
                      color={selectedIndex === index ? colors.azure50 : undefined}
                    >
                      {tab.tabName}
                    </Paragraph>
                  ) : (
                    <div
                      css={css`
                        margin-bottom: 16px;
                      `}
                    >
                      {selectedIndex === index
                        ? React.cloneElement(tab.tabName, { color: colors.azure50 })
                        : tab.tabName}
                    </div>
                  )}
                </Tab>
              );
            })}
          </TabList>

          {tabs.map(tab => {
            const tabKey = typeof tab.tabName === 'string' ? tab.tabName : tab.tabName.key;
            return (
              <TabPanel
                className={css`
                  display: none;
                  margin: 12px 2px;
                `}
                selectedClassName={css`
                  display: block;
                `}
                key={tabKey}
                data-testid={`${tabKey}-tab-content-testid`}
              >
                {tab.content}
              </TabPanel>
            );
          })}
        </ReactTabs>
      )}
    </ClassNames>
  );
};

export default HorizontalTabs;
