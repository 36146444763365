import * as React from 'react';

import DescriptionList from '../../../components/core/DescriptionList';
import FlexBox from '../../../components/core/FlexBox';
import { HomeIcon } from '../../../components/core/icons';
import IconWithText from '../../../components/core/IconWithText';
import Text from '../../../components/core/Text';
import { INFO_NOT_PRESENT } from '../../../constants';
import { IMaticPolicy } from '../../../interfaces/IPolicy';
import { findPolicyTypeLabel } from '../../../interfaces/IPolicyType';
import useNonSyncedPolicyHome from '../../../queries/policies/useNonSyncedPolicyHome';
import { spacings } from '../../../theme/variables';
import { capitalize } from '../../../utils/formatter';
import AssetDetailsPlaceholder from '../AssetDetailsPlaceholder';

const NotSyncedPolicyhome = ({ policy }: { policy: IMaticPolicy }) => {
  const { data: home, isLoading: isLoadingHome } = useNonSyncedPolicyHome(policy.id);

  if (isLoadingHome) {
    return <AssetDetailsPlaceholder />;
  }
  return (
    <>
      {home && (
        <FlexBox columnDirection>
          <FlexBox justifySpaceBetween alignItemsCenter>
            <IconWithText text={`${findPolicyTypeLabel(policy.policy_type)}`}>
              <HomeIcon />
            </IconWithText>
          </FlexBox>
          <FlexBox pt={spacings.px12} mh={spacings.px24} columnDirection>
            <Text bold>{home?.address?.full || INFO_NOT_PRESENT}</Text>
            <DescriptionList
              term={<Text>Residency type</Text>}
              details={<Text className="fs-mask">{capitalize(home?.usage) || INFO_NOT_PRESENT}</Text>}
            />
            <DescriptionList
              term={<Text>Year built</Text>}
              details={<Text className="fs-mask">{home?.year_built || INFO_NOT_PRESENT}</Text>}
            />
            <DescriptionList
              term={<Text>Sq ft</Text>}
              details={<Text className="fs-mask">{home?.square_feet || INFO_NOT_PRESENT}</Text>}
            />
            <DescriptionList
              term={<Text>Roof material</Text>}
              details={<Text className="fs-mask">{capitalize(home?.roof_material) || INFO_NOT_PRESENT}</Text>}
            />
            <DescriptionList
              term={<Text>Roof year</Text>}
              details={<Text className="fs-mask">{home?.roof_year || INFO_NOT_PRESENT}</Text>}
            />
          </FlexBox>
        </FlexBox>
      )}
    </>
  );
};

export default NotSyncedPolicyhome;
