import {
  AttachmentIcon,
  BundleIcon,
  ClockIcon,
  DollarIcon,
  NoteIcon,
  TaskIcon,
  UserIcon
} from '../../../components/core/icons';
import { IPerson } from '../../../interfaces';
import VerticalTabs from '../_components/VerticalTabs';
import AssetsTab from './AssetsTab';
import CommunicationHistoryTab from './CommunicationHistoryTab';
import FilesTab from './FilesTab';
import LoansTab from './LoansTab';
import NotesTab from './NotesTab/intex';
import PeopleTab from './PeopleTab';
import TasksTab from './TasksTab';

const Tabs = ({ person }: { person: IPerson }) => {
  const personGid = person.gid;
  return (
    <VerticalTabs
      wide
      collapsedByDefault
      tabs={[
        {
          tabIcon: <UserIcon key="people" />,
          tabName: 'People',
          content: <PeopleTab personGid={personGid} />
        },
        {
          tabIcon: <BundleIcon key="assets" />,
          tabName: 'Assets',
          content: <AssetsTab personGid={personGid} />
        },
        {
          tabIcon: <DollarIcon key="loans" />,
          tabName: 'Loans',
          content: <LoansTab personGid={personGid} />
        },
        {
          tabIcon: <NoteIcon key="notes" />,
          tabName: 'Notes',
          content: <NotesTab personGid={personGid} />
        },
        {
          tabIcon: <TaskIcon key="tasks" />,
          tabName: 'Tasks',
          content: <TasksTab person={person} />
        },
        {
          tabIcon: <ClockIcon key="feed" />,
          tabName: 'Feed',
          content: <CommunicationHistoryTab personGid={personGid} />
        },
        {
          tabIcon: <AttachmentIcon key="docs" />,
          tabName: 'Docs',
          content: <FilesTab personGid={personGid} />
        }
      ]}
    />
  );
};

export default Tabs;
