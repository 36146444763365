/* eslint-disable @typescript-eslint/no-use-before-define */
const resource = (strings: any, ...keys: any[]) => {
  const format = (values: any = {}) => {
    const result = [strings[0]];

    keys.forEach((key, i) => {
      const value = values[key] || (resources[key] && resources[key].format(values));

      result.push(value, strings[i + 1]);
    });

    return result.join('');
  };
  return { format };
};

const resources = {
  API: resource`/api/frontend`,
  AUTH: resource`${'API'}/authenticate`,
  LEADS: resource`${'API'}/leads/${'leadId'}`,
  DOCUMENT_TYPES: resource`${'API'}/document_types`
} as any;

export default resources;

/* eslint-enable @typescript-eslint/no-use-before-define */
