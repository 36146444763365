import 'react-multi-carousel/lib/styles.css';

/** @jsxImportSource @emotion/react */
import { css, Global } from '@emotion/react';
import useResizeObserver from '@react-hook/resize-observer';
import * as React from 'react';
import Carousel from 'react-multi-carousel';

import IconButton, { ButtonIcons } from '../../../../../components/core/buttons/IconButton';
import FlexBox from '../../../../../components/core/FlexBox';
import colors from '../../../../../theme/colors';
import { spacings } from '../../../../../theme/variables';
import {
  CarrierCell,
  DeductibleCell,
  DeductibleHeading,
  DwellingCell,
  DwellingHeading,
  ExpirationDateCell,
  ExpirationDateHeading,
  PolicyStatusCell,
  PremiumCell,
  PremiumHeading,
  RateChangeCell,
  RateChangeHeading,
  TableDataModel
} from './tableColumns';

export const ButtonGroup = ({ next, previous }: any) => {
  return (
    <div
      css={css`
        position: absolute;
        right: 0;
        top: -30px;
        background-color: ${colors.white};
      `}
    >
      <IconButton icon={ButtonIcons.ChevronLeft} color={colors.black} onClick={() => previous()} />
      <IconButton icon={ButtonIcons.ChevronRight} color={colors.black} onClick={() => next()} />
    </div>
  );
};

const CarouselItem = ({ children }: { children: React.ReactNode }) => (
  <div
    css={css`
      padding: 8px 4px;
      text-align: center;
    `}
  >
    {children}
  </div>
);

const HeadingItem = ({ children }: { children: React.ReactNode }) => (
  <div
    css={css`
      height: 35px;
      display: flex;
      align-items: center;
    `}
  >
    {children}
  </div>
);

const VerticalHeader = ({ isPremiumAnnual, setIsPremiumAnnual, policy_type }: TableDataModel) => {
  return (
    <FlexBox
      columnDirection
      pv={spacings.px4}
      gap={spacings.px8}
      customCss={css`
        width: 25%;
      `}
    >
      <HeadingItem>
        <div />
      </HeadingItem>
      <HeadingItem>
        <div />
      </HeadingItem>
      <HeadingItem>
        <PremiumHeading isPremiumAnnual={isPremiumAnnual} setAnnualPremium={setIsPremiumAnnual} />
      </HeadingItem>
      <HeadingItem>
        <DwellingHeading policyType={policy_type} />
      </HeadingItem>
      <HeadingItem>
        <DeductibleHeading policyType={policy_type} />
      </HeadingItem>
      <HeadingItem>
        <RateChangeHeading />
      </HeadingItem>
      <HeadingItem>
        <ExpirationDateHeading />
      </HeadingItem>
    </FlexBox>
  );
};

const PolicyItem = ({ data }: { data: TableDataModel }) => {
  return (
    <FlexBox
      columnDirection
      alignItemsCenter
      gap={spacings.px8}
      customCss={[
        data.rowCustomCSS,
        css`
          max-width: 150px;
        `
      ].filter(Boolean)}
    >
      <CarouselItem>
        <CarrierCell {...data} />
      </CarouselItem>
      <CarouselItem>
        <PolicyStatusCell {...data} />
      </CarouselItem>
      <CarouselItem>
        <PremiumCell {...data} />
      </CarouselItem>
      <CarouselItem>
        <DwellingCell {...data} />
      </CarouselItem>
      <CarouselItem>
        <DeductibleCell {...data} />
      </CarouselItem>
      <CarouselItem>
        <RateChangeCell {...data} />
      </CarouselItem>
      <CarouselItem>
        <ExpirationDateCell {...data} />
      </CarouselItem>
    </FlexBox>
  );
};

const responsive = {
  desktop: {
    breakpoint: { max: 5000, min: 300 },
    items: 2,
    partialVisibilityGutter: 0
  }
};

const CarouselColumns = ({ data }: { data: TableDataModel[] }) => {
  const elRef = React.useRef<HTMLDivElement | null>(null);

  const [, rerender] = React.useState({});
  useResizeObserver(elRef, _entry => rerender({}));

  const firstRow = data[0];
  const showArrows = data.length > 2;

  return (
    <FlexBox mt={showArrows ? spacings.px24 : undefined}>
      {firstRow && <VerticalHeader {...firstRow} />}
      <Global
        styles={() => css`
          .carousel-item-padding {
            padding: ${spacings.px4}px;
          }
        `}
      />
      <div
        id="carousel-container"
        ref={elRef}
        css={css`
          position: relative;
          width: 75%;
        `}
      >
        <Carousel
          arrows={false}
          renderButtonGroupOutside={true}
          customButtonGroup={showArrows ? <ButtonGroup /> : null}
          swipeable={false}
          draggable={false}
          showDots={false}
          centerMode={false}
          responsive={responsive}
          ssr={false} // means to render carousel on server-side.
          infinite={false}
          autoPlay={false}
          itemClass="carousel-item-padding"
        >
          {data.map(row => (
            <PolicyItem key={row.gid} data={row} />
          ))}
        </Carousel>
      </div>
    </FlexBox>
  );
};

export default CarouselColumns;
