/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import useResizeObserver from '@react-hook/resize-observer';
import React from 'react';
import ReactTooltip from 'react-tooltip';

import FlexBox from '../../../../../components/core/FlexBox';
import buildUIFlow from '../../../../../components/core/forms/uiFlowBuilder';
import Paragraph from '../../../../../components/core/Paragraph';
import featureFlags from '../../../../../constants/featureFlags';
import { IOpportunity, IRelatedPerson } from '../../../../../interfaces';
import {
  HOME_UNDERWRITING_FLOW_SIDEBAR_FORM,
  HOME_UNDERWRITING_FLOW_SIDEBAR_FORM_V3,
  SIDEBAR_HOME_BASIC_INFO_V2,
  SIDEBAR_HOME_EXTENDED_INFO_V2,
  usePersonAssetsUIFlows
} from '../../../../../queries/workflows/useUiFlow';
import colors from '../../../../../theme/colors';
import { spacings } from '../../../../../theme/variables';
import { getCurrentWidth } from '../../../_components/VerticalTabs/helpers';
import Home from './Home';
import HomeEligibilityForm from './HomeEligibilityForm';
import HomeForm from './HomeForm';
import HomesSkeleton from './HomesSkeleton';

interface HomesProps {
  personGid: string;
  engagementGid: string | undefined;
  homeOpportunities: IOpportunity[];
  isEditModeAvailable: boolean;
  relatedPeople: IRelatedPerson[] | undefined;
}

const WidthProvider = ({ children }: { children: (a: boolean) => React.ReactNode }) => {
  const [isExtraSmall, setIsExtraSmall] = React.useState(getCurrentWidth() < 500);

  const elRef = React.useRef<HTMLDivElement>(null);
  useResizeObserver(elRef, entry => setIsExtraSmall(entry.contentRect.width < 500));

  return (
    <FlexBox columnDirection gap={spacings.px12} ref={elRef}>
      {children(isExtraSmall)}
    </FlexBox>
  );
};

const Homes = ({ personGid, engagementGid, homeOpportunities, isEditModeAvailable, relatedPeople }: HomesProps) => {
  const opportunities = homeOpportunities.filter(homeOpportunity => homeOpportunity.assets?.[0]);
  const assetsGids = opportunities.map(homeOpportunity => homeOpportunity.assets?.[0]?.gid).filter(Boolean);

  const { data: homeProfileUIFlows, isPending: isPendingHomeProfileUIFlows } = usePersonAssetsUIFlows({
    uiFlowKey: SIDEBAR_HOME_BASIC_INFO_V2,
    personGid,
    assetsGids
  });

  const { data: homeDetailsUIFlows, isPending: isPendingHomeDetailsUIFLows } = usePersonAssetsUIFlows({
    uiFlowKey: SIDEBAR_HOME_EXTENDED_INFO_V2,
    personGid,
    assetsGids
  });

  const { data: homeEligibilityUIFlows, isPending: isPendingHomeEligibilityFlows } = usePersonAssetsUIFlows({
    uiFlowKey: featureFlags.homeEligibilityPropertyUnderMaintenance
      ? HOME_UNDERWRITING_FLOW_SIDEBAR_FORM_V3
      : HOME_UNDERWRITING_FLOW_SIDEBAR_FORM,
    personGid,
    assetsGids
  });

  const isPending = isPendingHomeProfileUIFlows || isPendingHomeDetailsUIFLows || isPendingHomeEligibilityFlows;

  React.useEffect(() => {
    ReactTooltip.rebuild();
  });

  const [editedHomeDetailsId, setEditedHomeDetailsId] = React.useState<IOpportunity['id']>();
  const [editedHomeEligibilityId, setEditedHomeEligibilityId] = React.useState<IOpportunity['id']>();

  if (isPending) {
    return <HomesSkeleton />;
  }

  if (opportunities.length === 0) {
    return (
      <Paragraph
        mt={spacings.px24}
        color={colors.grey60}
        customCss={css`
          text-align: center;
        `}
      >
        No homes are added
      </Paragraph>
    );
  }

  return (
    <WidthProvider>
      {isExtraSmall => {
        return opportunities.map((homeOpportunity, index) => {
          const homeOpportunityId = homeOpportunity.id;

          const homeProfileUIFlow = buildUIFlow({
            uiFlowResponse: homeProfileUIFlows?.[index]?.data?.ui_flow
          });
          const homeDetailsUIFlow = buildUIFlow({
            uiFlowResponse: homeDetailsUIFlows?.[index]?.data?.ui_flow
          });
          const homeEligibilityUIFlow = buildUIFlow({ uiFlowResponse: homeEligibilityUIFlows?.[index]?.data?.ui_flow });

          if (!homeProfileUIFlow || !homeDetailsUIFlow || !homeEligibilityUIFlow) {
            return null;
          }

          if (editedHomeDetailsId === homeOpportunityId) {
            return (
              <HomeForm
                key={homeOpportunityId}
                isExtraSmall={isExtraSmall}
                personGid={personGid}
                engagementGid={engagementGid}
                homeOpportunity={homeOpportunity}
                homeProfileUIFlow={homeProfileUIFlow}
                homeDetailsUIFlow={homeDetailsUIFlow}
                homeEligibilityUIFlow={homeEligibilityUIFlow}
                onClose={() => setEditedHomeDetailsId(undefined)}
                onSubmit={() => setEditedHomeDetailsId(undefined)}
                relatedPeople={relatedPeople}
              />
            );
          }

          if (editedHomeEligibilityId === homeOpportunityId) {
            return (
              <HomeEligibilityForm
                key={homeOpportunityId}
                personGid={personGid}
                engagementGid={engagementGid}
                homeOpportunity={homeOpportunity}
                homeEligibilityUIFlow={homeEligibilityUIFlow}
                onClose={() => setEditedHomeEligibilityId(undefined)}
                onSubmit={() => setEditedHomeEligibilityId(undefined)}
              />
            );
          }

          if (!editedHomeDetailsId && !editedHomeEligibilityId) {
            return (
              <Home
                key={homeOpportunityId}
                isExtraSmall={isExtraSmall}
                personGid={personGid}
                homeOpportunity={homeOpportunity}
                relatedPeople={relatedPeople}
                homeProfileUIFlow={homeProfileUIFlow}
                homeDetailsUIFlow={homeDetailsUIFlow}
                homeEligibilityUIFlow={homeEligibilityUIFlow}
                isEditModeAvailable={isEditModeAvailable}
                onEditHomeDetails={() => setEditedHomeDetailsId(homeOpportunityId)}
                onEditHomeEligibility={() => setEditedHomeEligibilityId(homeOpportunityId)}
              />
            );
          }

          return <React.Fragment key={homeOpportunityId} />;
        });
      }}
    </WidthProvider>
  );
};

export default Homes;
