/** @jsxImportSource @emotion/react */
import { ClassNames, css } from '@emotion/react';
import React from 'react';

import Copy from '../../../components/core/Copy';
import FlexBox from '../../../components/core/FlexBox';
import Paragraph from '../../../components/core/Paragraph';
import TelephonyActions from '../../../components/core/TelephonyActions';
import TelephonyTooltip from '../../../components/core/TelephonyTooltip';
import Text from '../../../components/core/Text';
import CopyableAnswer from '../../../components/UIFlow/CopyableAnswer';
import EmptyAnswer from '../../../components/UIFlow/EmptyAnswer';
import QuestionAnswerPair from '../../../components/UIFlow/QuestionAnswerPair';
import { Translations } from '../../../constants';
import { IPerson } from '../../../interfaces';
import usePersonDNC from '../../../queries/people/dncStatus';
import authInfo from '../../../services/authInfo';
import colors from '../../../theme/colors';
import { spacings } from '../../../theme/variables';
import { dateFormatter, phoneFormatter } from '../../../utils/formatter';
import DOBWrapper from '../../GuidedSellingExperience/tabs/CollectedInfoTab/_components/DOBWrapper';

const CustomerProfileView = ({ person }: { person: IPerson }) => {
  const { data: doNotContact, isSuccess } = usePersonDNC(person.gid);
  const canCallWithDNC = authInfo.features.do_not_contact_call_available;

  return (
    <>
      <FlexBox key={person.gid} columnDirection gap={spacings.px8}>
        <FlexBox gap={spacings.px16} columnDirection pl={spacings.px28}>
          <FlexBox columnDirection gap={spacings.px12}>
            <Paragraph bold>Contact info</Paragraph>
            {isSuccess && (
              <QuestionAnswerPair
                questionLabel={<Text color={colors.grey60}>Email</Text>}
                answerLabel={
                  <>
                    {doNotContact && !canCallWithDNC ? (
                      <Text color={colors.statusRed} data-testid="email-do-not-contact">
                        Do not contact
                      </Text>
                    ) : (
                      <>
                        {person.email && (
                          <TelephonyActions email={person.email} personGid={person.gid}>
                            <CopyableAnswer
                              withIcon={false}
                              fsMask
                              value={person.email}
                              customCss={
                                doNotContact && canCallWithDNC
                                  ? css`
                                      color: ${colors.statusRed} !important;
                                    `
                                  : undefined
                              }
                              testId="customer-profile-email"
                            />
                          </TelephonyActions>
                        )}
                        {!person.email && <EmptyAnswer />}
                      </>
                    )}
                  </>
                }
              />
            )}
            {person.phone && isSuccess && (
              <QuestionAnswerPair
                questionLabel={<Text color={colors.grey60}>Phone</Text>}
                answerLabel={
                  doNotContact && !canCallWithDNC ? (
                    <Text color={colors.statusRed} data-testid="phone-do-not-contact">
                      Do not contact
                    </Text>
                  ) : (
                    <TelephonyTooltip phone={person.phone} personGid={person.gid}>
                      <ClassNames>
                        {({ css }) => (
                          <Copy
                            className={
                              doNotContact && canCallWithDNC
                                ? `${css(`color: ${colors.statusRed};`)} fs-mask`
                                : 'fs-mask'
                            }
                            value={phoneFormatter(person.phone)}
                            testId="customer-profile-phone"
                          >
                            {phoneFormatter(person.phone)}
                          </Copy>
                        )}
                      </ClassNames>
                    </TelephonyTooltip>
                  )
                }
              />
            )}
            {person.secondary_phone && isSuccess && (
              <QuestionAnswerPair
                questionLabel={<Text color={colors.grey60}>Second phone</Text>}
                answerLabel={
                  doNotContact && !canCallWithDNC ? (
                    <Text color={colors.statusRed} data-testid="second-phone-do-not-contact">
                      Do not contact
                    </Text>
                  ) : (
                    <TelephonyTooltip phone={person.secondary_phone} personGid={person.gid}>
                      <ClassNames>
                        {({ css }) => (
                          <Copy
                            className={
                              doNotContact && canCallWithDNC
                                ? `${css(`color: ${colors.statusRed};`)} fs-mask`
                                : 'fs-mask'
                            }
                            value={phoneFormatter(person.secondary_phone)}
                            testId="collect-info-tab-customer-profile-results-secondary_phone"
                          >
                            {phoneFormatter(person.secondary_phone)}
                          </Copy>
                        )}
                      </ClassNames>
                    </TelephonyTooltip>
                  )
                }
              />
            )}
            {person.business_phone && isSuccess && (
              <QuestionAnswerPair
                questionLabel={<Text color={colors.grey60}>Business phone</Text>}
                answerLabel={
                  doNotContact && !canCallWithDNC ? (
                    <Text color={colors.statusRed} data-testid="business-phone-do-not-contact">
                      Do not contact
                    </Text>
                  ) : (
                    <TelephonyTooltip phone={person.business_phone} personGid={person.gid}>
                      <ClassNames>
                        {({ css }) => (
                          <Copy
                            className={
                              doNotContact && canCallWithDNC
                                ? `${css(`color: ${colors.statusRed};`)} fs-mask`
                                : 'fs-mask'
                            }
                            value={phoneFormatter(person.business_phone)}
                            testId="collect-info-tab-customer-profile-results-business_phone"
                          >
                            {phoneFormatter(person.business_phone)}
                          </Copy>
                        )}
                      </ClassNames>
                    </TelephonyTooltip>
                  )
                }
              />
            )}
          </FlexBox>

          <FlexBox columnDirection gap={spacings.px20}>
            <Paragraph bold>Personal info</Paragraph>

            <QuestionAnswerPair
              questionLabel={<Text color={colors.grey60}>First name</Text>}
              answerLabel={
                person.first_name ? (
                  <CopyableAnswer testId="customer-profile-first-name" value={person.first_name} fsMask />
                ) : (
                  <EmptyAnswer />
                )
              }
            />
            <QuestionAnswerPair
              questionLabel={<Text color={colors.grey60}>Middle name</Text>}
              answerLabel={
                person.middle_name ? (
                  <CopyableAnswer testId="customer-profile-middle-name" value={person.middle_name} fsMask />
                ) : (
                  <EmptyAnswer />
                )
              }
            />
            <QuestionAnswerPair
              questionLabel={<Text color={colors.grey60}>Last name</Text>}
              answerLabel={
                person.last_name ? (
                  <CopyableAnswer testId="customer-profile-last-name" value={person.last_name} fsMask />
                ) : (
                  <EmptyAnswer />
                )
              }
            />
            <QuestionAnswerPair
              questionLabel={<Text color={colors.grey60}>Marital status</Text>}
              answerLabel={
                person.marital_status ? (
                  <CopyableAnswer
                    testId="customer-profile-marital-status"
                    value={Translations.maritalStatus(person.marital_status)}
                    fsMask
                  />
                ) : (
                  <EmptyAnswer />
                )
              }
            />
            <QuestionAnswerPair
              questionLabel={<Text color={colors.grey60}>DOB</Text>}
              answerLabel={
                person.date_of_birth ? (
                  <DOBWrapper>
                    <CopyableAnswer fsMask value={dateFormatter(person.date_of_birth)} testId="customer-profile-dob" />
                  </DOBWrapper>
                ) : (
                  <EmptyAnswer />
                )
              }
            />
            <QuestionAnswerPair
              questionLabel={<Text color={colors.grey60}>Gender</Text>}
              answerLabel={
                person.gender ? (
                  <CopyableAnswer testId="customer-profile-gender" value={Translations.gender(person.gender)} />
                ) : (
                  <EmptyAnswer />
                )
              }
            />
            <QuestionAnswerPair
              questionLabel={<Text color={colors.grey60}>SNN</Text>}
              answerLabel={
                person.ssn ? (
                  <CopyableAnswer testId="customer-profile-ssn" value={person.ssn} fsMask />
                ) : (
                  <EmptyAnswer />
                )
              }
            />
            <QuestionAnswerPair
              questionLabel={<Text color={colors.grey60}>Fico score</Text>}
              answerLabel={
                person.fico_score ? (
                  <CopyableAnswer testId="customer-profile-fico-score" value={person.fico_score.toString()} fsMask />
                ) : (
                  <EmptyAnswer />
                )
              }
            />
            <QuestionAnswerPair
              questionLabel={<Text color={colors.grey60}>Education</Text>}
              answerLabel={
                person.education ? (
                  <CopyableAnswer
                    testId="customer-profile-education"
                    value={Translations.education(person.education)}
                  />
                ) : (
                  <EmptyAnswer />
                )
              }
            />
            <QuestionAnswerPair
              questionLabel={<Text color={colors.grey60}>Occupation</Text>}
              answerLabel={
                person.occupation_type ? (
                  <CopyableAnswer
                    testId="customer-profile-occupation-type"
                    value={Translations.occupation(person.occupation_type)}
                  />
                ) : (
                  <EmptyAnswer />
                )
              }
            />
            <QuestionAnswerPair
              questionLabel={<Text color={colors.grey60}>Current job start date</Text>}
              answerLabel={
                person.occupation_since_date ? (
                  <CopyableAnswer
                    testId="customer-profile-occupation-since-date"
                    value={dateFormatter(person.occupation_since_date)}
                  />
                ) : (
                  <EmptyAnswer />
                )
              }
            />
          </FlexBox>
          <FlexBox columnDirection gap={spacings.px20}>
            <Paragraph bold>Address</Paragraph>
            <QuestionAnswerPair
              questionLabel={<Text color={colors.grey60}>Living address</Text>}
              answerLabel={
                person.living_address?.full ? (
                  <CopyableAnswer testId="customer-profile-living-address" value={person.living_address.full} fsMask />
                ) : (
                  <EmptyAnswer />
                )
              }
            />
            <QuestionAnswerPair
              questionLabel={<Text color={colors.grey60}>Mailing address</Text>}
              answerLabel={
                person.mailing_address?.full ? (
                  <CopyableAnswer
                    testId="customer-profile-mailing-address"
                    value={person.mailing_address.full}
                    fsMask
                  />
                ) : (
                  <EmptyAnswer />
                )
              }
            />
          </FlexBox>
          <FlexBox columnDirection gap={spacings.px20}>
            <Paragraph bold>Driver info</Paragraph>
            <QuestionAnswerPair
              questionLabel={<Text color={colors.grey60}>License status</Text>}
              answerLabel={
                person.license_status ? (
                  <>
                    <CopyableAnswer testId="customer-profile-license-status" fsMask value={person.license_status} />
                  </>
                ) : (
                  <EmptyAnswer />
                )
              }
            />
            <QuestionAnswerPair
              questionLabel={<Text color={colors.grey60}>License number</Text>}
              answerLabel={
                person.license_number ? (
                  <>
                    <CopyableAnswer testId="customer-profile-license-number" fsMask value={person.license_number} />
                  </>
                ) : (
                  <EmptyAnswer />
                )
              }
            />
            <QuestionAnswerPair
              questionLabel={<Text color={colors.grey60}>License state</Text>}
              answerLabel={
                person.license_state ? (
                  <CopyableAnswer
                    testId="customer-profile-license-state"
                    value={Translations.usaState(person.license_state)}
                  />
                ) : (
                  <EmptyAnswer />
                )
              }
            />

            <QuestionAnswerPair
              questionLabel={<Text color={colors.grey60}>Age first licensed</Text>}
              answerLabel={
                person.age_first_licensed ? (
                  <>
                    <CopyableAnswer
                      testId="customer-profile-age-first-licensed"
                      value={person.age_first_licensed.toString()}
                    />
                  </>
                ) : (
                  <EmptyAnswer />
                )
              }
            />
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </>
  );
};

export default CustomerProfileView;
