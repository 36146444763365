import featureFlags from '../../../constants/featureFlags';
import { ICurrentDisposition, IOpportunity } from '../../../interfaces';
import DeprecatedFinalLookForm from './DeprecatedFinalLookForm';
import ReviewForm from './ReviewForm';

const FinalLook = ({
  opportunities,
  currentDisposition,
  isDataEditingForbidden
}: {
  isDataEditingForbidden: boolean;
  opportunities: IOpportunity[];
  currentDisposition: ICurrentDisposition | undefined;
}) => {
  return (
    <>
      {featureFlags.enablePolicyDocumentsUI ? (
        <ReviewForm
          opportunities={opportunities}
          currentDisposition={currentDisposition}
          isDataEditingForbidden={isDataEditingForbidden}
        />
      ) : (
        <DeprecatedFinalLookForm
          opportunities={opportunities}
          currentDisposition={currentDisposition}
          isDataEditingForbidden={isDataEditingForbidden}
        />
      )}
    </>
  );
};

export default FinalLook;
