/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';

import Button, { ButtonSize, ButtonVariant } from '../../../../../components/core/buttons/Button';
import { AttachmentIcon, UploadIcon } from '../../../../../components/core/icons';
import Paragraph from '../../../../../components/core/Paragraph';
import { FileToConfirm, InvalidFile } from '../../../../../interfaces/IDocument';
import colors from '../../../../../theme/colors';
import { borderRadius, spacings } from '../../../../../theme/variables';
import { formatFileName } from '../../../../../utils/formatter';

interface IDropzone {
  onUpload: (files: FileToConfirm[]) => void;
  onReject: (invalidFiles: InvalidFile[]) => void;
  uploadOneAtTheTime?: boolean;
  inline?: boolean;
  small?: boolean;
  disabled?: boolean;
}

const dropzoneStyle = css`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 5px;
  transition: opacity 0.3s;
  width: 100%;
  background: ${colors.white};
  border: 1px dashed ${colors.grey60};
  border-radius: ${borderRadius}px;
`;

const acceptedFileTypes = {
  'image/jpeg': [],
  'image/jpg': [],
  'image/png': [],
  'text/csv': [],
  'text/plain': [],
  'text/xml': [],
  'audio/mpeg': [],
  'video/mp4': [],
  'application/pdf': [],
  'application/msword': [],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
  'application/vnd.ms-excel': [],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
  'application/xml': []
};
const MAX_FILE_SIZE = 30 * 1024 * 1000; // 30 MB

const maxSizeValidator = (file: File) => {
  if (file.size > MAX_FILE_SIZE) {
    return {
      code: 'file-too-large',
      message: `File ${file.name} is larger than 30 MB.`
    };
  }
  return null;
};

// TODO: this has to be "core" dropzone
const DropzoneDeprecated = ({
  onUpload,
  onReject,
  uploadOneAtTheTime,
  inline,
  small,
  disabled = false
}: IDropzone): JSX.Element => {
  const onDrop = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    const filesToUpload = acceptedFiles.map(file => ({
      filesize: file.size,
      uid: crypto.randomUUID(),
      file,
      name: formatFileName(file.name)
    }));
    onUpload(filesToUpload);

    const filesToReject = fileRejections.map(({ errors, file }) => ({ file, error: errors[0]!.message }));
    onReject(filesToReject);
  };

  const { getRootProps, getInputProps } = useDropzone({
    useFsAccessApi: false,
    maxFiles: uploadOneAtTheTime ? 1 : undefined,
    validator: maxSizeValidator,
    onDrop,
    accept: acceptedFileTypes,
    disabled
  });

  return (
    <div
      {...getRootProps()}
      css={
        inline
          ? ''
          : [
              dropzoneStyle,
              css`
                height: ${small ? '133px' : '200px'};
              `
            ]
      }
    >
      {inline ? (
        <Paragraph
          color={colors.azure50}
          bold
          customCss={css`
            display: inline-block;
            &:hover {
              cursor: pointer;
              opacity: 0.8;
            }
          `}
        >
          <input {...getInputProps()} data-testid="button-input" className="dz-hidden-input" />
          <AttachmentIcon
            color={colors.azure50}
            css={css`
              margin-right: 10px;
            `}
          />
          Upload files
        </Paragraph>
      ) : (
        <div
          css={css`
            text-align: center;
          `}
        >
          <Paragraph bold>Drag and drop your files here</Paragraph>
          <Paragraph bold mb={spacings.px8}>
            or
          </Paragraph>
          <input {...getInputProps()} data-testid="button-input" className="dz-hidden-input" />
          <Button variant={ButtonVariant.Secondary} size={ButtonSize.Small} type="button" disabled={disabled}>
            <UploadIcon /> Upload a File
          </Button>
        </div>
      )}
    </div>
  );
};

export default DropzoneDeprecated;
