/** @jsxImportSource @emotion/react */
import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useLocation, useNavigate } from 'react-router-dom';

import FlexBox from '../../../components/core/FlexBox';
import BaseForm from '../../../components/core/forms/BaseForm';
import buildUIFlow from '../../../components/core/forms/uiFlowBuilder';
import Heading from '../../../components/core/Heading';
import Paragraph from '../../../components/core/Paragraph';
import Text from '../../../components/core/Text';
import featureFlags from '../../../constants/featureFlags';
import { useGuidedSellingExperienceContext } from '../../../contexts/GuidedSellingExperienceContext';
import { DataCollectionStepsKey, IDataCollection, IDataCollectionPageType } from '../../../interfaces/IDataCollection';
import { isExtendedHomeInfoAvailable } from '../../../interfaces/IHome';
import { NoteKind } from '../../../interfaces/INote';
import { useUpdateDataCollection } from '../../../queries/leads/data_collection/useDataCollection';
import useCreatePersonNote from '../../../queries/people/useCreatePersonNote';
import {
  HOME_UNDERWRITING_FLOW_SIDEBAR_FORM,
  HOME_UNDERWRITING_FLOW_SIDEBAR_FORM_V3,
  usePersonAssetsUIFlows
} from '../../../queries/workflows/useUiFlow';
import colors from '../../../theme/colors';
import { sizes, spacings } from '../../../theme/variables';
import { GuidedSellingPathnames } from '../navigation';
import HomeEligibility from '../tabs/CollectedInfoTab/Homes/HomeEligibility';
import Notes from '../tabs/NotesTab/Notes';

const CREATE_NOTE_TEXTAREA_ID = 'create-note-textarea';

const HandOffPage = ({ dataCollection }: { dataCollection: IDataCollection }) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { personGid, lead } = useGuidedSellingExperienceContext();

  const homeOpportunities =
    dataCollection.pages.find(p => p.page_type === IDataCollectionPageType.HomeProfile)?.opportunities || [];

  const assetsGids = homeOpportunities.map(op => op.assets?.[0]?.gid).filter(Boolean);

  const { data: homeEligibilityUIFlows, isPending: isPendingHomeEligibilityFlows } = usePersonAssetsUIFlows({
    uiFlowKey: featureFlags.homeEligibilityPropertyUnderMaintenance
      ? HOME_UNDERWRITING_FLOW_SIDEBAR_FORM_V3
      : HOME_UNDERWRITING_FLOW_SIDEBAR_FORM,
    personGid,
    assetsGids
  });

  const { mutate: createNote } = useCreatePersonNote();
  const { mutate: updateDataCollection } = useUpdateDataCollection();

  return (
    <BaseForm
      pb={spacings.px24}
      pt={spacings.px24}
      pl={spacings.px24}
      pr={spacings.px24}
      controlsAlignment="right"
      type="fullPage"
      controlsWidth={sizes.mediumFormInputWidth}
      initialValues={{}}
      submitText="Finalize!"
      submitTestId="hand-off-submit-button"
      onSubmit={() => {
        const trailingNoteValue = (document.getElementById(CREATE_NOTE_TEXTAREA_ID) as HTMLTextAreaElement | null)
          ?.value;

        if (trailingNoteValue) {
          createNote({
            kind: NoteKind.Memo,
            description: trailingNoteValue,
            person_gid: personGid!
          });
        }

        updateDataCollection({
          leadId: lead!.id,
          last_completed_step: DataCollectionStepsKey.QuotingConfiguration
        });

        return navigate(`${GuidedSellingPathnames.ISRFinish}${search}`);
      }}
      renderForm={() => {
        return (
          <FlexBox columnDirection gap={spacings.px12} maxWidth={840}>
            <Heading>Ready to hand off</Heading>
            {isPendingHomeEligibilityFlows && (
              <>
                <Skeleton count={8} height={40} baseColor={colors.grey10} highlightColor={colors.grey5} width="100%" />
              </>
            )}
            {!isPendingHomeEligibilityFlows && (
              <>
                {homeOpportunities.map((homeOpportunity, index) => {
                  const home = homeOpportunity.assets?.[0];
                  const homeEligibilityUIFlow = buildUIFlow({
                    uiFlowResponse: homeEligibilityUIFlows?.[index]?.data?.ui_flow
                  });

                  return home && isExtendedHomeInfoAvailable(home) && homeEligibilityUIFlow ? (
                    <React.Fragment key={home.gid}>
                      <Paragraph bold type="small">
                        {home.description_without_icon}
                      </Paragraph>
                      <HomeEligibility
                        homeOpportunity={homeOpportunity}
                        homeEligibilityUIFlow={homeEligibilityUIFlow}
                        isEditModeAvailable={false}
                        onEdit={() => null}
                      />
                    </React.Fragment>
                  ) : null;
                })}

                <Paragraph bold type="small">
                  Any additional concerns or observations <Text type="small">(this will appear in the notes):</Text>
                </Paragraph>
                <Notes textareaId={CREATE_NOTE_TEXTAREA_ID} />
              </>
            )}
          </FlexBox>
        );
      }}
    />
  );
};

export default HandOffPage;
