import React from 'react';
import { Navigate, Route, Routes, useMatch, useNavigate, useParams } from 'react-router-dom';

import Container from '../../components/core/Container';
import FlexBox from '../../components/core/FlexBox';
import AdminPolicyList from '../../components/PolicyList/AdminPolicyList';
import featureFlags from '../../constants/featureFlags';
import useTrackOpeningLead from '../../hooks/useTrackOpeningLead';
import { LeadTypes } from '../../interfaces/ISourceDimensions';
import { UserRoleCategory } from '../../interfaces/IUser';
import useLead from '../../queries/leads/useLead';
import analytics from '../../services/analytics';
import authInfo from '../../services/authInfo';
import colors from '../../theme/colors';
import { sizes, spacings } from '../../theme/variables';
import NotesView from '../NotesTab';
import ScheduleCall from '../ScheduleCall';
import LeadActivity from './LeadActivity';
import LeadAssets from './LeadAssets';
import LeadDocuments from './LeadDocuments';
import LeadHeader from './LeadHeader';
import LeadLoans from './LeadLoans';
import LeadNavigation from './LeadNavigation';
import PoliciesContainer from './LeadPolicies';
import LeadPriorPolicies from './LeadPriorPolicies';
import LeadSummary from './LeadSummary';
import LeadTasks from './LeadTasks';
import PersonOnlineSessions from './PersonOnlineSessions';
import Quotes from './Quotes';

const Lead = () => {
  const { id } = useParams();
  const { data: lead } = useLead(id);

  const navigate = useNavigate();

  useTrackOpeningLead({ leadGid: lead?.gid, leadId: lead?.id });

  const isMaxWidthApplied = !useMatch('/leads/:leadId/admin_policies/*');

  if (!lead) {
    return null;
  }

  if (
    authInfo.roleCategory !== UserRoleCategory.Admin &&
    lead.source_dimensions.lead_type === LeadTypes.UnknownEngagement
  ) {
    return <Navigate to="/leads" replace />;
  }

  return (
    <Container fitParentWidth>
      <Container mb={spacings.px24} backgroundColor={colors.white} borderBottom>
        <FlexBox columnDirection alignItemsCenter maxWidth={sizes.containerMaxWidth} autoMargin>
          <LeadHeader personGid={lead.person_gid} leadGid={lead.gid} leadId={lead.id} />
          <LeadNavigation personGid={lead.person_gid} leadGid={lead.gid} />
        </FlexBox>
      </Container>

      <FlexBox
        columnDirection
        alignItemsCenter
        maxWidth={isMaxWidthApplied ? sizes.containerMaxWidth : undefined}
        autoMargin
      >
        <Routes>
          <Route
            path="summary"
            element={<LeadSummary personGid={lead.person_gid} leadGid={lead.gid} leadId={lead.id} />}
          />
          <Route
            path="person_online_sessions"
            element={<PersonOnlineSessions personGid={lead.person_gid} leadGid={lead.gid} />}
          />
          {!featureFlags.hideLeadTasksTab && (
            <Route
              path="tasks"
              element={<LeadTasks personGid={lead.person_gid} leadGid={lead.gid} leadId={lead.id} />}
            />
          )}
          {!featureFlags.hideLeadNotesTab && (
            <Route path="notes" element={<NotesView personGid={lead.person_gid} leadGid={lead.gid} />} />
          )}
          {!featureFlags.hideLeadLoansTab && (
            <Route path="loans" element={<LeadLoans personGid={lead.person_gid} leadId={lead.id} />} />
          )}
          <Route path="prior_policies" element={<LeadPriorPolicies personGid={lead.person_gid} leadId={lead.id} />} />
          {!featureFlags.hideLeadAssetsTab && (
            <Route
              path="assets"
              element={<LeadAssets personGid={lead.person_gid} leadGid={lead.gid} leadId={lead.id} />}
            />
          )}
          <Route
            path="policies/*"
            element={<PoliciesContainer personGid={lead.person_gid} leadGid={lead.gid} leadId={lead.id} />}
          />
          {authInfo.features.extended_policy_list_view && (
            <Route path="admin_policies/*" element={<AdminPolicyList personGid={lead.person_gid} />} />
          )}
          {!featureFlags.hideLeadDocumentsTab && (
            <Route path="files" element={<LeadDocuments personGid={lead.person_gid} leadId={lead.id} />} />
          )}
          {!featureFlags.hideLeadQuotesTab && (
            <Route
              path="quotes"
              element={
                <Quotes
                  personGid={lead.person_gid}
                  leadGid={lead.gid}
                  leadId={lead.id}
                  onGetQuotes={() => {
                    analytics.track('Get quotes clicked', {
                      lead_gid: lead.gid,
                      person_gid: lead.person_gid,
                      place: 'old_lead_profile_ui'
                    });
                    return navigate(`/people/${lead.person_gid}/quotes/request?lead_id=${lead.id}`);
                  }}
                />
              }
            />
          )}
          <Route path="activity" element={<LeadActivity leadId={lead.id} />} />
          <Route
            path="schedule_call"
            element={
              <ScheduleCall
                personGid={lead.person_gid}
                leadGid={lead.gid}
                candidateGid={undefined}
                cancelHandler={() => navigate(-1)}
                afterSubmitHandler={() => navigate(-1)}
              />
            }
          />
          <Route path="*" element={<Navigate to="summary" replace />} />
        </Routes>
      </FlexBox>
    </Container>
  );
};

export default Lead;
