import { Field } from 'formik';
import React from 'react';
import { Col, Container, InputGroup, InputGroupAddon, Label, Row } from 'reactstrap';
import * as yup from 'yup';

import { Translations } from '../../constants';
import {
  ADDRESS,
  ADDRESS_CITY,
  ADDRESS_LINE1,
  ADDRESS_LINE2,
  ADDRESS_STATE,
  ADDRESS_ZIP,
  API_TO_SMARTY_STREETS_MAPPING
} from '../../constants/addressForm';
import { IAddressSuggestion, IPerson, IPersonAddress } from '../../interfaces';
import { createSmartyStreetsMapping, renameKeys } from '../../utils/object';
import { combine, maxDate, minDate, requiredField, yearField, zipField } from '../../utils/yupRules';
import { CopyField, ErrorCol, FormModal } from '../common';
import SmartyStreetsAddress from '../common/Address/Address';
import { DatePicker, Input, MoneyInput, NumberInput, Select } from '../common/FormikComponents';
import PersonNavLink from '../PersonNavLink';

const BLANK = '';

interface IHomeEditor {
  open: boolean;
  home: any;
  cancelBtnHandler: () => void;
  confirmBtnHandler: (values: any) => void;
  person: IPerson;
  customerAddresses?: IPersonAddress[];
}
const HomeEditor = ({
  open = false,
  home,
  cancelBtnHandler,
  confirmBtnHandler,
  person,
  customerAddresses
}: IHomeEditor) => {
  const selectedHome = home || {};
  const defaultAddresses = customerAddresses?.map((address: any) =>
    renameKeys(API_TO_SMARTY_STREETS_MAPPING, address)
  ) as IAddressSuggestion[] | undefined;

  const defaultIfNull = (value: null) => (value !== null ? value : BLANK);

  const onSubmit = ({
    [ADDRESS_LINE1]: line1,
    [ADDRESS_LINE2]: line2,
    [ADDRESS_STATE]: state,
    [ADDRESS_ZIP]: zip,
    [ADDRESS_CITY]: city,
    kind,
    car_count,
    ...otherParams
  }: any) => {
    const addressAttributes = { line1, line2, state, city, zip };

    const carCount = Number(car_count) || car_count;

    return confirmBtnHandler({
      ...otherParams,
      address_attributes: addressAttributes,
      garages: [{ garage_type: kind, car_count: carCount }]
    });
  };

  const generateAddress = (home: { address: object }) => {
    const address = (home.address || {}) as any;

    return {
      [ADDRESS_LINE1]: address.line1 || BLANK,
      [ADDRESS_LINE2]: address.line2 || BLANK,
      [ADDRESS_CITY]: address.city || BLANK,
      [ADDRESS_STATE]: address.state || BLANK,
      [ADDRESS_ZIP]: address.zip || BLANK
    };
  };

  return (
    <FormModal
      open={open}
      cancelHandler={cancelBtnHandler}
      confirmHandler={onSubmit}
      title={<PersonNavLink person={person} linkPrefix="HomeDetails." />}
      enableReinitialize
      initialValues={{
        ...generateAddress(selectedHome),
        id: selectedHome.id || BLANK,

        property_type: selectedHome.property_type || BLANK,
        usage: selectedHome.usage || BLANK,
        year_built: selectedHome.year_built || BLANK,
        square_feet: defaultIfNull(selectedHome.square_feet),
        owner_since: selectedHome.owner_since || BLANK,
        estimated_price: defaultIfNull(selectedHome.estimated_price),

        ownership: selectedHome.ownership || BLANK,

        construction: selectedHome.construction || BLANK,
        foundation: selectedHome.foundation || BLANK,
        stories: defaultIfNull(selectedHome.stories),
        kind: selectedHome.kind || BLANK,
        car_count: selectedHome.car_count || BLANK,
        exterior_walls: selectedHome.exterior_walls || BLANK,
        swimming_pool: selectedHome.swimming_pool || BLANK,
        bathrooms_full: defaultIfNull(selectedHome.bathrooms_full),
        bathrooms_half: defaultIfNull(selectedHome.bathrooms_half),
        bathroom_grade: selectedHome.bathroom_grade || BLANK,
        kitchen_grade: selectedHome.kitchen_grade || BLANK,

        roof_year: selectedHome.roof_year || BLANK,
        roof_material: selectedHome.roof_material || BLANK,
        roof_design: selectedHome.roof_design || BLANK,

        replacement_cost: selectedHome.replacement_cost || BLANK,
        units: defaultIfNull(selectedHome.units),
        total_rooms: defaultIfNull(selectedHome.total_rooms),
        bedrooms: defaultIfNull(selectedHome.bedrooms),
        heating: selectedHome.heating || BLANK,
        air_conditioning: selectedHome.air_conditioning || BLANK,
        sewer: selectedHome.sewer || BLANK,
        water: selectedHome.water || BLANK,
        quality: selectedHome.quality || BLANK,
        architectural_style: selectedHome.architectural_style || BLANK,
        condition: selectedHome.condition || BLANK
      }}
      validationSchema={yup.object().shape({
        [ADDRESS_LINE1]: requiredField,
        [ADDRESS_CITY]: requiredField,
        [ADDRESS_STATE]: requiredField,
        [ADDRESS_ZIP]: combine(requiredField, zipField),
        year_built: yearField,
        roof_year: yearField,
        owner_since: yup
          .date()
          .min(minDate, 'Only dates from 1800 are supported')
          .max(maxDate, "Date can't be greater than 2100"),
        square_feet: yup.number().min(1, 'Must be greater than or equal to 1'),
        total_rooms: yup.number().min(1, 'Must be greater than or equal to 1'),
        units: yup.number().min(1, 'Must be greater than or equal to 1'),
        bedrooms: yup.number().min(1, 'Must be greater than or equal to 1')
      })}
      renderForm={({ errors, values }) => (
        <Container className="mt-3">
          <Row>
            <Col sm={2}>Address</Col>
            <Col sm={10}>
              <SmartyStreetsAddress
                label="Home address"
                placeholder="Search for an address"
                // @ts-expect-error old code
                defaultValue={renameKeys(API_TO_SMARTY_STREETS_MAPPING, selectedHome.address || {})}
                defaultOptions={defaultAddresses}
                fallbackNames={createSmartyStreetsMapping(`${ADDRESS}_`)}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={2}>General Info</Col>
            <Col sm={10}>
              <Row>
                <Col sm={6}>
                  <Label>Property type</Label>
                  <Field
                    component={Select}
                    name="property_type"
                    options={Translations.propertyTypeOptions}
                    placeholder="Select"
                  />
                </Col>
                <ErrorCol id="year_built" sm={3} error={errors.year_built} when>
                  <Label>Year built</Label>
                  <CopyField value={values.year_built}>
                    <Field component={NumberInput} name="year_built" maxLength="4" intOnly noThousandsGroups />
                  </CopyField>
                </ErrorCol>
                <ErrorCol id="square_feet" error={errors.square_feet} when sm={3}>
                  <Label>Square feet</Label>
                  <CopyField value={values.square_feet}>
                    <Field component={NumberInput} name="square_feet" />
                  </CopyField>
                </ErrorCol>
              </Row>
              <Row>
                <ErrorCol sm={3} error={errors.owner_since} when>
                  <Label>Purchase date</Label>
                  <Field
                    component={DatePicker}
                    name="owner_since"
                    placeholder="MM/DD/YYYY"
                    maxDate={maxDate}
                    minDate={minDate}
                  />
                </ErrorCol>
                <Col sm={3}>
                  <Label>Est. price</Label>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                    <Field name="estimated_price" component={MoneyInput} withAddOn intOnly />
                  </InputGroup>
                </Col>
                <Col sm={6}>
                  <Label>Residency type</Label>
                  <Field component={Select} name="usage" options={Translations.homeUsageOptions} placeholder="Select" />
                </Col>
              </Row>
              <Row>
                <Col sm={3}>
                  <Label>Ownership</Label>
                  <Field
                    component={Select}
                    name="ownership"
                    options={Translations.homeOwnershipOptions}
                    placeholder="Select"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col sm={2}>Property Details</Col>
            <Col sm={10}>
              <Row>
                <Col sm={6}>
                  <Label>Construction type</Label>
                  <Field
                    component={Select}
                    name="construction"
                    options={Translations.constructionOptions}
                    placeholder="Select"
                  />
                </Col>
                <Col sm={4}>
                  <Label>Foundation</Label>
                  <Field
                    component={Select}
                    name="foundation"
                    options={Translations.foundationOptions}
                    placeholder="Select"
                  />
                </Col>
                <Col sm={2}>
                  <Label>Num stories</Label>
                  <Field component={Select} name="stories" options={Translations.storiesOptions} />
                </Col>
              </Row>
              <Row>
                <Col sm={4}>
                  <Label>Garage type</Label>
                  <Field component={Select} name="kind" options={Translations.garageTypeOptions} placeholder="Select" />
                </Col>
                <Col sm={2}>
                  <Label>Car count</Label>
                  <Field component={Input} name="car_count" placeholder="Select" />
                </Col>
                <Col sm={6}>
                  <Label>Exterior walls</Label>
                  <Field
                    component={Select}
                    name="exterior_walls"
                    options={Translations.exteriorWallsOptions}
                    placeholder="Select"
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <Label>Swimming pool</Label>
                  <Field
                    component={Select}
                    name="swimming_pool"
                    options={Translations.swimmingPoolOptions}
                    placeholder="Select"
                  />
                </Col>
                <Col sm={3}>
                  <Label>Full baths</Label>
                  <Field component={NumberInput} name="bathrooms_full" intOnly />
                </Col>
                <Col sm={3}>
                  <Label>Half baths</Label>
                  <Field component={NumberInput} name="bathrooms_half" intOnly />
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <Label>Bathroom grade</Label>
                  <Field
                    component={Select}
                    name="bathroom_grade"
                    options={Translations.bathroomGradeOptions}
                    placeholder="Select"
                  />
                </Col>
                <Col sm={6}>
                  <Label>Kitchen grade</Label>
                  <Field
                    component={Select}
                    name="kitchen_grade"
                    options={Translations.kitchenGradeOptions}
                    placeholder="Select"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col sm={2}>Roof Details</Col>
            <Col sm={10}>
              <Row>
                <ErrorCol id="roof_year" error={errors.roof_year} when>
                  <Label>Roof year</Label>
                  <Field component={NumberInput} name="roof_year" maxLength="4" intOnly noThousandsGroups />
                </ErrorCol>
                <Col sm={4}>
                  <Label>Roof material</Label>
                  <Field
                    component={Select}
                    name="roof_material"
                    options={Translations.roofMaterialOptions}
                    placeholder="Select"
                  />
                </Col>
                <Col sm={4}>
                  <Label>Roof design</Label>
                  <Field
                    component={Select}
                    name="roof_design"
                    options={Translations.roofDesignOptions}
                    placeholder="Select"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col sm={2}>Other Details</Col>
            <Col sm={10}>
              <Row>
                <Col sm={6}>
                  <Label>Heating</Label>
                  <Field component={Select} name="heating" options={Translations.heatingOptions} placeholder="Select" />
                </Col>
                <Col sm={6}>
                  <Label>Air Conditioning</Label>
                  <Field
                    component={Select}
                    name="air_conditioning"
                    options={Translations.airConditioningOptions}
                    placeholder="Select"
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <Label>Sewer</Label>
                  <Field component={Input} name="sewer" />
                </Col>
                <Col sm={6}>
                  <Label>Water</Label>
                  <Field component={Input} name="water" />
                </Col>
              </Row>
              <Row>
                <Col sm={3}>
                  <Label>Replacement Cost</Label>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                    <Field name="replacement_cost" component={MoneyInput} withAddOn intOnly />
                  </InputGroup>
                </Col>
                <ErrorCol id="total_rooms" error={errors.total_rooms} when sm={2}>
                  <Label>Total Rooms</Label>
                  <Field component={NumberInput} name="total_rooms" />
                </ErrorCol>
                <ErrorCol id="bedrooms" error={errors.bedrooms} when sm={2}>
                  <Label>Bedrooms</Label>
                  <Field component={NumberInput} name="bedrooms" />
                </ErrorCol>
                <Col sm={5}>
                  <Label>Architectural Style</Label>
                  <Field component={Input} name="architectural_style" />
                </Col>
              </Row>
              <Row>
                <ErrorCol id="units" error={errors.units} when sm={2}>
                  <Label>Num. Units</Label>
                  <Field component={NumberInput} name="units" />
                </ErrorCol>
                <Col sm={5}>
                  <Label>Quality</Label>
                  <Field component={Input} name="quality" />
                </Col>
                <Col sm={5}>
                  <Label>Condition</Label>
                  <Field component={Input} name="condition" />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      )}
    />
  );
};

export default HomeEditor;
