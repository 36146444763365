import { useQuery } from '@tanstack/react-query';

import { IPartner } from '../../interfaces';
import api from '../../services/api';

const PARTNERS_QUERY_KEY = 'partners';

export const PARTNERS_QUERY = {
  queryKey: [PARTNERS_QUERY_KEY],
  queryFn: (): Promise<IPartner[]> => api.get('/api/frontend/partners')
};

const usePartners = () => useQuery({ ...PARTNERS_QUERY, staleTime: Infinity });
export default usePartners;
