/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Button from '../../../components/core/buttons/Button';
import DropDown, { DropDownPosition } from '../../../components/core/buttons/DropDown';
import CancelTrailingScheduledCallModal from '../../../components/DispositionsModals/CancelTrailingScheduledCallModal';
// eslint-disable-next-line max-len
import NewTrailingOpportunitiesModal from '../../../components/DispositionsModals/TrailingOpportunitiesModal/NewTrailingOpportunitiesModal';
import featureFlags from '../../../constants/featureFlags';
import { DispositionType } from '../../../interfaces/IDisposition';
import { OpportunityStatus } from '../../../interfaces/IOpportunity';
import { ScheduledInteractionFlow } from '../../../interfaces/IScheduledCall';
import { UserRoleCategory } from '../../../interfaces/IUser';
import useLeadOpportunities from '../../../queries/leads/opportunities/useLeadOpportunities';
import { usePersonScheduledInteractions } from '../../../queries/scheduled_calls/useScheduledInteractions';
import authInfo, { isSuperISR } from '../../../services/authInfo';
import { findUncompletedCall } from '../../ScheduleCall/helpers';
import { useCurrentDispositionSubscriptionContext } from '../_hooks';
import { GuidedSellingPathnames } from '../navigation';

const FinalizeLead = ({ leadId, personGid }: { leadId: number; personGid: string }) => {
  const [trailingOpportunitiesModalOpened, setTrailingOpportunitiesModalOpened] = React.useState(false);
  const [cancelCallModalOpened, setCancelCallModalOpened] = React.useState(false);

  const navigate = useNavigate();
  const { search } = useLocation();

  const { data: opportunities, refetch: refetchOpportunities } = useLeadOpportunities({ leadId });
  const { data: scheduledCalls } = usePersonScheduledInteractions(personGid, [
    ScheduledInteractionFlow.Calendly,
    ScheduledInteractionFlow.CalendlyFollowUp,
    ScheduledInteractionFlow.ScheduledCall,
    ScheduledInteractionFlow.ScheduledCallIsrs,
    ScheduledInteractionFlow.ScheduledCallV2,
    ScheduledInteractionFlow.ScheduledCallEmail,
    ScheduledInteractionFlow.ScheduledByRecapture
  ]);
  const uncompletedCall = findUncompletedCall(scheduledCalls);

  const { currentDisposition, isUpdatingDisposition } = useCurrentDispositionSubscriptionContext();

  const isLoading = !opportunities || isUpdatingDisposition;
  const disableFinalizeDropdown =
    currentDisposition?.assignee?.id !== authInfo.currentUserId && authInfo.roleCategory === UserRoleCategory.Agent;
  const atLeastOneOpportunitySold = !!opportunities?.find(opp => opp.status === OpportunityStatus.Sold);
  const showFinalizeButton =
    currentDisposition &&
    (currentDisposition.disposition_type === DispositionType.Pipeline ||
      currentDisposition.disposition_type === DispositionType.Qualified);

  return (
    <>
      {showFinalizeButton && (
        <>
          {atLeastOneOpportunitySold ? (
            <Button
              data-testid="finalize-lead-button"
              customCss={css`
                min-width: 150px;
              `}
              onClick={() => navigate(`${GuidedSellingPathnames.Finalize}${search}`)}
            >
              Finalize
            </Button>
          ) : (
            <DropDown
              testId="finalize-lead-dropdown"
              options={[
                { label: 'At least one sold', value: DispositionType.PolicySold },
                !featureFlags.cstGuidedSellingExperience && { label: 'All lost', value: DispositionType.Lost }
              ].filter(Boolean)}
              disabled={disableFinalizeDropdown}
              loading={isLoading}
              onSelected={selected => {
                if (selected.value === DispositionType.PolicySold) {
                  return navigate(`${GuidedSellingPathnames.Finalize}${search}`);
                } else {
                  setTrailingOpportunitiesModalOpened(true);
                }
              }}
              customButtonCss={css`
                min-width: 150px;
              `}
              position={DropDownPosition.Top}
            >
              Finalize
            </DropDown>
          )}
        </>
      )}
      {trailingOpportunitiesModalOpened && opportunities && (
        <NewTrailingOpportunitiesModal
          leadId={leadId}
          opportunities={opportunities}
          dispositionType={DispositionType.Lost}
          closeModal={() => {
            setTrailingOpportunitiesModalOpened(false);
          }}
          onDispositionUpdated={() => {
            refetchOpportunities().then(() => {
              uncompletedCall && setCancelCallModalOpened(true);

              if (featureFlags.displayDisqualifyButton) {
                navigate(
                  `${isSuperISR() ? GuidedSellingPathnames.ISRFinish : GuidedSellingPathnames.AgentFinish}${search}`
                );
              } else {
                navigate(`${GuidedSellingPathnames.Finalize}${search}`);
              }
            });
          }}
        />
      )}
      {uncompletedCall && cancelCallModalOpened && (
        <CancelTrailingScheduledCallModal
          personGid={personGid}
          scheduledCall={uncompletedCall}
          assignee={currentDisposition?.assignee}
          onCloseModal={() => setCancelCallModalOpened(false)}
        />
      )}
    </>
  );
};

export default FinalizeLead;
