/** @jsxImportSource @emotion/react */
import { css, Global } from '@emotion/react';
import * as React from 'react';
import Carousel from 'react-multi-carousel';
import { useNavigate } from 'react-router-dom';

import Container from '../../../components/core/Container';
import Copy from '../../../components/core/Copy';
import DottedTag from '../../../components/core/DottedTag';
import FlexBox from '../../../components/core/FlexBox';
import BaseForm from '../../../components/core/forms/BaseForm';
import { CheckCircleIcon, ClockIcon, CopyIcon, ErrorIcon, UserIcon } from '../../../components/core/icons';
import Checkbox from '../../../components/core/inputs/Checkbox';
import Paragraph from '../../../components/core/Paragraph';
import PolicyTypeIcon from '../../../components/core/PolicyTypeIcon';
import Tag from '../../../components/core/Tag';
import Text from '../../../components/core/Text';
import { useGuidedSellingExperienceContext } from '../../../contexts/GuidedSellingExperienceContext';
import { DispositionType } from '../../../interfaces/IDisposition';
import { IOpportunity, OpportunityStatus } from '../../../interfaces/IOpportunity';
import { PolicyType } from '../../../interfaces/IPolicyType';
import { generateAutoDescription, IVehicle } from '../../../interfaces/IVehicle';
import useLeadOpportunities from '../../../queries/leads/opportunities/useLeadOpportunities';
import usePersonTasks from '../../../queries/people/person_tasks/usePersonTasks';
import useUserAvatarUrl from '../../../queries/users/useUserAvatarUrl';
import colors from '../../../theme/colors';
import { spacings } from '../../../theme/variables';
import { capitalize, dateTimeFormatter } from '../../../utils/formatter';
import { useCurrentDispositionSubscriptionContext } from '../../GuidedSellingExperience/_hooks';
import { ButtonGroup } from '../../GuidedSellingExperience/tabs/PoliciesTab/_components/CarouselColumns';

const OpportunityStatusTag = ({ opp }: { opp: IOpportunity }) => {
  if (!opp.is_data_collection_enabled) {
    return <DottedTag text="Skipped" color={colors.statusOrange} />;
  }
  if (opp.status === OpportunityStatus.Sold) {
    return <DottedTag text="Sold" color={colors.statusGreen} />;
  }
  return <DottedTag text="Lost" color={colors.statusRed} />;
};

const NewFinalizedSalesPage = () => {
  const { person, lead } = useGuidedSellingExperienceContext();
  const { currentDisposition } = useCurrentDispositionSubscriptionContext();
  const { data: opportunities } = useLeadOpportunities({ leadId: lead!.id });
  const { data: tasks } = usePersonTasks(person!.gid);
  const { data: avatarUrl } = useUserAvatarUrl(currentDisposition?.author?.id);
  const navigate = useNavigate();

  return (
    <BaseForm
      controlsAlignment="right"
      controlsWidth={320}
      pb={spacings.px24}
      pl={spacings.px24}
      pr={spacings.px24}
      pt={spacings.px24}
      type="fullPage"
      submitText="Got it! Go to sales dashboard"
      initialValues={{}}
      onSubmit={() => {
        return navigate('/leads');
      }}
      renderForm={() => {
        return (
          <FlexBox columnDirection gap={spacings.px24}>
            <FlexBox columnDirection gap={spacings.px12}>
              <Paragraph bold>Lead info</Paragraph>
              <FlexBox
                pv={spacings.px6}
                alignItemsCenter
                justifySpaceBetween
                border
                roundBorder
                p={spacings.px12}
                css={css`
                  width: 864px;
                `}
              >
                <FlexBox gap={spacings.px12} alignItemsCenter>
                  <Container border roundBorder p={spacings.px4}>
                    <Container roundBorder p={spacings.px8} backgroundColor={colors.grey5}>
                      <UserIcon height={24} width={24} />
                    </Container>
                  </Container>
                  <Text type="small" className="fs-mask">
                    {person?.name}
                  </Text>
                </FlexBox>
                <FlexBox gap={spacings.px12} alignItemsCenter>
                  {currentDisposition?.disposition_type === DispositionType.PolicySold ? (
                    <FlexBox alignItemsCenter gap={spacings.px4}>
                      <CheckCircleIcon color={colors.statusGreen} />
                      <Text type="small">Lead sold</Text>
                    </FlexBox>
                  ) : (
                    <FlexBox alignItemsCenter gap={spacings.px4}>
                      <ErrorIcon color={colors.statusRed} />
                      <Text type="small">Lead lost</Text>
                    </FlexBox>
                  )}
                </FlexBox>

                {lead && (
                  <Copy value={lead.id.toString()} withIcon={false}>
                    <FlexBox alignItemsCenter gap={spacings.px4} p={spacings.px4}>
                      <Text bold type="small">
                        Lead id: {lead.id}
                      </Text>
                      <CopyIcon />
                    </FlexBox>
                  </Copy>
                )}
              </FlexBox>
            </FlexBox>

            {opportunities && (
              <FlexBox columnDirection gap={spacings.px12}>
                <Paragraph bold>Lead`s opportunities({opportunities.length})</Paragraph>
                <Global
                  styles={() => css`
                    .carousel-item-padding {
                      padding: ${spacings.px8}px;
                    }
                  `}
                />
                <div
                  css={css`
                    position: relative;
                    width: 865px;
                  `}
                >
                  <Carousel
                    responsive={{
                      desktop: {
                        breakpoint: { max: 5000, min: 800 },
                        items: 4,
                        partialVisibilityGutter: 0
                      }
                    }}
                    arrows={false}
                    renderButtonGroupOutside={true}
                    customButtonGroup={<ButtonGroup />}
                    swipeable
                    draggable={false}
                    showDots={false}
                    centerMode={false}
                    ssr={false}
                    infinite={false}
                    autoPlay={false}
                    itemClass="carousel-item-padding"
                  >
                    {opportunities.map(opp => {
                      return (
                        <FlexBox
                          backgroundColor={opp.status === OpportunityStatus.Sold ? colors.white : colors.grey5}
                          key={opp.id}
                          border
                          roundBorder
                          p={spacings.px8}
                          columnDirection
                          css={css`
                            min-width: 200px;
                            max-width: 200px;
                          `}
                          gap={spacings.px12}
                        >
                          <FlexBox alignItemsFlexStart justifySpaceBetween>
                            <Container
                              border
                              roundBorder
                              p={spacings.px4}
                              css={css`
                                width: fit-content;
                              `}
                            >
                              <Container roundBorder p={spacings.px8} backgroundColor={colors.grey5}>
                                <PolicyTypeIcon policyType={opp.policy_type} height={24} width={24} />
                              </Container>
                            </Container>
                            {opp.primary && (
                              <Tag
                                label="Primary opportunity"
                                transparent
                                backgroundColor={colors.violet}
                                textColor={colors.violet}
                                border
                                textType="tiny"
                              />
                            )}
                          </FlexBox>
                          <FlexBox
                            columnDirection
                            css={css`
                              height: 40px;
                            `}
                          >
                            <Text type="small" bold>
                              {opp.title}
                            </Text>
                            <div
                              title={
                                opp.policy_type === PolicyType.Auto
                                  ? generateAutoDescription(opp.assets as IVehicle[])?.toString()
                                  : opp.assets?.[0]?.address.full
                              }
                            >
                              <Text type="small" singleLine className="fs-mask">
                                {opp.policy_type === PolicyType.Auto
                                  ? generateAutoDescription(opp.assets as IVehicle[])
                                  : opp.assets?.[0]?.address.full}
                              </Text>
                            </div>
                          </FlexBox>
                          <FlexBox gap={spacings.px8} alignItemsCenter justifySpaceBetween>
                            {avatarUrl ? (
                              <img
                                src={avatarUrl}
                                alt="avatar"
                                css={css`
                                  border-radius: ${spacings.px24}px;
                                  width: 24px;
                                  height: 24px;
                                `}
                              />
                            ) : (
                              <FlexBox
                                justifyCenter
                                alignItemsCenter
                                customCss={css`
                                  width: 24px;
                                  height: 24px;
                                `}
                              >
                                <UserIcon width={spacings.px24} height={spacings.px24} color={colors.white} />
                              </FlexBox>
                            )}
                            <Container>
                              <OpportunityStatusTag opp={opp} />
                            </Container>
                          </FlexBox>
                        </FlexBox>
                      );
                    })}
                  </Carousel>
                </div>
              </FlexBox>
            )}
            {tasks && tasks.length > 0 && (
              <FlexBox columnDirection gap={spacings.px12}>
                <Paragraph bold>Your tasks</Paragraph>
                <FlexBox columnDirection gap={spacings.px8}>
                  {tasks.map(task => {
                    return (
                      <FlexBox
                        key={task.id}
                        pv={spacings.px6}
                        alignItemsCenter
                        justifySpaceBetween
                        border
                        roundBorder
                        p={spacings.px8}
                        css={css`
                          min-width: 864px;
                          max-width: 864px;
                        `}
                        gap={spacings.px12}
                      >
                        <FlexBox gap={spacings.px8} alignItemsCenter>
                          <Checkbox
                            label=""
                            id={`${task.id}`}
                            name={`${task.id}-checkbox`}
                            checked={task.completed}
                            disabled
                          />
                          <Text
                            singleLine
                            type="small"
                            customCss={css`
                              width: 330px;
                            `}
                            title={task.description}
                            className="fs-mask"
                          >
                            {task.description}
                          </Text>
                          <Tag
                            label={capitalize(task.kind)}
                            transparent
                            backgroundColor={colors.violet}
                            textColor={colors.violet}
                            border
                            textType="tiny"
                          />
                        </FlexBox>
                        <FlexBox gap={spacings.px12} alignItemsCenter>
                          <Text singleLine type="small">
                            For {task.assignee.name}
                          </Text>
                        </FlexBox>

                        <FlexBox alignItemsCenter gap={spacings.px4}>
                          <ClockIcon />
                          <Text singleLine type="small">
                            due {dateTimeFormatter(task.due_date)}
                          </Text>
                        </FlexBox>
                      </FlexBox>
                    );
                  })}
                </FlexBox>
              </FlexBox>
            )}
          </FlexBox>
        );
      }}
    />
  );
};

export default NewFinalizedSalesPage;
