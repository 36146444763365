/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';
import ReactTooltip from 'react-tooltip';

import Tooltip from '../../components/common/Tooltip/NewTooltip';
import IconButton, { ButtonIcons } from '../../components/core/buttons/IconButton';
import Container from '../../components/core/Container';
import Copy from '../../components/core/Copy';
import FlexBox from '../../components/core/FlexBox';
import Text from '../../components/core/Text';
import { useGuidedSellingExperienceContext } from '../../contexts/GuidedSellingExperienceContext';
import { IQuote, IVehicle } from '../../interfaces';
import { isPersonAgedToDrive } from '../../interfaces/IPerson';
import { coverageByKey, PolicyCoverageKey } from '../../interfaces/IPolicyCoverage';
import { QuoteSource } from '../../interfaces/IQuote';
import { buildVehicleTitle } from '../../interfaces/IVehicle';
import usePersonRelatedPeople from '../../queries/people/usePersonRelatedPeople';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import { moneyFormatter } from '../../utils/formatter';
import { EDIT_DISABLED_TOOLTIP_ID } from './_helpers';

const coverageTextCss = css`
  &[data-content='Not included'] {
    color: ${colors.statusOrange};
  }
`;

const AutoQuoteInfo = ({
  setOpenQuoteEditor,
  quote,
  opportunityAssets,
  disabled
}: {
  setOpenQuoteEditor: React.Dispatch<React.SetStateAction<boolean>>;
  quote: IQuote;
  opportunityAssets: IVehicle[];
  disabled?: boolean;
}) => {
  const { personGid, person } = useGuidedSellingExperienceContext();
  const { data: relatedPeople } = usePersonRelatedPeople(personGid);
  const filteredRelatedPeople = relatedPeople?.filter(isPersonAgedToDrive) || [];
  const possiblyInsuredDrivers = [person, ...filteredRelatedPeople];

  const carrierName = quote.attributes.carrier.name || '—';
  const premium = moneyFormatter(quote.attributes.premium, true) || '—';
  const term = quote.attributes.payment_options?.[0]?.term_months === 6 ? 6 : 12;
  const firstMonth = moneyFormatter(quote.attributes.payment_options?.[0]?.downpayment, true) || '—';
  const subsequentMonthlyPayments =
    moneyFormatter(quote.attributes.payment_options?.[0]?.subsequent_payment, true) || '—';
  const propertyDamage =
    coverageByKey(quote.attributes.coverages, PolicyCoverageKey.PropertyDamage)?.limit_description || '—';

  const bILiabilityPerPerson =
    coverageByKey(quote.attributes.coverages, PolicyCoverageKey.BiPerPerson)?.limit_description || '—';
  const bILiabilityPerAccident =
    coverageByKey(quote.attributes.coverages, PolicyCoverageKey.BiPerAccident)?.limit_description || '—';

  const uninsuredMotoristPropertyDamage =
    coverageByKey(quote.attributes.coverages, PolicyCoverageKey.UninsuredMpd)?.limit_description || '—';

  const umbiPerPerson =
    coverageByKey(quote.attributes.coverages, PolicyCoverageKey.UninsuredMbiPerPerson)?.limit_description || '—';
  const umbiPerAccident =
    coverageByKey(quote.attributes.coverages, PolicyCoverageKey.UninsuredMbiPerAccident)?.limit_description || '—';

  const medPay = coverageByKey(quote.attributes.coverages, PolicyCoverageKey.MedicalPayments)?.limit_description || '';
  const pip =
    coverageByKey(quote.attributes.coverages, PolicyCoverageKey.PersonalInjuryProtection)?.limit_description || '';

  const insuredDrivers =
    quote.attributes.coinsureds_gids
      ?.concat(personGid!)
      ?.map(gid => possiblyInsuredDrivers.find(driver => driver?.gid === gid)?.name)
      .join(', ') || '—';
  const bindingUrl = quote.attributes.binding_url || '—';

  const quoteEditDisabled = disabled || ![QuoteSource.AU, QuoteSource.CO].includes(quote.source);

  React.useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <Container
      border
      borderColor={colors.grey10}
      p={spacings.px12}
      customCss={css`
        border-radius: ${spacings.px8}px;
        max-width: 400px;
      `}
    >
      <FlexBox columnDirection gap={spacings.px8}>
        <FlexBox justifySpaceBetween>
          <Text bold>Your proposal</Text>
          <IconButton
            data-for={EDIT_DISABLED_TOOLTIP_ID}
            data-tip={quoteEditDisabled ? 'Quote editing is disabled' : ''}
            disabled={quoteEditDisabled}
            icon={ButtonIcons.Edit}
            color={colors.black}
            onClick={() => {
              setOpenQuoteEditor(true);
            }}
          />
        </FlexBox>
        <FlexBox gap={spacings.px12} columnDirection>
          <FlexBox justifySpaceBetween>
            <Text>Carrier</Text>
            <Text>{carrierName}</Text>
          </FlexBox>
          <FlexBox justifySpaceBetween>
            <Text>Bridge URL</Text>
            <Copy value={bindingUrl}>
              <Text
                customCss={css`
                  max-width: 160px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                `}
              >
                {bindingUrl}
              </Text>
            </Copy>
          </FlexBox>
          <FlexBox justifySpaceBetween>
            <Text>Premium({term === 6 ? '6 months' : '1 yr'})</Text>
            <Text>{premium}</Text>
          </FlexBox>
          <FlexBox justifySpaceBetween>
            <Text>First month (Downpayment)</Text>
            <Text>{firstMonth}</Text>
          </FlexBox>
          <FlexBox justifySpaceBetween>
            <Text>Subsequent monthly payments</Text>
            <Text>{subsequentMonthlyPayments}</Text>
          </FlexBox>
          <Text bold mv={spacings.px4}>
            General automobile coverages
          </Text>
          <FlexBox justifySpaceBetween>
            <Text>Bodily injury liability</Text>
            <FlexBox
              columnDirection
              customCss={css`
                text-align: right;
              `}
            >
              <Text>Each person {bILiabilityPerPerson}</Text>
              <Text>Each accident {bILiabilityPerAccident}</Text>
            </FlexBox>
          </FlexBox>
          <FlexBox justifySpaceBetween>
            <Text>Property damage</Text>
            <Text>Each accident {propertyDamage}</Text>
          </FlexBox>
          <FlexBox justifySpaceBetween>
            <Text>
              <Text underline dotted data-for="umbi-tip" data-tip="Uninsured/Underinsured motorist">
                UM/UIM
              </Text>{' '}
              <Tooltip id="umbi-tip" />
              injury liability
            </Text>
            <FlexBox
              columnDirection
              customCss={css`
                text-align: right;
              `}
            >
              <Text>Each person {umbiPerPerson}</Text>
              <Text>Each accident {umbiPerAccident}</Text>
            </FlexBox>
          </FlexBox>
          <FlexBox justifySpaceBetween>
            <Text>
              <Text underline dotted data-for="umbi-tip" data-tip="Uninsured/Underinsured motorist">
                UM/UIM
              </Text>{' '}
              property damage
            </Text>
            <Text>Each accident {uninsuredMotoristPropertyDamage}</Text>
          </FlexBox>
          {medPay && (
            <FlexBox justifySpaceBetween>
              <Text>Medical payments</Text>
              <Text>{medPay}</Text>
            </FlexBox>
          )}
          {pip && (
            <FlexBox justifySpaceBetween>
              <Text>Personal injury protection</Text>
              <Text>{pip}</Text>
            </FlexBox>
          )}
          <FlexBox justifySpaceBetween>
            <Text>Insured drivers</Text>
            <Text>{insuredDrivers}</Text>
          </FlexBox>
          {opportunityAssets.map(vehicle => {
            const getCoverageValue = (coverageKey: PolicyCoverageKey, type: 'limit' | 'deductible') =>
              quote?.attributes.coverages?.find(
                coverage => coverage.key === coverageKey && coverage.asset_gid === vehicle.gid
              )?.[`${type}_description`] || '—';

            return (
              <React.Fragment key={vehicle.gid}>
                <Text bold mv={spacings.px4}>
                  {buildVehicleTitle(vehicle)}
                </Text>
                <FlexBox justifySpaceBetween>
                  <Text>Collision deductible</Text>
                  <Text>{getCoverageValue(PolicyCoverageKey.Collision, 'deductible')}</Text>
                </FlexBox>
                <FlexBox justifySpaceBetween>
                  <Text>Comprehensive deductible</Text>
                  <Text>{getCoverageValue(PolicyCoverageKey.Comprehensive, 'deductible') || '—'}</Text>
                </FlexBox>
                <FlexBox justifySpaceBetween>
                  <Text>Roadside assistance coverage</Text>
                  <Text customCss={coverageTextCss} data-content={getCoverageValue(PolicyCoverageKey.Towing, 'limit')}>
                    {getCoverageValue(PolicyCoverageKey.Towing, 'limit') || '—'}
                  </Text>
                </FlexBox>
                <FlexBox justifySpaceBetween>
                  <Text>Full glass</Text>
                  <Text
                    customCss={coverageTextCss}
                    data-content={getCoverageValue(PolicyCoverageKey.FullGlass, 'limit')}
                  >
                    {getCoverageValue(PolicyCoverageKey.FullGlass, 'limit') || '—'}
                  </Text>
                </FlexBox>
                <FlexBox justifySpaceBetween>
                  <Text>Rental car reimbursement</Text>
                  <Text
                    customCss={coverageTextCss}
                    data-content={getCoverageValue(PolicyCoverageKey.RentalMaxAmount, 'limit')}
                  >
                    {getCoverageValue(PolicyCoverageKey.RentalMaxAmount, 'limit')}
                  </Text>
                </FlexBox>
              </React.Fragment>
            );
          })}
        </FlexBox>
      </FlexBox>
    </Container>
  );
};
export default AutoQuoteInfo;
