/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import FlexBox from '../../../components/core/FlexBox';
import { ClockIcon } from '../../../components/core/icons';
import Paragraph from '../../../components/core/Paragraph';
import { IPerson, presentPersonPhone } from '../../../interfaces/IPerson';
import usePersonTimezone from '../../../queries/people/usePersonTimezone';
import colors from '../../../theme/colors';
import { spacings } from '../../../theme/variables';
import { calculateTimeWithOffset } from '../../../utils/formatter';

const Time = ({ person }: { person: IPerson }) => {
  const { data } = usePersonTimezone({
    personGid: person.gid,
    phone: presentPersonPhone(person)
  });
  const offset = data?.utc_offset_hours;
  const [time, setTime] = React.useState('');

  React.useEffect(() => {
    offset && setTime(calculateTimeWithOffset(offset));
    const timer = setInterval(() => {
      offset && setTime(calculateTimeWithOffset(offset));
    }, 1000 * 60);

    return () => {
      clearInterval(timer);
    };
  }, [offset]);

  return offset ? (
    <FlexBox
      alignItemsCenter
      gap={spacings.px4}
      customCss={css`
        white-space: nowrap;
      `}
    >
      <ClockIcon />
      <Paragraph color={colors.grey80}>{time}</Paragraph>
      <Paragraph color={colors.grey80}>{data.abbreviation || data.display_name || data.name}</Paragraph>
    </FlexBox>
  ) : null;
};

export default Time;
