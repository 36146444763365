/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import { DropDownMenu } from '../../../../../components/common';
import Container from '../../../../../components/core/Container';
import FlexBox from '../../../../../components/core/FlexBox';
import Tag from '../../../../../components/core/Tag';
import Text from '../../../../../components/core/Text';
import DeleteDocumentModal from '../../../../../components/FileModals/DeleteModal';
import EditDocumentModal from '../../../../../components/FileModals/EditModal';
import { useToggle } from '../../../../../hooks';
import { IDocument, IMaticPolicy, IOpportunity } from '../../../../../interfaces';
import { documentFileDownloadUrl, documentFilePreviewUrl } from '../../../../../interfaces/IDocument';
import { UpdateDocumentRequest } from '../../../../../queries/people/person_documents/usePersonDocuments';
import colors from '../../../../../theme/colors';
import { spacings } from '../../../../../theme/variables';
import { getOpportunityLabel, getPolicyLabel } from '../helpers';

const Document = ({
  document,
  onDelete,
  onUpdate,
  owners,
  opportunities,
  policies,
  disabled = false
}: {
  document: IDocument;
  onDelete: (document: IDocument) => Promise<unknown>;
  onUpdate: (documentId: number, request: UpdateDocumentRequest) => Promise<unknown>;
  owners: { key: string; value: string; description: string | string[] | undefined }[];
  opportunities: IOpportunity[] | undefined;
  policies: IMaticPolicy[] | undefined;
  disabled?: boolean;
}) => {
  const [editDocument, toggleEditDocument] = useToggle(false);
  const [deleteDocument, toggleDeleteDocument] = useToggle(false);
  const opportunityLabel = getOpportunityLabel({
    ownerType: document.owner_type,
    ownerId: document.owner_id,
    opportunities
  });
  const policyLabel = getPolicyLabel({ ownerType: document.owner_type, ownerId: document.owner_id, policies });

  const documentTypeTitle = document.document_type_title?.toString();

  return (
    <Container mv={spacings.px4}>
      <Text bold>{policyLabel}</Text>
      <FlexBox pv={spacings.px4} alignItemsCenter justifySpaceBetween>
        <FlexBox
          css={css`
            width: 90%;
          `}
        >
          <Tag
            label={documentTypeTitle || 'Unknown'}
            backgroundColor={documentTypeTitle ? colors.violet : colors.grey60}
          />
          <Text className="fs-mask" singleLine pl={spacings.px8} title={document.name}>
            <a
              href={documentFilePreviewUrl(document)}
              target="_blank"
              rel="noreferrer"
              css={css`
                color: ${colors.black};
              `}
            >
              {document.name}
            </a>
          </Text>
        </FlexBox>

        <DropDownMenu
          ml={spacings.px4}
          items={[
            {
              label: <Text>Edit</Text>,
              action: toggleEditDocument,
              disabled
            },
            {
              label: (
                <a
                  href={documentFileDownloadUrl(document)}
                  target="_blank"
                  rel="noreferrer"
                  css={css`
                    width: 100%;
                  `}
                >
                  <Text>Download</Text>
                </a>
              ),
              action: () => {},
              disabled
            },
            {
              label: <Text color={colors.statusRed}>Delete</Text>,
              action: toggleDeleteDocument,
              disabled
            }
          ]}
          wrapperTestId="document-actions"
        />
      </FlexBox>
      {editDocument && (
        <EditDocumentModal
          document={document}
          toggleEditDocument={toggleEditDocument}
          onUpdate={onUpdate}
          owners={owners}
          opportunities={opportunities}
          policies={policies}
        />
      )}
      {deleteDocument && (
        <DeleteDocumentModal
          document={document}
          onDelete={onDelete}
          toggleDeleteDocument={toggleDeleteDocument}
          opportunityLabel={opportunityLabel}
          policyLabel={policyLabel}
        />
      )}
    </Container>
  );
};

export default Document;
