/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import Container from '../../../components/core/Container';
import FlexBox from '../../../components/core/FlexBox';
import Paragraph from '../../../components/core/Paragraph';
import Tag from '../../../components/core/Tag';
import { IPerson } from '../../../interfaces';
import useBrand from '../../../queries/partners/useBrand';
import usePersonMaticPolicies from '../../../queries/people/person_policies/usePersonMaticPolicies';
import usePersonLeads from '../../../queries/people/usePersonLeads';
import colors from '../../../theme/colors';
import { spacings } from '../../../theme/variables';
import ActionButtons from './ActionButtons';
import LastPolicy from './LastPolicy';
import RecentLeads from './RecentLeads';
import Time from './Time';

const Header = ({ person }: { person: IPerson }) => {
  const { data: leads } = usePersonLeads(person.gid);
  const { data: policies } = usePersonMaticPolicies({
    personGid: person.gid
  });

  const lastLead = leads?.[0];
  const brandKey = lastLead?.source_dimensions?.brand;
  const { brand } = useBrand(brandKey);
  const lastPolicy = policies?.[0];

  return (
    <Container
      backgroundColor={colors.grey3}
      fitParentWidth
      borderBottom
      borderColor={colors.grey10}
      pv={spacings.px16}
      ph={spacings.px24}
    >
      <FlexBox
        alignItemsCenter
        justifySpaceBetween
        gap={spacings.px8}
        customCss={css`
          flex-wrap: wrap;
        `}
      >
        <FlexBox
          alignItemsCenter
          gap={spacings.px8}
          customCss={css`
            flex-wrap: wrap;
          `}
        >
          <Paragraph
            type="large"
            bold
            className="fs-mask"
            singleLine
            customCss={css`
              max-width: 320px;
            `}
          >
            {person.name}
          </Paragraph>
          {lastLead && <RecentLeads leads={leads.slice(0, 5)} />}
          <FlexBox gap={spacings.px8}>
            <Time person={person} />
            {brandKey === 'unknown' || !brand ? null : (
              <FlexBox justifyCenter columnDirection>
                <Tag testId="person-engagement-brand" label={brand.name} backgroundColor={colors.violet} />
              </FlexBox>
            )}
            <Tag label="Matic customer" backgroundColor={colors.cherise50} />
          </FlexBox>
        </FlexBox>
        <FlexBox gap={spacings.px8}>
          <ActionButtons person={person} />
        </FlexBox>
        {lastPolicy && <LastPolicy policy={lastPolicy} />}
      </FlexBox>
    </Container>
  );
};

export default Header;
