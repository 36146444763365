/** @jsxImportSource @emotion/react */
import { css, Global } from '@emotion/react';
import * as React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Carousel from 'react-multi-carousel';

import Container from '../../../../components/core/Container';
import FlexBox from '../../../../components/core/FlexBox';
import PolicyTypeIcon from '../../../../components/core/PolicyTypeIcon';
import Text from '../../../../components/core/Text';
import { IDocument, IMaticPolicy } from '../../../../interfaces';
import { findPolicyType, PolicyType } from '../../../../interfaces/IPolicyType';
import { generateAutoDescription, IVehicle } from '../../../../interfaces/IVehicle';
import { DocumentType } from '../../../../queries/document_types/useDocumentTypes';
import usePersonDocuments from '../../../../queries/people/person_documents/usePersonDocuments';
import { usePolicyDeliveries } from '../../../../queries/people/person_policies/usePolicyDeliveries';
import colors from '../../../../theme/colors';
import { spacings } from '../../../../theme/variables';
import { IExtendedDocument } from '../../../PostSalesExperience/FinalLook/CollectedDocuments';
import { IChannel } from '../../../PostSalesExperience/FinalLook/Document';
import MiniDocument from '../../../PostSalesExperience/FinalLook/MiniDocument';
import { getCurrentWidth } from '../../_components/VerticalTabs/helpers';
import { ButtonGroup } from '../PoliciesTab/_components/CarouselColumns';

const PolicyDocuments = ({
  personGid,
  policy,
  docTypes,
  setExpandedDocument,
  setExpandedDocumentChannels
}: {
  personGid: string;
  policy: IMaticPolicy;
  docTypes: DocumentType[];
  setExpandedDocument: (doc: IDocument | null) => void;
  setExpandedDocumentChannels: (channels: IChannel[] | null) => void;
}) => {
  const currentWidth = getCurrentWidth();
  const [items, setItems] = React.useState(currentWidth / 232); // 232 is the width of the MiniDocument + margins
  const { data: deliveries } = usePolicyDeliveries({ personGid, policyId: policy.id });
  const { data: documents } = usePersonDocuments({ personGid, policyId: policy.id });

  const deliveryDocuments = Object.entries(
    (deliveries || [])
      .flatMap(item =>
        item.deliverable_documents.map(doc => ({
          ...doc,
          channel: item.channel,
          recipient_type: item.recipient_type,
          delivery_status: item.status,
          updated_at: item.updated_at,
          last_send_try_at: item.last_send_try_at
        }))
      )
      .reduce(
        (groups, doc) => {
          (groups[doc.document_type] ||= []).push(doc);
          return groups;
        },
        {} as Record<string, IExtendedDocument[]>
      )
  ).map(([document_type, docs]) => ({
    document_type,
    document: (docs as IExtendedDocument[]).find(doc => doc.document)?.document || null,
    channels: (docs as IExtendedDocument[]).map(
      ({ channel, status, recipient_type, delivery_status, updated_at, last_send_try_at }) => ({
        channel,
        document_status: status,
        recipient_type,
        delivery_status,
        sent_at: updated_at,
        last_send_try_at
      })
    )
  }));

  if (!(deliveries && documents && docTypes)) {
    return (
      <SkeletonTheme baseColor={colors.grey10} highlightColor={colors.grey5}>
        <FlexBox columnDirection gap={spacings.px16} mt={spacings.px12}>
          {[1, 2, 3].map(value => (
            <Container key={value} fitParentWidth>
              <Skeleton height={66} />
            </Container>
          ))}
        </FlexBox>
      </SkeletonTheme>
    );
  }

  const otherDocuments = documents.filter(doc =>
    deliveryDocuments.every(deliveryDoc => deliveryDoc.document?.id !== doc.id)
  );

  if (deliveryDocuments.length === 0 && otherDocuments.length === 0) {
    return null;
  }

  const showArrows = items < deliveryDocuments.length + otherDocuments.length;

  return (
    <FlexBox columnDirection>
      <FlexBox gap={spacings.px8}>
        <PolicyTypeIcon policyType={policy.policy_type} height={20} width={20} />
        <FlexBox columnDirection>
          <Text bold>
            {findPolicyType(policy.policy_type)?.name}{' '}
            <Text
              ph={spacings.px4}
              color={colors.grey60}
              type="small"
              customCss={css`
                border-radius: 4px;
                border: 1px solid ${colors.grey10};
                background-color: ${colors.grey5};
                line-height: 1.5;
              `}
            >
              {documents.length}
            </Text>
          </Text>
          <Text type="small" singleLine className="fs-mask">
            {policy.policy_type === PolicyType.Auto
              ? generateAutoDescription(policy.assets as IVehicle[])
              : policy.assets?.[0]?.address.full}
          </Text>
          <Text type="small" singleLine className="fs-mask">
            Policy #{policy.policy_number}
          </Text>
        </FlexBox>
      </FlexBox>

      <Global
        styles={() => css`
          .carousel-item-padding {
            padding: ${spacings.px8}px;
          }
        `}
      />
      <div
        css={css`
          position: relative;
        `}
      >
        <Carousel
          responsive={{
            desktop: {
              breakpoint: { max: 5000, min: 100 },
              items,
              partialVisibilityGutter: 0,
              slidesToSlide: items
            }
          }}
          afterChange={() => setItems(getCurrentWidth() / 230)}
          arrows={false}
          renderButtonGroupOutside
          customButtonGroup={showArrows ? <ButtonGroup /> : null}
          swipeable={false}
          draggable={false}
          showDots={false}
          centerMode={false}
          ssr={false}
          infinite={false}
          autoPlay={false}
          itemClass="carousel-item-padding"
        >
          {deliveryDocuments.map(doc => (
            <MiniDocument
              key={doc.document_type}
              doc={doc.document}
              documentType={doc.document_type}
              channels={doc.channels}
              policy={policy}
              docTypes={docTypes}
              isDeliveryDocument
              onDocumentExpand={() => {
                setExpandedDocument(doc.document);
                setExpandedDocumentChannels(doc.channels);
              }}
            />
          ))}
          {otherDocuments.map(doc => (
            <MiniDocument
              key={doc.id}
              doc={doc}
              policy={policy}
              documentType={doc.document_type_key}
              docTypes={docTypes}
              onDocumentExpand={() => {
                setExpandedDocument(doc);
                setExpandedDocumentChannels(null);
              }}
            />
          ))}
        </Carousel>
      </div>
    </FlexBox>
  );
};

export default PolicyDocuments;
