/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import colors from './colors';

export enum InputSize {
  Small = 'smallFormInputWidth',
  Medium = 'mediumFormInputWidth',
  Large = 'largeFormInputWidth'
}

export enum LabelSize {
  Small = 'smallLabelSize',
  Medium = 'mediumLabelSize',
  Large = 'largeLabelSize'
}

export const borderRadius = 4;
export const secondaryInputMargin = 8;

export const sizes = {
  containerMaxWidth: 1334,

  inputPaddingHorizontal: 12,
  formsHeight: 36,

  largeFormInputWidth: 490,
  mediumFormInputWidth: 320,
  smallFormInputWidth: 200,

  largeLabelSize: 212,
  mediumLabelSize: 184,
  smallLabelSize: 122
} as const;

// BEAT_SPACING = 4;
export const spacings = {
  px0: 0,
  px4: 4,
  '-px4': -4,
  // Deprecated
  px6: 6,
  px8: 8,
  '-px8': -8,
  px12: 12,
  '-px12': -12,
  // Deprecated
  px16: 16,
  // Deprecated
  px20: 20,
  px24: 24,
  '-px24': -24,
  // Deprecated
  '-px20': -20,
  // Deprecated
  px28: 28,
  px32: 32,
  '-px32': -32,
  px40: 40,
  px48: 48
} as const;

export const spacingKeys = [
  'mt',
  'mb',
  'ml',
  'mr',
  'mv',
  'mh',
  'm',
  'pt',
  'pb',
  'pl',
  'pr',
  'pv',
  'ph',
  'p',
  'gap'
] as const;

type SpacingsKeys = (typeof spacingKeys)[number];
type SpacingsValues = (typeof spacings)[keyof typeof spacings];
export type Spacings = Partial<Record<SpacingsKeys, SpacingsValues>>;

const preparePixelSize = (size: SpacingsValues): string => `${size}px`;

const marginVerticalCss = (size: SpacingsValues) => css`
  margin-top: ${size}px;
  margin-bottom: ${size}px;
`;

const marginHorizontalCss = (size: SpacingsValues) => css`
  margin-left: ${size}px;
  margin-right: ${size}px;
`;

const marginCss = (size: SpacingsValues) => css`
  margin: ${size}px;
`;

const paddingVerticalCss = (size: SpacingsValues) => css`
  padding-top: ${size}px;
  padding-bottom: ${size}px;
`;

const paddingHorizontalCss = (size: SpacingsValues) => css`
  padding-left: ${size}px;
  padding-right: ${size}px;
`;

const paddingCss = (size: SpacingsValues) => css`
  padding: ${size}px;
`;

export const spacingsCss = ({ mt, mb, ml, mr, mv, mh, m, pt, pb, pl, pr, pv, ph, p, gap }: Spacings) => css`
  ${typeof mt === 'number' && `margin-top: ${preparePixelSize(mt)}`};
  ${typeof mb === 'number' && `margin-bottom: ${preparePixelSize(mb)}`};
  ${typeof ml === 'number' && `margin-left: ${preparePixelSize(ml)}`};
  ${typeof mr === 'number' && `margin-right: ${preparePixelSize(mr)}`};
  ${typeof mv === 'number' && marginVerticalCss(mv)};
  ${typeof mh === 'number' && marginHorizontalCss(mh)};
  ${typeof m === 'number' && marginCss(m)};

  ${typeof pt === 'number' && `padding-top: ${preparePixelSize(pt)}`};
  ${typeof pb === 'number' && `padding-bottom: ${preparePixelSize(pb)}`};
  ${typeof pl === 'number' && `padding-left: ${preparePixelSize(pl)}`};
  ${typeof pr === 'number' && `padding-right: ${preparePixelSize(pr)}`};
  ${typeof pv === 'number' && paddingVerticalCss(pv)};
  ${typeof ph === 'number' && paddingHorizontalCss(ph)};
  ${typeof p === 'number' && paddingCss(p)};

  ${typeof gap === 'number' && `gap: ${preparePixelSize(gap)}`};
`;

export interface BoxCss {
  border?: boolean;
  borderColor?: (typeof colors)[keyof typeof colors];
  borderWidth?: 1 | 2 | 3 | 4;
  roundBorder?: boolean;
  boxShadow?: boolean;
  boxHoverShadow?: boolean;
  backgroundColor?: (typeof colors)[keyof typeof colors];
  borderBottom?: boolean;
}

export const boxCssPropsKeys = [
  'border',
  'borderColor',
  'borderWidth',
  'roundBorder',
  'boxShadow',
  'boxHoverShadow',
  'backgroundColor',
  'borderBottom'
] as const;

export const boxCss = ({
  border,
  borderColor = colors.grey10,
  borderWidth = 1,
  roundBorder,
  boxShadow,
  boxHoverShadow,
  backgroundColor,
  borderBottom
}: BoxCss) => css`
  ${border && `border: ${borderWidth}px solid ${borderColor};`}
  ${roundBorder && 'border-radius: 8px;'}
    ${boxShadow && 'box-shadow: 2px 4px 0px rgba(0, 0, 0, 0.12);'}
    ${backgroundColor && `background-color: ${backgroundColor};`}
    ${borderBottom && `border-bottom: ${borderWidth}px solid ${borderColor};`}

    &:hover {
    ${boxHoverShadow && 'box-shadow: 2px 6px 7px 0px rgba(0, 0, 0, 0.12);'}
  }
  ${boxHoverShadow && 'transition: box-shadow 0.2s;'}
`;

export const containerSizeKeys = ['fitParentWidth', 'fitParentHeight'] as const;

type ContainerSizeKeys = (typeof containerSizeKeys)[number];
export type ContainerSize = Partial<Record<ContainerSizeKeys, boolean>>;

export const containerSizeCss = ({ fitParentWidth, fitParentHeight }: ContainerSize) => css`
  ${fitParentWidth && 'width: 100%;'}
  ${fitParentHeight && 'height: 100%;'}
`;
