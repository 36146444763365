import featureFlags from '../../../../../constants/featureFlags';
import { IGroupedQuotes, IQuote } from '../../../../../interfaces';
import { IAssetsQuotesGroup } from '../../../../../interfaces/IGroupedQuotes';
import { EligibilityResults, QuoteStatus } from '../../../../../interfaces/IQuote';
import { IQuotesRequest } from '../../../../../interfaces/IQuotesRequests';
import { SourceProducts } from '../../../../../interfaces/ISourceDimensions';
import { LicensingTypes } from '../../../../../interfaces/IUser';
import authInfo from '../../../../../services/authInfo';

export const findLatestCxQuotesRequest = (quotesRequests: IQuotesRequest[] | undefined) => {
  if (!quotesRequests?.length) {
    return undefined;
  }

  return [...quotesRequests].find(({ source_product }) => source_product !== SourceProducts.AgentManual);
};

export const isQuoteSelectedByCustomer = ({ attributes: { is_customer_choice } }: IQuote) => is_customer_choice;

export const customerChoicesFromQuotes = (groupedQuotes: IGroupedQuotes<IAssetsQuotesGroup> | undefined) =>
  groupedQuotes?.groups?.flatMap(({ quotes }) => quotes).filter(quote => isQuoteSelectedByCustomer(quote));

export const isQuoteRequiresDataFix = ({ status }: IQuote) => status === QuoteStatus.InvalidData;

export const isQuoteEligibleOrPartlyEligible = ({ status, attributes: { eligibility } }: IQuote) => {
  return (
    [QuoteStatus.Success, QuoteStatus.CompleteManually].includes(status) &&
    eligibility &&
    [EligibilityResults.Eligible, EligibilityResults.Uncertain].includes(eligibility.result)
  );
};

export const isQuoteNonEligible = ({ status, attributes: { eligibility } }: IQuote) => {
  if (status === QuoteStatus.DeclinedBusiness) {
    return true;
  }

  return (
    [QuoteStatus.Success, QuoteStatus.CompleteManually].includes(status) &&
    eligibility?.result === EligibilityResults.Ineligible
  );
};

export const isBridgingAvailable = ({ attributes: { appointed, carrier_non_admitted } }: IQuote) => {
  let nonAdmittedIsAllowed = true;
  if (featureFlags.nonAdmittedLicenseCheck) {
    nonAdmittedIsAllowed = carrier_non_admitted ? !!authInfo.licensingTypes?.includes(LicensingTypes.PCLicense) : true;
  }

  return appointed && nonAdmittedIsAllowed;
};

export const getBridgingUnavailableMessage = ({ attributes: { appointed, carrier_non_admitted } }: IQuote) => {
  let nonAdmittedIsAllowed = true;
  if (featureFlags.nonAdmittedLicenseCheck) {
    nonAdmittedIsAllowed = carrier_non_admitted ? !!authInfo.licensingTypes?.includes(LicensingTypes.PCLicense) : true;
  }

  if (appointed && nonAdmittedIsAllowed) {
    return '';
  }

  if (!appointed) {
    return 'No carrier appointment in the current state';
  }

  return 'This is an E&S quote and is not eligible to bind';
};
