/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FormikComputedProps, FormikHandlers, FormikHelpers, FormikState } from 'formik';
import * as React from 'react';

import Button from '../../../components/core/buttons/Button';
import Container from '../../../components/core/Container';
import FlexBox from '../../../components/core/FlexBox';
import FormLoader from '../../../components/core/FormLoader';
import buildUIFlow from '../../../components/core/forms/uiFlowBuilder';
import Heading from '../../../components/core/Heading';
import Text from '../../../components/core/Text';
import { isServiceLead } from '../../../components/DispositionsModals/dispositionsHelper';
import featureFlags from '../../../constants/featureFlags';
import { useGuidedSellingExperienceContext } from '../../../contexts/GuidedSellingExperienceContext';
import { IHome, IOpportunity } from '../../../interfaces';
import { useReconcileOpportunities } from '../../../queries/leads/data_collection/useDataCollectionOpportunities';
import { Qualification } from '../../../queries/people/usePersonAssetQualification';
import {
  HOME_UNDERWRITING_FLOW_MAIN_FORM,
  HOME_UNDERWRITING_FLOW_MAIN_FORM_V3,
  usePersonAssetsUIFlows,
  useSaveAnswers
} from '../../../queries/workflows/useUiFlow';
import colors from '../../../theme/colors';
import { spacings } from '../../../theme/variables';
import FinalizeLead from '../_components/FinalizeLead';
import { StepContentProps } from '../GuidedDataCollection';
import HomeEligibilityQuestions from '../GuidedDataCollection/_components/HomeEligibilityQuestions';
import LeadWarnings from './_components/LeadWarnings';

type HomeAssetOpportunityPair = readonly [IHome, IOpportunity];
type HomeAssetGid = string;
type FormRef = FormikHandlers & FormikState<unknown> & FormikComputedProps<unknown> & FormikHelpers<unknown>;

const HomeEligibilityQuestionsPage = ({
  onSubmit,
  dataCollection,
  page,
  isDataEditingForbidden,
  onLeadClosed
}: StepContentProps) => {
  const [qualificationStatus, setQualificationStatus] = React.useState<
    Record<HomeAssetGid, { isQualifying: boolean; qualification: Qualification | undefined }>
  >({});

  const { personGid, leadGid, lead } = useGuidedSellingExperienceContext();

  const homeAssetsAndOpportunities = (page.opportunities || []).reduce(
    (acc, opp) => {
      const previousObjCopy = { ...acc };

      opp.assets?.forEach(asset => {
        previousObjCopy[asset.gid] = [asset as IHome, opp];
      });

      return previousObjCopy;
    },
    {} as Record<HomeAssetGid, HomeAssetOpportunityPair>
  );

  const eligibilityQuestionsRefs = React.useRef<FormRef[]>([]);

  const { data: uiFlows, isFetchedAfterMount: isPersonAssetsFetched } = usePersonAssetsUIFlows({
    assetsGids: Object.keys(homeAssetsAndOpportunities),
    uiFlowKey: featureFlags.homeEligibilityPropertyUnderMaintenance
      ? HOME_UNDERWRITING_FLOW_MAIN_FORM_V3
      : HOME_UNDERWRITING_FLOW_MAIN_FORM,
    personGid
  });

  const { mutateAsync: saveAnswers, isPending: isSubmittingEligibilityQuestions } = useSaveAnswers();

  const { mutateAsync: reconcileOpportunities } = useReconcileOpportunities();

  const [isSubmittingPage, setIsSubmittingPage] = React.useState(false);

  if (!isPersonAssetsFetched) {
    return <FormLoader ph={spacings.px24} pv={spacings.px12} />;
  }

  const someEnabledAssetIsDisqualified = Object.keys(qualificationStatus).some(
    homeAssetGid =>
      homeAssetsAndOpportunities[homeAssetGid]?.[1]?.is_data_collection_enabled &&
      qualificationStatus[homeAssetGid]?.qualification?.result === 'disqualified'
  );
  const someAssetIsQualifying = Object.values(qualificationStatus).some(record => record.isQualifying);
  const isLoading = isSubmittingEligibilityQuestions || someAssetIsQualifying || isSubmittingPage;

  return (
    <Container
      fitParentHeight
      customCss={css`
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr auto;
      `}
    >
      <Container
        pt={spacings.px12}
        ph={spacings.px24}
        customCss={css`
          overflow: auto;
          padding-bottom: 60px;
        `}
      >
        <LeadWarnings dataCollection={dataCollection} />
        <Heading type="h4">Eligibility questions</Heading>
        <FlexBox columnDirection maxWidth={745}>
          {Object.keys(homeAssetsAndOpportunities).map((personHomeGid, index) => {
            const underwritingQuestionsUIFlow = buildUIFlow({
              uiFlowResponse: uiFlows?.[index]?.data?.ui_flow
            });

            if (!underwritingQuestionsUIFlow) {
              return null;
            }

            return (
              <HomeEligibilityQuestions
                key={personHomeGid}
                personHome={homeAssetsAndOpportunities[personHomeGid]![0]}
                underwritingQuestionsUIFlow={underwritingQuestionsUIFlow}
                openedByDefault={index === 0 ? true : false}
                personGid={personGid!}
                leadGid={leadGid!}
                leadId={lead!.id}
                saveAnswers={saveAnswers}
                opportunity={homeAssetsAndOpportunities[personHomeGid]![1]}
                setQualificationStatus={setQualificationStatus}
                isDataEditingForbidden={isDataEditingForbidden}
                isServiceLead={isServiceLead(dataCollection.current_disposition)}
                formRef={(element: any) => (eligibilityQuestionsRefs.current[index] = element)}
                onLeadClosed={onLeadClosed}
              />
            );
          })}
        </FlexBox>
      </Container>

      <FlexBox
        alignItemsCenter
        justifyRight
        ph={spacings.px24}
        pv={spacings.px24}
        gap={spacings.px12}
        customCss={css`
          border-top: 1px solid ${colors.grey10};
        `}
      >
        {lead && personGid && <FinalizeLead leadId={lead.id} personGid={personGid} />}
        {someEnabledAssetIsDisqualified && (
          <div>
            <Text type="small">Make sure all of the homes either qualified or disqualified!</Text>
          </div>
        )}
        <Button
          onClick={async () => {
            setIsSubmittingPage(true);
            const promises = (await Promise.all(
              eligibilityQuestionsRefs.current.map(ref => ref.submitForm())
            )) as Array<true | undefined>;

            if (promises.every(Boolean)) {
              await onSubmit();
              await reconcileOpportunities({ leadId: lead!.id, assetsGids: Object.keys(homeAssetsAndOpportunities) });
            }

            setIsSubmittingPage(false);
          }}
          type="submit"
          customCss={css`
            min-width: 320px;
          `}
          data-testid="submit-home-eligibility-button"
          disabled={someEnabledAssetIsDisqualified || isDataEditingForbidden}
          loading={isLoading}
        >
          Next
        </Button>
      </FlexBox>
    </Container>
  );
};

export default HomeEligibilityQuestionsPage;
