/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import Button from '../../components/core/buttons/Button';
import Container from '../../components/core/Container';
import FlexBox from '../../components/core/FlexBox';
import { isEndDisposition } from '../../components/DispositionsModals/dispositionsHelper';
import TablePlaceholder from '../../components/TablePlaceholder';
import { useGuidedSellingExperienceContext } from '../../contexts/GuidedSellingExperienceContext';
import { IOpportunity } from '../../interfaces';
import { DispositionType } from '../../interfaces/IDisposition';
import { OpportunityStatus } from '../../interfaces/IOpportunity';
import useDataCollection from '../../queries/leads/data_collection/useDataCollection';
import useUpdateLeadDisposition from '../../queries/leads/dispositions/useUpdateLeadDisposition';
import useLeadOpportunities, { updateOpportunity } from '../../queries/leads/opportunities/useLeadOpportunities';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import CustomerPolicies from '../Customer/CustomerPolicies';
import { useDataEditingForbidden } from '../GuidedSellingExperience/_hooks';
import FinalizedSalesPage from './ThankYouPage/FinalizedSalesPage';

const CSTPostSalesExperience = () => {
  const [isFinalized, setIsFinalized] = React.useState(false);
  const [isClosingLead, setIsClosingLead] = React.useState(false);

  const { person, lead } = useGuidedSellingExperienceContext();
  const leadId = lead?.id;

  const isDataEditingForbidden = useDataEditingForbidden();

  const { data: dataCollection, isPending: isPendingDataCollection } = useDataCollection(leadId);
  const { data: opportunities, isPending: isPendingOpportunities } = useLeadOpportunities({ leadId });
  const { mutateAsync: updateDisposition } = useUpdateLeadDisposition();

  if (isPendingDataCollection || isPendingOpportunities || !person || !dataCollection || !leadId || !opportunities) {
    return (
      <FlexBox p={spacings.px24}>
        <TablePlaceholder testId="cst-post-sales-loader" />
      </FlexBox>
    );
  }

  if (isFinalized) {
    return <FinalizedSalesPage dataCollection={dataCollection} showOpportunities={false} />;
  }

  return (
    <Container
      css={css`
        height: 100%;

        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr auto;
      `}
    >
      <Container p={spacings.px24}>
        <CustomerPolicies person={person} leadId={leadId} postSalesExperience />
      </Container>
      <FlexBox
        p={spacings.px24}
        justifyRight
        customCss={css`
          border-top: 1px solid ${colors.grey10};
        `}
      >
        <Button
          disabled={isDataEditingForbidden}
          loading={isClosingLead}
          onClick={async () => {
            setIsClosingLead(true);

            async function makeRequests(leadId: number, opportunities: IOpportunity[]) {
              for (const opportunity of opportunities) {
                await updateOpportunity({
                  leadId,
                  opportunityId: opportunity.id,
                  params: { status: OpportunityStatus.Sold, reason: opportunity.reason }
                });
              }
            }

            if (opportunities) {
              await makeRequests(
                leadId,
                opportunities.filter(opportunity => opportunity.is_opened)
              );
            }

            if (!isEndDisposition(dataCollection.current_disposition.disposition_type)) {
              await updateDisposition({ leadId, disposition_type: DispositionType.PolicySold });
            }

            setIsClosingLead(false);
            setIsFinalized(true);
          }}
        >
          Proceed
        </Button>
      </FlexBox>
    </Container>
  );
};

export default CSTPostSalesExperience;
