import { GuidedSellingExperienceContext } from '../../../contexts/GuidedSellingExperienceContext';
import { IPerson } from '../../../interfaces';
// eslint-disable-next-line max-len
import { CurrentDispositionSubscriptionContext } from '../../GuidedSellingExperience/_hooks/useCurrentDispositionSubscription';
import { QuotesModalViewContext } from '../../GuidedSellingExperience/GuidedQuoting/Quotes/_hooks/useModalView';
import QuotesRequests from '../../GuidedSellingExperience/GuidedQuoting/Quotes/QuotesRequests';

const Quotes = ({ person }: { person: IPerson }) => {
  return (
    <GuidedSellingExperienceContext.Provider value={{ isPersonLinkedToActiveCall: true } as any}>
      <CurrentDispositionSubscriptionContext.Provider value={{ currentDispositions: undefined } as any}>
        <QuotesModalViewContext.Provider value={{ quotesModalView: false }}>
          <QuotesRequests personGid={person.gid} leadGid={undefined} leadId={undefined} onGetQuotes={undefined} />
        </QuotesModalViewContext.Provider>
      </CurrentDispositionSubscriptionContext.Provider>
    </GuidedSellingExperienceContext.Provider>
  );
};

export default Quotes;
