/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import FlexBox from '../../../../components/core/FlexBox';
import Paragraph from '../../../../components/core/Paragraph';
import HorizontalTabs from '../../../../components/core/Tabs/HorizontalTabs';
import { InsurableInterest, Requirement } from '../../../../interfaces/IPolicyType';
import usePersonOpportunities from '../../../../queries/people/person_opportunities/usePersonOpportunities';
import usePersonRelatedPeople from '../../../../queries/people/usePersonRelatedPeople';
import VerticalTabContent from '../../_components/VerticalTabs/VerticalTabContent';
import VerticalTabHeader from '../../_components/VerticalTabs/VerticalTabHeader';
import Homes from './Homes';
import Vehicles from './Vehicles';

const AssetsTab = ({ personGid }: { personGid: string }) => {
  const { data: relatedPeople } = usePersonRelatedPeople(personGid);

  const { data: homeOpportunities } = usePersonOpportunities({
    personGid,
    insurableInterest: InsurableInterest.RealProperty,
    requirement: Requirement.Essential
  });
  const { data: vehicleOpportunities } = usePersonOpportunities({
    personGid,
    insurableInterest: InsurableInterest.Vehicle,
    requirement: Requirement.Essential
  });

  return (
    <FlexBox columnDirection fitParentHeight>
      <VerticalTabHeader>
        <FlexBox alignItemsCenter>
          <Paragraph type="large">Assets</Paragraph>
        </FlexBox>
      </VerticalTabHeader>

      <VerticalTabContent
        fitParentHeight
        customCss={css`
          overflow-y: auto;
          flex: 1;
        `}
      >
        {personGid && homeOpportunities && vehicleOpportunities && (
          <>
            <HorizontalTabs
              tabs={[
                {
                  tabName: 'Home',
                  content: (
                    <Homes
                      key="homes"
                      personGid={personGid}
                      engagementGid={personGid}
                      homeOpportunities={homeOpportunities}
                      isEditModeAvailable={true}
                      relatedPeople={relatedPeople}
                    />
                  )
                },
                {
                  tabName: 'Auto',
                  content: (
                    <Vehicles
                      key="autos"
                      vehicleOpportunities={vehicleOpportunities}
                      personGid={personGid}
                      isEditModeAvailable={true}
                    />
                  )
                }
              ]}
              stickyHeader
            />
          </>
        )}
      </VerticalTabContent>
    </FlexBox>
  );
};

export default AssetsTab;
