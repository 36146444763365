/** @jsxImportSource @emotion/react */
import { useQueryClient } from '@tanstack/react-query';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Tooltip from '../../../components/common/Tooltip/NewTooltip';
import Container from '../../../components/core/Container';
import FlexBox from '../../../components/core/FlexBox';
import FormLoader from '../../../components/core/FormLoader';
import BaseForm from '../../../components/core/forms/BaseForm';
import Heading from '../../../components/core/Heading';
import CancelTrailingScheduledCallModal from '../../../components/DispositionsModals/CancelTrailingScheduledCallModal';
import featureFlags from '../../../constants/featureFlags';
import { useGuidedSellingExperienceContext } from '../../../contexts/GuidedSellingExperienceContext';
import { IOpportunity } from '../../../interfaces';
import { DispositionType, ICurrentDisposition } from '../../../interfaces/IDisposition';
import { OpportunityStatus } from '../../../interfaces/IOpportunity';
import { PolicyPaymentMethod } from '../../../interfaces/IPolicy';
import { isNonLifeAncillaryPolicyType, originAssetTypeForPolicyType } from '../../../interfaces/IPolicyType';
import { ScheduledInteractionFlow } from '../../../interfaces/IScheduledCall';
import useUpdateLeadDisposition from '../../../queries/leads/dispositions/useUpdateLeadDisposition';
import { updateOpportunity } from '../../../queries/leads/opportunities/useLeadOpportunities';
import usePersonMaticPolicies from '../../../queries/people/person_policies/usePersonMaticPolicies';
import { useCreateOrUpdatePolicyDelivery } from '../../../queries/people/person_policies/usePolicyDeliveries';
import { PERSON_TASKS_QUERY_KEY } from '../../../queries/people/person_tasks/usePersonTasks';
import usePersonLoans from '../../../queries/people/usePersonLoans';
import { usePersonScheduledInteractions } from '../../../queries/scheduled_calls/useScheduledInteractions';
import analytics from '../../../services/analytics';
import { spacings } from '../../../theme/variables';
import { GuidedSellingPathnames } from '../../GuidedSellingExperience/navigation';
import { findUncompletedCall } from '../../ScheduleCall/helpers';
import PolicyDetails from './PolicyDetails';

const CLOSE_OPPORTUNITIES_TIP = 'You need to close opportunities to proceed.';
const MISSING_LOAN_TIP = 'Please add a loan.';
const MISSING_LOAN_NUMBER_TIP = 'Please add a loan number.';
const CONFIRM_TIP_ID = 'confirm-button';

const SubmitButtonWithTips = ({
  closeOpportunitiesTip,
  missingLoanTip,
  missingLoanNumberTip
}: {
  closeOpportunitiesTip: boolean;
  missingLoanTip: boolean;
  missingLoanNumberTip: boolean;
}) => {
  const tipText = (() => {
    switch (true) {
      case closeOpportunitiesTip:
        return CLOSE_OPPORTUNITIES_TIP;
      case missingLoanTip:
        return MISSING_LOAN_TIP;
      case missingLoanNumberTip:
        return MISSING_LOAN_NUMBER_TIP;
      default:
        return '';
    }
  })();

  return (
    <React.Fragment>
      <span data-for={CONFIRM_TIP_ID} data-tip={tipText}>
        Proceed
      </span>
      {tipText && <Tooltip id={CONFIRM_TIP_ID} />}
    </React.Fragment>
  );
};

const ReviewForm = ({
  opportunities,
  currentDisposition,
  isDataEditingForbidden
}: {
  isDataEditingForbidden: boolean;
  opportunities: IOpportunity[];
  currentDisposition: ICurrentDisposition | undefined;
}): JSX.Element => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { lead, person } = useGuidedSellingExperienceContext();
  const { data: policies = [], isFetchedAfterMount: isPoliciesFetched } = usePersonMaticPolicies({
    personGid: person!.gid,
    filters: { lead_id: lead!.id }
  });
  const { data: loans = [] } = usePersonLoans({ personGid: person!.gid });
  const { mutateAsync: updateDelivery } = useCreateOrUpdatePolicyDelivery();
  const [openCancelCallModal, setOpenCancelCallModal] = React.useState<boolean>(false);
  const { data: scheduledCalls, isFetchedAfterMount: isScheduledCallsFetched } = usePersonScheduledInteractions(
    person!.gid,
    [
      ScheduledInteractionFlow.Calendly,
      ScheduledInteractionFlow.CalendlyFollowUp,
      ScheduledInteractionFlow.ScheduledCall,
      ScheduledInteractionFlow.ScheduledCallIsrs,
      ScheduledInteractionFlow.ScheduledCallV2,
      ScheduledInteractionFlow.ScheduledCallEmail,
      ScheduledInteractionFlow.ScheduledByRecapture
    ]
  );
  const isDataLoaded = isPoliciesFetched && isScheduledCallsFetched && currentDisposition && lead && person && loans;

  const uncompletedCall = findUncompletedCall(scheduledCalls);

  const { mutateAsync: updateDisposition } = useUpdateLeadDisposition();

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const opportunitiesToConfirm = opportunities.filter(
    opportunity =>
      !isNonLifeAncillaryPolicyType(opportunity) && opportunity.is_opened && opportunity.is_data_collection_enabled
  );

  const opportunitiesToAutoclose = opportunities
    .filter(
      opportunity =>
        (isNonLifeAncillaryPolicyType(opportunity) && opportunity.is_opened) || !opportunity.is_data_collection_enabled
    )
    .map(opportunity => ({ ...opportunity, reason: opportunity.reason || 'not_interested' }));

  const sortedPolicies = policies.sort((a, b) => {
    const aIndex = opportunities.findIndex(opp => opp.policy_type === a.policy_type);
    const bIndex = opportunities.findIndex(opp => opp.policy_type === b.policy_type);

    if (aIndex < bIndex) {
      return -1;
    } else if (aIndex > bIndex) {
      return 1;
    } else {
      return 0;
    }
  });

  const escrowPolicies = sortedPolicies.filter(policy => policy.payment_method === PolicyPaymentMethod.Escrow);
  const directBillPolicies = sortedPolicies.filter(policy => policy.payment_method === PolicyPaymentMethod.DirectBill);

  const anyMissingLoanForEscrowPolicies = escrowPolicies.some(policy => {
    const assetType = originAssetTypeForPolicyType(policy.policy_type);

    if (!assetType) {
      return false;
    }

    return !loans.find(loan => loan[assetType]?.gid === policy.assets?.[0]?.gid);
  });

  const loansForEscrowPolicies = loans.filter(loan =>
    escrowPolicies.some(policy => {
      const assetType = originAssetTypeForPolicyType(policy.policy_type);

      if (!assetType) {
        return false;
      }

      return policy.assets?.[0]?.gid === loan[assetType]?.gid;
    })
  );

  const loansForDirectBillPolicies = loans.filter(loan =>
    directBillPolicies.some(policy => {
      const assetType = originAssetTypeForPolicyType(policy.policy_type);

      if (!assetType) {
        return false;
      }

      return policy.assets?.[0]?.gid === loan[assetType]?.gid;
    })
  );

  const anyMissingNumberForLoans =
    loansForDirectBillPolicies.some(loan => !loan.loan_number) ||
    loansForEscrowPolicies.some(loan => !loan.loan_number);

  const applyHardStopFeature =
    featureFlags.hardStopForMissingLoan && (anyMissingLoanForEscrowPolicies || anyMissingNumberForLoans);

  const disabledSubmit = !!opportunitiesToConfirm.length || isDataEditingForbidden || applyHardStopFeature;

  if (!isDataLoaded) {
    return (
      <Container p={spacings.px24}>
        <FormLoader />
      </Container>
    );
  }

  return (
    <BaseForm
      controlsAlignment="right"
      controlsWidth={320}
      pb={spacings.px24}
      pl={spacings.px24}
      pr={spacings.px24}
      pt={spacings.px24}
      type="fullPage"
      disabled={disabledSubmit}
      submitText={
        <SubmitButtonWithTips
          closeOpportunitiesTip={!!opportunitiesToConfirm.length}
          missingLoanTip={applyHardStopFeature && anyMissingLoanForEscrowPolicies}
          missingLoanNumberTip={applyHardStopFeature && anyMissingNumberForLoans}
        />
      }
      initialValues={{}}
      loading={isSubmitting}
      onSubmit={async () => {
        setIsSubmitting(true);

        currentDisposition.disposition_type !== DispositionType.PolicySold &&
          (await Promise.all(
            opportunitiesToAutoclose.map(opp =>
              updateOpportunity({
                leadId: lead.id,
                opportunityId: opp.id,
                params: { status: OpportunityStatus.Lost, reason: opp.reason || 'not_interested' }
              })
            )
          )
            .then(() => {
              return updateDisposition({
                leadId: lead.id,
                disposition_type: sortedPolicies.length ? DispositionType.PolicySold : DispositionType.Lost
              });
            })
            .then(() => {
              policies.forEach(policy => {
                updateDelivery({ personGid: person.gid, policyId: policy.id });
              });
            })
            .catch(() => {
              setIsSubmitting(false);
            }));
        analytics.track('Final look confirm button clicked');

        if (uncompletedCall) {
          setOpenCancelCallModal(true);
        } else {
          setIsSubmitting(false);
          navigate(`${GuidedSellingPathnames.AgentFinish}${search}`);
        }

        return queryClient.invalidateQueries({ queryKey: [PERSON_TASKS_QUERY_KEY, person.gid] });
      }}
      renderForm={() => (
        <FlexBox columnDirection gap={spacings.px24}>
          <Heading>Review</Heading>

          <FlexBox columnDirection gap={spacings.px40}>
            {sortedPolicies.map(policy => (
              <PolicyDetails policy={policy} key={policy.policy_number} />
            ))}
          </FlexBox>

          {openCancelCallModal && uncompletedCall && (
            <CancelTrailingScheduledCallModal
              assignee={currentDisposition.assignee}
              personGid={person.gid}
              scheduledCall={uncompletedCall}
              onCloseModal={() => {
                setOpenCancelCallModal(false);

                setIsSubmitting(false);
                navigate(`${GuidedSellingPathnames.AgentFinish}${search}`);
              }}
            />
          )}
        </FlexBox>
      )}
    />
  );
};

export default ReviewForm;
