/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  Row,
  TableOptions,
  useReactTable
} from '@tanstack/react-table';
import * as React from 'react';

import colors from '../../../theme/colors';

/*
  React Table relies on memoization to determine when state and side effects should update or be calculated.
  This means that every option you pass to useTable should be memoized either via
  React.useMemo (for objects) or React.useCallback (for functions).
*/
interface TableProps<T> {
  columns: any[];
  data: T[];
  testId: string;
  withBorder?: boolean;
  withRowBorders?: boolean;
  headerBackgroundColor?: string;
  renderSubComponent?: (props: { row: Row<T> }) => React.ReactElement;
  tableOptions?: Omit<TableOptions<T>, 'columns' | 'data' | 'getCoreRowModel' | 'getExpandedRowModel'>;
}

const Table = <T extends Record<string, any>>({
  columns,
  data,
  testId,
  renderSubComponent,
  withBorder = true,
  withRowBorders = false,
  headerBackgroundColor = colors.white,
  tableOptions
}: TableProps<T>) => {
  const { getHeaderGroups, getRowModel } = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    ...tableOptions
  });

  return (
    <table
      data-testid={testId}
      css={css`
        width: 100%;
        font-weight: normal;
        border-spacing: 0 4px;
      `}
    >
      <thead
        css={css`
          background-color: ${headerBackgroundColor};
        `}
      >
        {getHeaderGroups().map(headerGroup => {
          return (
            <tr
              key={headerGroup.id}
              data-testid={((testId && `${testId}-`) || '') + headerGroup.id}
              css={css`
                ${withBorder && `border-bottom: 1px solid ${colors.grey10};`}
              `}
            >
              {headerGroup.headers.map(header => {
                return (
                  <th
                    key={header.id}
                    data-testid={((testId && `${testId}-`) || '') + header.id}
                    css={css`
                      font-weight: normal;
                      padding: 4px 8px;
                      position: relative;
                      width: ${header.column.getSize()}px;
                      ${withRowBorders ? `border-right: 1px solid ${colors.grey10}` : ''};
                    `}
                  >
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                );
              })}
            </tr>
          );
        })}
      </thead>
      <tbody>
        {getRowModel().rows.map(row => {
          return (
            <React.Fragment key={row.id}>
              <tr
                key={row.id}
                data-testid={((testId && `${testId}-`) || '') + row.id}
                css={css`
                  ${withBorder && `border-bottom: 1px solid ${colors.grey10};`}
                  ${row.original.rowCustomCSS};
                `}
              >
                {row.getVisibleCells().map(cell => (
                  <td
                    key={cell.id}
                    data-testid={((testId && `${testId}-`) || '') + cell.id}
                    css={css`
                      padding: 4px 8px;
                      width: ${cell.column.getSize()}px;
                      ${withRowBorders ? `border-right: 1px solid ${colors.grey10}` : ''};
                    `}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
              {row.getIsExpanded() && renderSubComponent && (
                <tr>
                  <td colSpan={row.getVisibleCells().length}>{renderSubComponent({ row })}</td>
                </tr>
              )}
            </React.Fragment>
          );
        })}
      </tbody>
    </table>
  );
};

export default Table;
