/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useQueryClient } from '@tanstack/react-query';
import * as React from 'react';

import SystemMessage from '../../../components/core/Alert/SystemMessage';
import Button, { ButtonVariant } from '../../../components/core/buttons/Button';
import Container from '../../../components/core/Container';
import Copy from '../../../components/core/Copy';
import FlexBox from '../../../components/core/FlexBox';
import FormModal from '../../../components/core/FormModal';
import { RadioGroupField } from '../../../components/core/forms/fields';
import { BuildingsIcon, ChevronLeft, CopyIcon } from '../../../components/core/icons';
import Paragraph from '../../../components/core/Paragraph';
import Text from '../../../components/core/Text';
import { Translations } from '../../../constants';
import { useToggle } from '../../../hooks';
import { ILoan, IMaticPolicy, IPersonAsset } from '../../../interfaces';
import { DocumentDeliveryStatus, IDocumentDelivery } from '../../../interfaces/IDocumentDelivery';
import { servicerOrLender } from '../../../interfaces/ILender';
import { findPolicyType, PolicyType } from '../../../interfaces/IPolicyType';
import { generateAutoDescription, IVehicle } from '../../../interfaces/IVehicle';
import { PERSON_MATIC_POLICIES_QUERY_KEY } from '../../../queries/people/person_policies/usePersonMaticPolicies';
import useUpdatePolicy, { UpdatePolicyRequest } from '../../../queries/people/person_policies/useUpdatePolicy';
import usePersonLoans, { PERSON_LOANS_QUERY_KEY } from '../../../queries/people/usePersonLoans';
import colors from '../../../theme/colors';
import { spacings } from '../../../theme/variables';
import LoanEditor from '../../Customer/CustomerLoans/LoanEditor';

const Loan = ({ loan, isPrimary = false }: { loan: ILoan; isPrimary?: boolean }) => {
  const company = servicerOrLender({ servicer: loan.servicer, lender: loan.lender });
  return (
    <FlexBox justifySpaceBetween gap={spacings.px12} alignItemsBaseline border p={spacings.px12} roundBorder>
      <FlexBox
        gap={spacings.px12}
        alignItemsCenter
        customCss={css`
          max-width: 55%;
        `}
      >
        <Container border roundBorder p={spacings.px4}>
          <Container roundBorder p={spacings.px8} backgroundColor={colors.grey5}>
            <BuildingsIcon color={isPrimary ? colors.azure50 : colors.grey60} />
          </Container>
        </Container>

        <FlexBox columnDirection fitParentWidth gap={spacings.px8}>
          <FlexBox alignItemsCenter gap={spacings.px8}>
            <Text singleLine type="small" className="fs-mask">
              {company?.business_name}
            </Text>
          </FlexBox>
          {company?.mortgagee_address?.full && (
            <Paragraph type="small" color={colors.grey60} singleLine className="fs-mask">
              {company.mortgagee_address.full}
            </Paragraph>
          )}
        </FlexBox>
      </FlexBox>
      {loan.loan_number && (
        <Copy value={loan.loan_number} withIcon={false}>
          <FlexBox alignItemsCenter gap={spacings.px4} p={spacings.px4}>
            <Text bold type="small" className="fs-mask">
              {loan.loan_number}
            </Text>
            <CopyIcon />
          </FlexBox>
        </Copy>
      )}
    </FlexBox>
  );
};

const PolicyLoans = ({
  personGid,
  asset,
  policy,
  deliveries
}: {
  personGid: string;
  asset?: IPersonAsset;
  policy: IMaticPolicy;
  deliveries: IDocumentDelivery[] | undefined;
}) => {
  const queryClient = useQueryClient();
  const [editPrimaryLoan, toggleEditPrimaryLoan] = useToggle(false);
  const [openOtherLoans, toggleOpenOtherLoans] = useToggle(false);
  const { data: loans } = usePersonLoans({ personGid, assetGid: asset?.gid });
  const { mutateAsync: updatePolicy, isPending: isPendingPolicyUpdate } = useUpdatePolicy();
  const primaryLoan = loans?.find(loan => loan.gid === policy.primary_loan_gid) || loans?.[0];
  const otherLoans = loans?.filter(loan => loan.gid !== primaryLoan?.gid);

  const paymentMethod = Translations.paymentMethod(policy.payment_method).toLowerCase();
  const [editorOpened, toggleEditorOpened] = useToggle(false);
  const assetGid = policy.assets?.[0]?.gid;
  const isMissedLoan = !!deliveries?.find(delivery => delivery.status === DocumentDeliveryStatus.MissedLoan);
  const isMissedLoanNumber = !!deliveries?.find(
    delivery => delivery.status === DocumentDeliveryStatus.MissedLoanNumber
  );
  const isMissedFaxNumber = !!deliveries?.find(delivery => delivery.status === DocumentDeliveryStatus.MissedFaxNumber);

  if ((!primaryLoan && !isMissedLoan) || !deliveries?.length) {
    return null;
  }

  return (
    <FlexBox columnDirection gap={spacings.px12}>
      <FlexBox gap={spacings.px8}>
        <Text bold>Review primary loan</Text>
        {loans && (
          <Button variant={ButtonVariant.Text} onClick={toggleEditPrimaryLoan} disabled={loans.length < 2}>
            Select primary
          </Button>
        )}
      </FlexBox>

      {!isMissedLoan && !isMissedLoanNumber && !isMissedFaxNumber && primaryLoan && (
        <>
          <Loan loan={primaryLoan} isPrimary />
          {otherLoans && otherLoans.length > 0 && (
            <FlexBox
              customCss={css`
                flex-direction: ${openOtherLoans ? 'column-reverse' : 'column'};
              `}
              gap={spacings.px12}
            >
              <Button
                variant={ButtonVariant.Text}
                onClick={toggleOpenOtherLoans}
                customCss={css`
                  width: fit-content;
                  font-weight: 500;
                `}
              >
                <ChevronLeft
                  css={css`
                    transform: rotate(${openOtherLoans ? '90deg' : '270deg'});
                    transition: transform 0.2s;
                    margin-bottom: 2px;
                  `}
                  color={colors.azure50}
                />
                {openOtherLoans ? 'Hide' : 'See'} other {otherLoans.length} loan
                {otherLoans.length > 1 ? 's' : ''}
              </Button>
              {openOtherLoans && otherLoans?.map(loan => <Loan key={loan.gid} loan={loan} />)}
            </FlexBox>
          )}
        </>
      )}

      {(isMissedLoan || isMissedLoanNumber) && (
        <SystemMessage
          type="warning"
          heading="Missing loan information"
          mv={spacings.px8}
          description={
            <>
              <Container>
                You have selected {paymentMethod} payment, but the loan information is missing. If you do not add it, we
                will not send the required documents to the mortgage company. This will result in a non-payment
                cancellation and the policy will be pending. A task will be created to fax this information manually if
                not uploaded now.
              </Container>
              <Button variant={ButtonVariant.Text} mt={spacings.px8} onClick={toggleEditorOpened}>
                Add information
              </Button>
            </>
          }
        />
      )}

      {isMissedFaxNumber && (
        <SystemMessage
          type="error"
          heading="No fax number for lender"
          mv={spacings.px8}
          description={
            <Container>
              There is no fax number on file for this lender. Please, post in the #support-agency-tools channel to
              verify and add the fax number.
            </Container>
          }
        />
      )}

      {editorOpened && assetGid && (
        <LoanEditor
          personGid={personGid}
          assetGid={assetGid}
          cancelHandler={toggleEditorOpened}
          loan={primaryLoan || undefined}
          confirmHandler={() => {
            toggleEditorOpened();
            queryClient.invalidateQueries({ queryKey: [PERSON_LOANS_QUERY_KEY, personGid, assetGid] });
          }}
        />
      )}
      {editPrimaryLoan && (
        <FormModal
          title="Select primary loan"
          confirmText="Update"
          cancelText="Cancel"
          cancelHandler={toggleEditPrimaryLoan}
          confirmHandler={values => {
            const { id, ...rest } = policy;
            updatePolicy({
              person_gid: personGid,
              policy_id: id,
              carrier_id: policy.carrier.id,
              ...rest,
              primary_loan_gid: values.primary_loan
            } as unknown as UpdatePolicyRequest).then(() => {
              queryClient.invalidateQueries({ queryKey: [personGid, PERSON_MATIC_POLICIES_QUERY_KEY] });
              toggleEditPrimaryLoan();
            });
          }}
          confirmationInProgress={isPendingPolicyUpdate}
          initialValues={{ primary_loan: primaryLoan?.gid || '' }}
          renderForm={() => {
            return (
              <FlexBox columnDirection gap={spacings.px24}>
                <RadioGroupField
                  columnDirection
                  label={() => (
                    <Paragraph bold className="fs-mask">
                      For {findPolicyType(policy.policy_type)?.name} policy{' '}
                      {policy.policy_type === PolicyType.Auto
                        ? generateAutoDescription(policy.assets as IVehicle[])
                        : policy.assets?.[0]?.address.full}
                    </Paragraph>
                  )}
                  required
                  id="primary_loan"
                  name="primary_loan"
                  options={(loans || []).map(loan => ({
                    id: loan.gid,
                    label: servicerOrLender({ servicer: loan.servicer, lender: loan.lender })?.business_name || '',
                    description: (
                      <Text type="small" color={colors.grey60} className="fs-mask">
                        Loan # {loan.loan_number}
                      </Text>
                    ),
                    value: loan.gid
                  }))}
                />
              </FlexBox>
            );
          }}
        />
      )}
    </FlexBox>
  );
};

export default PolicyLoans;
